var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import moment from 'moment';
import { htmlToIndesignHtml, shouldPreserveLongSequencesForNewspaper } from '../indesign/helpers';
import { dateTimeLikeToDate } from '../date';
import { EHandlebars } from './shared';
import { DateParseError, UnknownDateFormat } from '../errors/NoticePreviewErrors';
import { getNoticeType } from '../helpers';
import pricingHelpers from '../pricing';
var generateV1Footer = function (footerFormatString, notice) {
    if (!footerFormatString)
        return '';
    var formatFooterString = function (match) {
        var e_1, _a;
        // {{***}} -> ***
        var footerTag = match.slice(2, -1);
        var formatType = footerTag.split(' ')[0];
        if (formatType === 'SQUASH') {
            var monthFormat = void 0;
            var dayFormat = void 0;
            var yearFormat = void 0;
            try {
                // ...(***)... -> ***
                monthFormat = footerTag.match(/\(.*?\)/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('month', footerTag);
            }
            try {
                // ...[***]... -> ***
                dayFormat = footerTag.match(/\[.*?\]/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('day', footerTag);
            }
            try {
                // ...|***|... -> ***
                yearFormat = footerTag.match(/\|.*?\|/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('year', footerTag);
            }
            var firstDate = notice.publicationDates.map(dateTimeLikeToDate)[0];
            var currentYear = firstDate.getFullYear();
            var currentMonth = firstDate.getMonth();
            var formattedString = "" + moment(firstDate).format(monthFormat);
            try {
                for (var _b = __values(notice.publicationDates), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var timestamp = _c.value;
                    var date = dateTimeLikeToDate(timestamp);
                    if (date.getFullYear() !== currentYear) {
                        formattedString += moment(firstDate).format(yearFormat) + ", ";
                    }
                    if (date.getMonth() !== currentMonth) {
                        formattedString += moment(date).format(monthFormat);
                    }
                    formattedString += moment(date).format(dayFormat);
                    currentYear = date.getFullYear();
                    currentMonth = date.getMonth();
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            var lastDate = dateTimeLikeToDate(notice.publicationDates[notice.publicationDates.length - 1]);
            formattedString += moment(lastDate).format(yearFormat);
            return formattedString;
        }
        if (formatType === 'DATE') {
            var format_1;
            var separator = void 0;
            try {
                // ...(***)... -> ***
                format_1 = footerTag.match(/\(.*?\)/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('date', footerTag);
            }
            try {
                // ...[***]... -> ***
                separator = footerTag.match(/\[.*?\]/)[0].slice(1, -1);
            }
            catch (err) {
                throw new DateParseError('separator', footerTag);
            }
            return (notice.publicationDates || [])
                .map(function (t) { return "" + moment(dateTimeLikeToDate(t)).format(format_1); })
                .join(separator);
        }
        throw new UnknownDateFormat(match);
    };
    return footerFormatString.replace(/{{.*?}}/g, formatFooterString);
};
export var addFooterXML = function (footer) {
    return "<?xml version=\"1.0\" encoding=\"UTF-8\"?><dynamic-footer xmlns:aid=\"http://ns.adobe.com/AdobeInDesign/4.0/\" xmlns:aid5=\"http://ns.adobe.com/AdobeInDesign/5.0/\">" + footer + "</dynamic-footer>";
};
export var createCustomFooter = function (dynamicFooter, customId, dbPricing, oldCustomId) {
    var customFooter = dynamicFooter || '';
    // reset footer
    if (oldCustomId) {
        customFooter = customFooter.replace(oldCustomId, '#'.repeat(6));
    }
    // replace in custom ID
    if (customId) {
        customFooter = customFooter.replace('#'.repeat(6), customId);
    }
    // replace in for total price
    if (dbPricing === null || dbPricing === void 0 ? void 0 : dbPricing.subtotal) {
        customFooter = customFooter.replace('*'.repeat(6), "" + (dbPricing.subtotal / 100).toFixed(2));
    }
    return customFooter;
};
export var generateFormattedFooter = function (notice) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaper, footerFormatString, footer, isV1Footer, compiled, filerName, filerOrgName, filer, filerOrg, price, _a, _b, noticeType, fixedFooter, preserveLongSequences;
    var _c, _d, _e, _f, _g;
    return __generator(this, function (_h) {
        switch (_h.label) {
            case 0:
                if (!notice.newspaper)
                    return [2 /*return*/, ''];
                if (!notice.publicationDates)
                    return [2 /*return*/, ''];
                return [4 /*yield*/, notice.newspaper.get()];
            case 1:
                newspaper = _h.sent();
                if (!newspaper.data())
                    return [2 /*return*/, ''];
                footerFormatString = notice.publicationDates.length === 1 && ((_c = newspaper.data()) === null || _c === void 0 ? void 0 : _c.oneRunFooter)
                    ? (_d = newspaper.data()) === null || _d === void 0 ? void 0 : _d.oneRunFooter : (_e = newspaper.data()) === null || _e === void 0 ? void 0 : _e.footerFormatString;
                if (!footerFormatString)
                    return [2 /*return*/, ''];
                isV1Footer = footerFormatString.slice(0, 3) === 'V1:';
                if (!isV1Footer) return [3 /*break*/, 2];
                footer = generateV1Footer(footerFormatString.slice(3, footerFormatString.length), notice);
                return [3 /*break*/, 9];
            case 2:
                compiled = EHandlebars.compile(footerFormatString);
                filerName = '';
                filerOrgName = '';
                if (!notice.filer) return [3 /*break*/, 4];
                return [4 /*yield*/, notice.filer.get()];
            case 3:
                filer = _h.sent();
                filerName = ((_f = filer.data()) === null || _f === void 0 ? void 0 : _f.name) || '';
                _h.label = 4;
            case 4:
                if (!notice.filedBy) return [3 /*break*/, 6];
                return [4 /*yield*/, notice.filedBy.get()];
            case 5:
                filerOrg = _h.sent();
                filerOrgName = ((_g = filerOrg.data()) === null || _g === void 0 ? void 0 : _g.name) || '';
                _h.label = 6;
            case 6:
                price = '';
                if (!notice.displayParams) return [3 /*break*/, 8];
                _b = (_a = pricingHelpers).getUIPricingObject;
                return [4 /*yield*/, pricingHelpers.createDBPricingObjectFromData(notice, notice.displayParams)];
            case 7:
                price = _b.apply(_a, [_h.sent()])
                    .subtotal.toFixed(2);
                _h.label = 8;
            case 8:
                noticeType = getNoticeType({ data: function () { return notice; } }, newspaper);
                footer = compiled({
                    dates: notice.publicationDates,
                    price: price || '#.##',
                    noticeType: noticeType || 'Public Notice',
                    filerOrgName: filerOrgName,
                    filerName: filerName
                });
                _h.label = 9;
            case 9:
                fixedFooter = createCustomFooter(footer, notice.customId, notice.pricing);
                preserveLongSequences = shouldPreserveLongSequencesForNewspaper(newspaper);
                return [2 /*return*/, htmlToIndesignHtml(fixedFooter, window.DOMParser, {
                        isFirstPHeading: false,
                        preserveLongSequences: preserveLongSequences
                    }, {})];
        }
    });
}); };
