var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import handlebars from 'handlebars';
import moment from 'moment';
import { dateTimeLikeToDate, dateTimeLikeToTimestamp } from '../date';
import { DateParseError, MissingParameters } from '../errors/NoticePreviewErrors';
var default_conjunctions = { default: ',', final: ' and' };
var getDelimiter = function (conjunctions, index, total) {
    var DEFAULT_CONJUCT = conjunctions.default;
    var FINAL_CONJUCT = conjunctions.final;
    if (DEFAULT_CONJUCT && FINAL_CONJUCT === undefined)
        return DEFAULT_CONJUCT;
    if (total === 2) {
        if (index === 0)
            return "" + FINAL_CONJUCT;
    }
    if (index === total - 2)
        return "" + FINAL_CONJUCT;
    if (index === total - 1)
        return '';
    return DEFAULT_CONJUCT;
};
var getConjunction = function (index, publicationDates, conjunctions) {
    if (!conjunctions ||
        !Array.isArray(publicationDates) ||
        !publicationDates[index])
        return undefined;
    if (!publicationDates || publicationDates.length === 0)
        return '';
    var currentMonth = publicationDates[index].toDate().getMonth();
    var isFinalDateInMonth = publicationDates
        .slice(index + 1)
        .filter(function (date) {
        return date ? date.toDate().getMonth() === currentMonth : false;
    }).length === 0;
    var areMoreDays = publicationDates.slice(index + 1).length;
    // return the month separator
    if (isFinalDateInMonth && areMoreDays && conjunctions.months !== undefined) {
        return conjunctions.months;
    }
    // e.g. return ',' or ', and' depending on where we are in the list
    var delim = "" + getDelimiter(conjunctions, index, publicationDates.length);
    if (delim)
        return "" + delim + (conjunctions.spaces === 'false' ? '' : ' ');
    return undefined;
};
var dayTokens = /(Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?)/g;
var monthTokens = /(Mo|MM?M?M?)/g;
var yearTokens = /(YYYYYY|YYYYY|YYYY|YY)/g;
export var squash = function (footerTag, publicationDates, conjunctions) {
    var _a, _b, _c, e_1, _d;
    var monthFormat;
    var dayFormat;
    var yearFormat;
    var monthDecorators = false;
    if (!publicationDates || publicationDates.length === 0)
        return '';
    try {
        _a = __read(footerTag.match(monthTokens), 1), monthFormat = _a[0];
        // ..{(***}... -> ***
        var monthAndDecorators = footerTag.match(/\{.*?\}/);
        if (Array.isArray(monthAndDecorators) &&
            typeof monthAndDecorators[0] === 'string') {
            monthDecorators = true;
            monthFormat = monthAndDecorators[0].slice(1, -1);
        }
    }
    catch (err) {
        throw new DateParseError('month', footerTag);
    }
    try {
        _b = __read(footerTag.match(dayTokens), 1), dayFormat = _b[0];
    }
    catch (err) {
        throw new DateParseError('day', footerTag);
    }
    try {
        _c = __read(footerTag.match(yearTokens), 1), yearFormat = _c[0];
    }
    catch (err) {
        yearFormat = null;
    }
    var firstDate = publicationDates[0].toDate();
    var currentYear = firstDate.getFullYear();
    var currentMonth = firstDate.getMonth();
    var formattedString = "" + moment(firstDate).format(monthFormat) + (monthDecorators ? '' : ' ');
    try {
        for (var _e = __values((publicationDates || []).entries()), _f = _e.next(); !_f.done; _f = _e.next()) {
            var _g = __read(_f.value, 2), i = _g[0], timestamp = _g[1];
            var date_1 = timestamp.toDate();
            if (date_1.getFullYear() !== currentYear) {
                if (yearFormat)
                    formattedString += moment(firstDate).format(yearFormat) + ", ";
            }
            if (date_1.getMonth() !== currentMonth) {
                formattedString += "" + moment(date_1).format(monthFormat) + (monthDecorators ? '' : ' ');
            }
            formattedString += moment(date_1).format(dayFormat);
            formattedString += getConjunction(i, publicationDates, conjunctions) || '';
            currentYear = date_1.getFullYear();
            currentMonth = date_1.getMonth();
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_f && !_f.done && (_d = _e.return)) _d.call(_e);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (yearFormat)
        formattedString += ', ';
    var lastDate = publicationDates[publicationDates.length - 1].toDate();
    formattedString += yearFormat ? moment(lastDate).format(yearFormat) : '';
    return formattedString.trim();
};
export var date = function (footerTag, publicationDates, conjunctions) {
    var e_2, _a;
    var format;
    var separator;
    if (!publicationDates || publicationDates.length === 0)
        return '';
    try {
        // ...(***)... -> ***
        format = footerTag.match(/\(.*?\)/)[0].slice(1, -1);
    }
    catch (err) {
        throw new DateParseError('date', footerTag);
    }
    try {
        // ...[***]... -> ***
        separator = footerTag.match(/\[.*?\]/)[0].slice(1, -1);
    }
    catch (err) {
        separator = '';
    }
    if (conjunctions) {
        var formattedString = '';
        try {
            for (var _b = __values((publicationDates || []).entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 1), i = _d[0];
                var d = publicationDates[i];
                var delim = "" + getDelimiter(conjunctions, i, publicationDates.length);
                formattedString += moment(d.toDate()).format(format);
                if (delim) {
                    formattedString +=
                        conjunctions.spaces === 'false' ? "" + delim : delim + " ";
                }
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return formattedString;
    }
    return publicationDates
        .map(function (t) { return "" + moment(t.toDate()).format(format); })
        .join(separator);
};
handlebars.registerHelper('ARRAY', function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    return Array.prototype.slice.call(args, 0, -1);
});
handlebars.registerHelper('SLICE', function (arr, start, finish) {
    return (arr || []).slice(start, typeof finish === 'number' ? finish : undefined);
});
handlebars.registerHelper('SQUASH_DATES', function (a) {
    var _a = a.hash, dates = _a.dates, format = _a.format, conjunctions = _a.conjunctions;
    if (!dates || !dates.length)
        return '';
    var parsedConjuct = conjunctions ? JSON.parse(conjunctions) : null;
    return squash(format, dates, parsedConjuct || default_conjunctions);
});
handlebars.registerHelper('FORMAT_DATES', function (a) {
    if (!a)
        throw new MissingParameters();
    var _a = a.hash, dates = _a.dates, format = _a.format, conjunctions = _a.conjunctions;
    if (!dates || dates.length === 0)
        return '';
    if (dates && dates.length === 1)
        return moment(dateTimeLikeToDate(dates[0])).format(format);
    var parsedConjuct = conjunctions ? JSON.parse(conjunctions) : null;
    return date("(" + format + ")", dates, parsedConjuct || default_conjunctions);
});
handlebars.registerHelper('ADD_DATES', function (dates, days, months, years) {
    return (dates || []).map(function (d) {
        var m = moment(dateTimeLikeToDate(d));
        if (days)
            m.add(days, 'days');
        if (months)
            m.add(months, 'months');
        if (years)
            m.add(years, 'years');
        return dateTimeLikeToTimestamp(m.toDate());
    });
});
handlebars.registerHelper('FORMAT_MONEY', function (value) {
    if (!value)
        return '';
    return parseFloat("" + value).toFixed(2);
});
handlebars.registerHelper('ROUND', function (value, digits) {
    if (!digits)
        return value;
    return parseFloat("" + value).toFixed(digits);
});
handlebars.registerHelper('FORMAT_DATE', function (dateTimeLike, format) {
    if (!dateTimeLike)
        return '';
    var date = dateTimeLikeToDate(dateTimeLike);
    return moment(date).format(format);
});
handlebars.registerHelper('GET_INDEX', function (array, index) {
    if (!(array === null || array === void 0 ? void 0 : array.length))
        return null;
    return array[index];
});
handlebars.registerHelper('LENGTH', function (a) {
    return (a || []).length;
});
handlebars.registerHelper('MATH', function (lv, operator, rv) {
    var lvalue = parseFloat(lv);
    var rvalue = parseFloat(rv);
    return {
        '+': lvalue + rvalue,
        '-': lvalue - rvalue,
        '*': lvalue * rvalue,
        '/': lvalue / rvalue,
        '%': lvalue % rvalue
    }[operator];
});
handlebars.registerHelper('UPPERCASE', function (a) {
    return a ? a.toUpperCase() : a;
});
handlebars.registerHelper('FIRST', function () {
    var a = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        a[_i] = arguments[_i];
    }
    return a.find(Boolean) || '';
});
handlebars.registerHelper('LAST', function (a) {
    return [a[a.length - 1]];
});
handlebars.registerHelper('times', function (n, block) {
    var accum = '';
    for (var i = 0; i < n; ++i)
        accum += block.fn(i);
    return accum;
});
handlebars.registerHelper('isdefined', function (value) {
    return value !== undefined;
});
handlebars.registerHelper('equals', function (arg1, arg2) {
    return arg1 === arg2;
});
handlebars.registerHelper('startswith', function (arg1, arg2) {
    return arg1 && typeof arg1 === 'string' ? arg1.startsWith(arg2) : false;
});
handlebars.registerHelper('any', function () {
    var args = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        args[_i] = arguments[_i];
    }
    /* The last argument of a destructured iterative argument list
    in a registered handlebar is the options object, which will always
    be truthy, so we have to ignore it in the function below */
    return args.some(function (arg) { return args.indexOf(arg) !== args.length - 1 && !!arg; });
});
export var EHandlebars = handlebars;
