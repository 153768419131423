var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { push } from 'connected-react-router';
import { QuestionMarkRoundedIcon, CogIcon, AccountCircleIcon } from 'icons';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import { RoleType } from 'lib/enums';
import { isDonationOrg, canRunDonations } from 'lib/publishers';
import Country from 'lib/enums/Country';
import { useTranslation } from 'react-i18next';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { autoBot } from 'lib/types/firebase';
import { getLocationParams, getSubdomain } from 'utils/urls';
import SwitchToChrome from '../components/SwitchToChrome';
import ActiveOrganizationSelect from './ActiveOrganizationSelect';
import NotificationDropdown from '../components/NotificationDropdown';
import Survey from '../routes/settings/publisher/Survey';
var mapStateToProps = function (state) { return ({
    activeOrganization: state.auth.activeOrganization,
    organization: state.auth.organization,
    orgContext: state.auth.orgContext,
    user: state.auth.user,
    userAuth: state.auth.userAuth,
    location: state.router.location,
    showAllOrgsNotices: state.auth.showAllOrgsNotices
}); };
var COLUMN_LOGO_URL = 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2FColumn_Logo_Blue-08%20(1).png?alt=media&token=9c3821a8-d4be-401c-af9b-e0e2c0d8e328';
var isCurrentlyRunningDonations = function (activeOrganization) { return __awaiter(void 0, void 0, void 0, function () {
    var donationProfileQuery, _a, profile;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!(isDonationOrg(activeOrganization) || canRunDonations(activeOrganization))) {
                    return [2 /*return*/, false];
                }
                return [4 /*yield*/, Firebase.firestore()
                        .collection(Collections.donationProfiles)
                        .where('organization', '==', activeOrganization.ref)
                        .withConverter(autoBot())
                        .limit(1)
                        .get()];
            case 1:
                donationProfileQuery = _b.sent();
                if (donationProfileQuery.empty)
                    return [2 /*return*/, false];
                _a = __read(donationProfileQuery.docs, 1), profile = _a[0];
                if (!profile.data().legalDocs)
                    return [2 /*return*/, false];
                return [2 /*return*/, true];
        }
    });
}); };
var Frame = function (_a) {
    var userAuth = _a.userAuth, organization = _a.organization, orgContext = _a.orgContext, user = _a.user, noFramePadding = _a.noFramePadding, location = _a.location, activeOrganization = _a.activeOrganization, push = _a.push, showAllOrgsNotices = _a.showAllOrgsNotices, children = _a.children;
    var _b = __read(useState(false), 2), isSettingsMenuOpen = _b[0], setIsSettingsMenuOpen = _b[1];
    var _c = __read(useState(false), 2), isHelpMenuOpen = _c[0], setIsHelpMenuOpen = _c[1];
    var _d = __read(useState(false), 2), isNotificationMenuOpen = _d[0], setIsNotificationMenuOpen = _d[1];
    var _e = __read(useState(false), 2), isActiveOrgSelectOpen = _e[0], setIsActiveOrgSelectOpen = _e[1];
    var _f = __read(useState(COLUMN_LOGO_URL), 2), frameLogoURL = _f[0], setFrameLogoURL = _f[1];
    var _g = __read(useState(false), 2), showDonationTab = _g[0], setShowDonationTab = _g[1];
    var pathName = window.location.pathname;
    var _h = useTranslation(), t = _h.t, i18n = _h.i18n;
    useEffect(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()))
                            return [2 /*return*/];
                        // Future TODO: we should have a language toggle
                        if ((activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data().country) === Country.GBR.value) {
                            i18n.changeLanguage('en-GB');
                        }
                        return [4 /*yield*/, isCurrentlyRunningDonations(activeOrganization)];
                    case 1:
                        if (_a.sent()) {
                            setShowDonationTab(true);
                        }
                        return [2 /*return*/];
                }
            });
        }); })();
    }, [activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.id]);
    useEffect(function () {
        var getFrameUrl = function () { return __awaiter(void 0, void 0, void 0, function () {
            var subdomainLogo, subdomainSnapshot, subdomainOrg;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (window.location.href.includes('florida'))
                            return [2 /*return*/, '/FPF-color.png'];
                        subdomainLogo = getLocationParams().get('logo');
                        if (subdomainLogo) {
                            setFrameLogoURL(subdomainLogo);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, Firebase.firestore()
                                .collection(Collections.organizations)
                                .where('subdomain', '==', getSubdomain())
                                .get()];
                    case 1:
                        subdomainSnapshot = _a.sent();
                        if (!subdomainSnapshot.docs.length) {
                            return [2 /*return*/];
                        }
                        subdomainOrg = subdomainSnapshot.docs[0];
                        if (subdomainOrg.data().filingFlowSubdomainImage &&
                            getSubdomain() === subdomainOrg.data().subdomain) {
                            setFrameLogoURL(subdomainOrg.data().filingFlowSubdomainImage);
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        getFrameUrl();
    }, []);
    return (React.createElement("div", { className: "flex flex-col h-screen bg-gray-80" },
        user && user.data() && user.data().role === RoleType.super.value && (React.createElement("div", { className: "flex justify-center items-center bg-yellow-500 z-20" },
            React.createElement("div", { className: "font-semibold" }, "SUPERUSER"))),
        React.createElement("nav", { className: "bg-white shadow z-20" },
            React.createElement("div", { className: "max-w-7xl mx-auto px-2 sm:px-6 lg:px-8" },
                React.createElement("div", { className: "relative flex justify-between h-16" },
                    React.createElement("div", { className: "absolute inset-y-0 left-0 flex items-center hidden" },
                        React.createElement("button", { className: "inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500", "aria-expanded": "false" },
                            React.createElement("span", { className: "sr-only" }, "Open main menu"),
                            React.createElement("svg", { className: "block h-6 w-6", xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", "aria-hidden": "true" },
                                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M4 6h16M4 12h16M4 18h16" })),
                            React.createElement("svg", { className: "hidden h-6 w-6", xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 24 24", stroke: "currentColor", "aria-hidden": "true" },
                                React.createElement("path", { strokeLinecap: "round", strokeLinejoin: "round", strokeWidth: "2", d: "M6 18L18 6M6 6l12 12" })))),
                    React.createElement("div", { className: "md:flex-1 flex items-center justify-center sm:items-stretch sm:justify-between" },
                        React.createElement("div", { className: "flex-shrink-0 flex items-center" },
                            frameLogoURL && (React.createElement("div", { onClick: function () {
                                    user ? push('/') : push('/login');
                                } },
                                React.createElement("img", { className: "object-cover object-left h-5 md:object-contain md:object-left -mt-1 cursor-pointer", src: showAllOrgsNotices ? COLUMN_LOGO_URL : frameLogoURL, alt: "Company name" }))),
                            location && !location.pathname.includes('search') && (React.createElement(ActiveOrganizationSelect, { open: isActiveOrgSelectOpen, setOpen: function (value) {
                                    setIsActiveOrgSelectOpen(value);
                                    if (value) {
                                        setIsHelpMenuOpen(false);
                                        setIsSettingsMenuOpen(false);
                                        setIsNotificationMenuOpen(false);
                                    }
                                } })),
                            React.createElement(Survey, { fromMainView: true, user: user, activeOrganization: activeOrganization })),
                        React.createElement("div", { className: "hidden sm:ml-6 sm:flex sm:space-x-8 mr-2" },
                            userAuth && (React.createElement("div", { className: "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer " + (pathName.includes('/notices') && 'border-blue-600 text-gray-900'), onClick: function () { return push('/'); } }, "My Notices")),
                            showDonationTab && (React.createElement("div", { className: "inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer " + (pathName.includes('/donation-campaign') && 'border-blue-600 text-gray-900'), onClick: function () { return push('/donation-campaign'); } }, "Donation Campaign")),
                            (user === null || user === void 0 ? void 0 : user.data().card) && (React.createElement("div", { className: "inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer " + (pathName.includes('/cards') && 'border-blue-600 text-gray-900'), onClick: function () { return push('/cards'); } }, "Cards")),
                            (user === null || user === void 0 ? void 0 : user.data().bulkPaymentEnabled) && (React.createElement("div", { className: "inline-flex items-center ml-2 px-1 pt-1 border-b-2 border-transparent text-sm font-medium text-gray-700 hover:border-blue-600 cursor-pointer " + (pathName.includes('/payments') && 'border-blue-600 text-gray-900'), onClick: function () { return push('/payments'); } }, "Payments")))),
                    React.createElement("div", { className: "absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0" },
                        user && (React.createElement(NotificationDropdown, { user: user, open: isNotificationMenuOpen, setOpen: function (value) {
                                setIsNotificationMenuOpen(value);
                                if (value) {
                                    setIsHelpMenuOpen(false);
                                    setIsSettingsMenuOpen(false);
                                }
                            } })),
                        React.createElement("div", { className: "relative" },
                            React.createElement("div", null,
                                React.createElement("button", { id: "help-bar-icon", "data-testid": "help-bar-icon", className: "bg-white mx-3 p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", onClick: function () {
                                        setIsHelpMenuOpen(!isHelpMenuOpen);
                                        setIsSettingsMenuOpen(false);
                                        setIsNotificationMenuOpen(false);
                                        setIsActiveOrgSelectOpen(false);
                                    } },
                                    React.createElement(QuestionMarkRoundedIcon, null))),
                            React.createElement("div", { className: (!isHelpMenuOpen &&
                                    'hidden') + " z-10 border origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "user-menu", "data-testid": "help-menu-dropdown" },
                                React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-750 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                        window.open('https://help.column.us');
                                    } }, "Visit Help Center"))),
                        React.createElement("div", { className: "relative" },
                            React.createElement(Tooltip, { title: showAllOrgsNotices
                                    ? 'Select a specific organization to see settings'
                                    : '', disableHoverListener: !showAllOrgsNotices },
                                React.createElement("div", null,
                                    React.createElement("button", { id: "settings-icon", className: "bg-white p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 " + (showAllOrgsNotices &&
                                            'opacity-50'), onClick: function () {
                                            setIsSettingsMenuOpen(!isSettingsMenuOpen);
                                            setIsHelpMenuOpen(false);
                                            setIsNotificationMenuOpen(false);
                                            setIsActiveOrgSelectOpen(false);
                                        }, disabled: showAllOrgsNotices }, userAuth ? (React.createElement(CogIcon, { className: "text-white hover:text-gray-200" })) : (React.createElement(AccountCircleIcon, { className: "text-gray-400 hover:text-gray-500" }))))),
                            React.createElement("div", { className: (!isSettingsMenuOpen &&
                                    'hidden') + " z-10 border origin-top-right absolute right-0 mt-2 w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "user-menu" },
                                userAuth && (React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                        push('/settings/');
                                    } }, "My Account")),
                                organization && (React.createElement("div", { id: "my-organization", className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                        push('/settings/organization');
                                    } }, t('frame.org-settings'))),
                                userAuth && (React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                        push('/logout');
                                    } }, "Logout")),
                                !userAuth && (React.createElement("div", null,
                                    React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                            push('/login');
                                        } }, "Sign In"),
                                    organization && (React.createElement("div", { id: "my-organization", className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                            push('/settings/organization');
                                        } }, t('frame.org-settings'))),
                                    userAuth && (React.createElement("div", { className: "block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer", onClick: function () {
                                            push('/register');
                                        } }, "Register"))))))))),
            React.createElement("div", { className: "hidden sm:hidden" },
                React.createElement("div", { className: "pt-2 pb-4 space-y-1" },
                    (isDonationOrg(activeOrganization) ||
                        canRunDonations(activeOrganization)) && (React.createElement("div", { className: "block pl-3 pr-4 py-2 border-l-4 border-indigo-500 text-base font-medium text-indigo-700 bg-indigo-50", onClick: function () { return push('/donation-campaign'); } }, "Donation Campaign")),
                    React.createElement("a", { href: "#", className: "block pl-3 pr-4 py-2 border-l-4 border-transparent text-base font-medium text-gray-600 hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300" }, "Team")))),
        React.createElement(SwitchToChrome, { hideOnTheseRoutes: ['/search'] }),
        React.createElement("main", { id: "main", className: noFramePadding
                ? 'fixed min-w-full bg-gray-80 h-full'
                : 'flex-grow p-3 bg-gray-80 h-full overflow-x-scroll', onClick: function () {
                setIsNotificationMenuOpen(false);
                setIsSettingsMenuOpen(false);
                setIsHelpMenuOpen(false);
                setIsActiveOrgSelectOpen(false);
            } }, children),
        React.createElement("footer", { id: "appfooter", className: (!pathName.includes('/notices') &&
                'hidden') + " text-gray-700 pb-3 text-center bg-gray-80" },
            React.createElement("div", null,
                React.createElement("span", { onClick: function () {
                        return window.open('https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/Terms%20of%20Use.pdf?alt=media&token=7c8fd6e4-c71b-4602-8f48-ed7948bbeea2');
                    }, className: "cursor-pointer" }, "Terms of Use"),
                ' ',
                "|",
                ' ',
                React.createElement("span", { onClick: function () {
                        return window.open('https://enotice-production.imgix.net/custom-documents/permalink/4953.6bc4e-Column%20Privacy%20Policy.pdf');
                    }, className: "cursor-pointer" }, "Privacy Policy"),
                ' ',
                "| ",
                React.createElement("span", null, "help@column.us"),
                " |",
                ' ',
                React.createElement("span", { onClick: function () { return window.open('https://www.column.us'); }, className: "cursor-pointer" }, "column.us")))));
};
export default connect(mapStateToProps, { push: push })(Frame);
