var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { NoticeType } from 'lib/enums';
import { Collections } from 'lib/constants';
import { autoBot } from 'lib/types/firebase';
import { firestoreTimestampOrDateToDate } from 'lib/helpers';
import Firebase from '../../EnoticeFirebase';
var chooseRate = function (noticeType, userRates, orgRates) {
    // make sure that rates associated with the notice type show up first
    var highestPriorityNoticeTypeOnRate = noticeType !== NoticeType.custom.value ? noticeType : null;
    var sortedOrgRates = orgRates
        ? orgRates.sort(function (a) {
            return a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1;
        })
        : null;
    var sortedUserRates = userRates.sort(function (a) {
        return a.data().noticeType === highestPriorityNoticeTypeOnRate ? -1 : 1;
    });
    var orgCandidate = sortedOrgRates ? sortedOrgRates[0] : null;
    var userCandidate = sortedUserRates.length ? sortedUserRates[0] : null;
    if (orgCandidate && !userCandidate)
        return orgCandidate.ref;
    return userCandidate && userCandidate.ref;
};
export var rateCanPublishWithPlacementData = function (rateSnapshot, placement) {
    var _a;
    var isDisplayNotice = placement.noticeType === NoticeType.display_ad.value;
    var isDisplayRate = rateSnapshot.data().noticeType === NoticeType.display_ad.value;
    if (isDisplayNotice !== isDisplayRate)
        return false;
    if (!((_a = rateSnapshot.data().allowedDays) === null || _a === void 0 ? void 0 : _a.length))
        return true;
    var publicationDates = placement.publicationDates;
    if (!publicationDates || !publicationDates.length)
        return true;
    var firstPublicationDate = firestoreTimestampOrDateToDate(publicationDates[0]);
    var firstPublicationDay = firstPublicationDate.getDay();
    // if we're allowed to run on the first day, stop!
    if (rateSnapshot.data().allowedDays.includes(firstPublicationDay))
        return true;
    return false;
};
export var checkForDefault = function (placement, newspaper, newNoticeType) { return __awaiter(void 0, void 0, Promise, function () {
    var noticeType, isDisplay, defaultOrgRates, user, customer, customBaseQuery, defaultOrgRateQuery, defaultUserRateQuery, defaultUserRates, d, defaultRate, _a, newspaperRates, availableNewspaperRates;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                noticeType = newNoticeType || placement.noticeType;
                isDisplay = noticeType === NoticeType.display_ad.value;
                if (!placement.filer)
                    return [2 /*return*/];
                if (!newspaper)
                    return [2 /*return*/];
                return [4 /*yield*/, placement.filer.get()];
            case 1:
                user = _b.sent();
                customer = placement.customer;
                if (!customer)
                    return [2 /*return*/];
                if (customer.linerRate && !isDisplay)
                    return [2 /*return*/, customer.linerRate];
                if (customer.displayRate && isDisplay)
                    return [2 /*return*/, customer.displayRate];
                customBaseQuery = Firebase.firestore()
                    .collection(Collections.rates)
                    .where('organization', '==', newspaper.ref)
                    .withConverter(autoBot());
                if (!user.data().activeOrganization) return [3 /*break*/, 3];
                return [4 /*yield*/, customBaseQuery
                        .where('organizations', 'array-contains', user.data().activeOrganization)
                        .get()];
            case 2:
                defaultOrgRateQuery = _b.sent();
                defaultOrgRates = defaultOrgRateQuery.docs.filter(function (r) {
                    return rateCanPublishWithPlacementData(r, placement);
                });
                _b.label = 3;
            case 3: return [4 /*yield*/, customBaseQuery
                    .where('filers', 'array-contains', user.ref)
                    .get()];
            case 4:
                defaultUserRateQuery = _b.sent();
                defaultUserRates = defaultUserRateQuery.docs.filter(function (r) {
                    return rateCanPublishWithPlacementData(r, placement);
                });
                d = chooseRate(noticeType, defaultUserRates, defaultOrgRates);
                if (d)
                    return [2 /*return*/, d];
                defaultRate = isDisplay
                    ? newspaper.data().defaultDisplayRate
                    : newspaper.data().defaultLinerRate;
                _a = rateCanPublishWithPlacementData;
                return [4 /*yield*/, defaultRate.get()];
            case 5:
                if (_a.apply(void 0, [(_b.sent()),
                    placement]))
                    return [2 /*return*/, defaultRate];
                return [4 /*yield*/, customBaseQuery.get()];
            case 6:
                newspaperRates = _b.sent();
                availableNewspaperRates = newspaperRates.docs.filter(function (r) {
                    return rateCanPublishWithPlacementData(r, placement);
                });
                return [2 /*return*/, availableNewspaperRates[0].ref];
        }
    });
}); };
