var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { bindActionCreators } from 'redux';
import JoyRide from 'react-joyride';
import { connect } from 'react-redux';
import JoyRideActions from 'redux/joyrides';
import JoyrideTooltip from './Tooltip';
var JoyRideContainer = function (_a) {
    var joyrides = _a.joyrides, joyRideActions = _a.joyRideActions;
    var _b;
    var _c = __read(useState(false), 2), run = _c[0], setRun = _c[1];
    var _d = __read(useState(false), 2), init = _d[0], setInit = _d[1];
    var parsedSteps = [];
    try {
        parsedSteps = JSON.parse((_b = joyrides === null || joyrides === void 0 ? void 0 : joyrides.activeJoyRide) === null || _b === void 0 ? void 0 : _b.data().steps).map(function (s) { return (__assign(__assign({}, s), { disableBeacon: true, spotlightPadding: 20 })); });
    }
    catch (err) { }
    useEffect(function () {
        window.addEventListener('replacestate', function () { return joyRideActions.setNewJoyRidePossible(); }, false);
    }, []);
    var observeElements = function (selectors) {
        var observer = new MutationObserver(function (mutations, me) {
            return __awaiter(this, void 0, void 0, function () {
                var node;
                return __generator(this, function (_a) {
                    node = selectors.find(function (sel) {
                        return document.querySelector(sel);
                    });
                    try {
                        if (node) {
                            setInit(true);
                            setRun(true);
                            me.disconnect(); // stop observing
                        }
                    }
                    catch (err) {
                        console.log(err);
                    }
                    return [2 /*return*/];
                });
            });
        });
        observer.observe(document, {
            childList: true,
            subtree: true
        });
    };
    useEffect(function () {
        if (parsedSteps.length && !init) {
            observeElements((parsedSteps || []).map(function (step) { return step.target; }));
        }
    }, [parsedSteps]);
    return (React.createElement(JoyRide, { continuous: true, styles: {
            options: {
                textColor: '#004a14',
                width: 900,
                zIndex: 2147483647
            }
        }, disableScrolling: true, showProgress: true, showSkipButton: true, run: joyrides.activeJoyRide && run, tooltipComponent: JoyrideTooltip, steps: parsedSteps, callback: function (e) {
            var _a;
            try {
                joyrides === null || joyrides === void 0 ? void 0 : joyrides.callback(e);
            }
            catch (err) { }
            if (((_a = e === null || e === void 0 ? void 0 : e.step) === null || _a === void 0 ? void 0 : _a.waitFor) && !document.querySelector(e.step.waitFor)) {
                setRun(false);
                observeElements([e.step.waitFor]);
            }
            if (e.reset === 'reset') {
                setInit(false);
            }
            if (e.action === 'reset' || e.action === 'close') {
                joyRideActions.markJoyRideRead();
            }
        }, beaconComponent: function () { return React.createElement(React.Fragment, null, " "); } }));
};
var mapDispatchToProps = function (dispatch) { return ({
    joyRideActions: bindActionCreators(JoyRideActions, dispatch)
}); };
var mapStateToProps = function (state) { return ({
    joyrides: state.joyrides
}); };
export default connect(mapStateToProps, mapDispatchToProps)(JoyRideContainer);
