export default {
    usd: {
        value: 1,
        symbol: '$',
        key: 'usd',
        iso: 'usd',
        label: 'United States Dollar',
        label_abbrev: 'USD'
    },
    gbp: {
        value: 2,
        symbol: '£',
        key: 'gbp',
        iso: 'gbp',
        label: 'Pound Sterling',
        label_abbrev: 'GBP'
    }
};
