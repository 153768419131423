import React from 'react';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { connect } from 'react-redux';
import amber from '@material-ui/core/colors/amber';
import { mergeDeep } from './utils/funcs';
import computeColors from './lib/theme';
var defaultPrimary = computeColors('#2d9bdb');
var nonProgrammaticDarkBlue = '#24415b';
defaultPrimary[900] = nonProgrammaticDarkBlue;
var EnoticeTheme = function (_a) {
    var children = _a.children, orgContext = _a.orgContext;
    var createTheme = function () {
        var defaultTheme = {
            typography: {
                fontFamily: [
                    '-apple-system',
                    'BlinkMacSystemFont',
                    '"Segoe UI"',
                    '"Helvetica Neue"',
                    'Arial',
                    'sans-serif',
                    '"Apple Color Emoji"',
                    '"Segoe UI Emoji"',
                    '"Segoe UI Symbol"'
                ].join(','),
                subtitle2: {
                    fontWeight: 200
                }
            },
            palette: {
                primary: defaultPrimary,
                secondary: amber,
                grey: {
                    button: '#efefef',
                    inputBackground: 'rgba(255, 255, 255, 0.25)',
                    scrollFormHeading: 'rgba(0, 0, 0, 0.54)',
                    scrollFormBorder: "1px solid rgba(0, 0, 0, 0.1)",
                    scrollFormInteractiveElt: 'rgb(218, 218, 218)',
                    scrollFormInteractiveEltHov: 'rgb(190, 190, 190)',
                    buttonDark: '#dadada'
                },
                tertiary: {
                    main: '#e24f21',
                    600: '#cc481f'
                }
            }
        };
        var customTheme = orgContext ? orgContext.data().theme || {} : {};
        try {
            return createMuiTheme(mergeDeep(defaultTheme, customTheme));
        }
        catch (err) {
            return createMuiTheme(defaultTheme);
        }
    };
    var theme = createTheme();
    return React.createElement(ThemeProvider, { theme: theme }, children);
};
var mapStateToProps = function (state) { return ({
    orgContext: state.auth.orgContext
}); };
export default connect(mapStateToProps)(EnoticeTheme);
