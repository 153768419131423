import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import React from 'react';
import { isDonationOrg } from 'lib/publishers';
var Header = function (_a) {
    var orgContext = _a.orgContext;
    return (React.createElement(Helmet, null,
        React.createElement("title", null, orgContext
            ? orgContext.data().name + " " + (!isDonationOrg(orgContext) ? '| Public Notices' : '')
            : 'Column - Public Notice')));
};
var mapStateToProps = function (state) { return ({
    orgContext: state.auth.orgContext
}); };
export default connect(mapStateToProps)(Header);
