export default {
    processing_fee_oop: {
        value: 1,
        label: 'Processing Fee (Out of Platform)',
        name: 'Processing Fee (Out of Platform)',
        key: 'processing_fee_oop'
    },
    public_notice_refund: {
        value: 2,
        label: 'Public Notice Refund',
        name: 'Public Notice Refund',
        key: 'public_notice_refund'
    }
};
