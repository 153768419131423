var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useState, useEffect } from 'react';
import FreeformCModal from 'components/modals/FreeFormCModal';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import moment from 'moment';
import State from 'lib/enums/State';
import OrganizationType from 'lib/enums/OrganizationType';
import EToast from 'components/EToast';
var SurveyModal = function (_a) {
    var fromMainView = _a.fromMainView, setFloridaDataSurveyModal = _a.setFloridaDataSurveyModal, user = _a.user, activeOrganization = _a.activeOrganization;
    var _b = __read(useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(useState(), 2), error = _c[0], setError = _c[1];
    var _d = __read(useState(true), 2), disableAuditRequestModal = _d[0], setDisableAuditRequestModal = _d[1];
    var _e = __read(useState(true), 2), isDisabled = _e[0], setIsDisabled = _e[1];
    var inputTextStyle = 'flex items-center mr-1 mb-2 appearance-none rounded relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5';
    var _f = __read(useState(-1), 2), radioButtonSelected = _f[0], setRadioButtonSelected = _f[1];
    var _g = __read(useState(false), 2), displayAuditRequested = _g[0], setDisplayAuditRequested = _g[1];
    var _h = __read(useState(''), 2), auditLink = _h[0], setAuditLink = _h[1];
    var _j = __read(useState(), 2), successToast = _j[0], setSuccessToast = _j[1];
    var _k = __read(useState(false), 2), openSurveyModal = _k[0], setOpenSurveyModal = _k[1];
    var shouldShowPublisherSurvey = function () { return __awaiter(void 0, void 0, void 0, function () {
        var surveys;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!activeOrganization)
                        return [2 /*return*/, false];
                    if (!((_a = activeOrganization.data()) === null || _a === void 0 ? void 0 : _a.showModalPrematurely) &&
                        moment().year() <= 2021)
                        return [2 /*return*/, false];
                    return [4 /*yield*/, Firebase.firestore()
                            .collection(Collections.survey)
                            .where('newspaper', '==', activeOrganization.ref)
                            .where('response.timeSubmitted', '>=', moment()
                            .startOf('quarter')
                            .toDate())
                            .get()];
                case 1:
                    surveys = _b.sent();
                    if (activeOrganization.data().state !== State.florida.value ||
                        activeOrganization.data().organizationType !==
                            OrganizationType.newspaper.value ||
                        surveys.docs.length > 0)
                        return [2 /*return*/, false];
                    return [2 /*return*/, true];
            }
        });
    }); };
    var goBack = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setDisplayAuditRequested(false);
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        setDisableAuditRequestModal(auditLink === '');
    }, [auditLink]);
    useEffect(function () {
        var setModal = function () { return __awaiter(void 0, void 0, void 0, function () {
            var showSurvey;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, shouldShowPublisherSurvey()];
                    case 1:
                        showSurvey = _a.sent();
                        setOpenSurveyModal(showSurvey);
                        return [2 /*return*/];
                }
            });
        }); };
        setModal();
    }, [activeOrganization, successToast]);
    var populateResponse = function () { return __awaiter(void 0, void 0, void 0, function () {
        var now, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    now = new Date();
                    response = {
                        user: user.ref,
                        auditLink: auditLink,
                        timeSubmitted: now,
                        holdsPeriodicalPermit: radioButtonSelected === 2,
                        holdsPeriodicalPermitAndInFiscallyConstrainedCounty: radioButtonSelected === 3
                    };
                    return [4 /*yield*/, Firebase.firestore()
                            .collection(Collections.survey)
                            .add({
                            newspaper: activeOrganization.ref,
                            quarter: moment()
                                .startOf('quarter')
                                .toDate(),
                            response: response
                        })];
                case 1:
                    _a.sent();
                    setSuccessToast("Confirmed! You're all set.");
                    return [2 /*return*/];
            }
        });
    }); };
    if (!openSurveyModal && !successToast) {
        return null;
    }
    return (React.createElement("div", null,
        openSurveyModal && displayAuditRequested && (React.createElement(FreeformCModal, { header: 'Audit requested', setOpen: fromMainView ? undefined : function () { return setFloridaDataSurveyModal(); }, customStyle: 'px-2', noExitOutsideModal: true, width: '30%', maxWidth: 'sm:max-w-2xl' },
            React.createElement("form", { onSubmit: function (e) {
                    e.preventDefault();
                    populateResponse();
                } },
                React.createElement("div", { className: "overflow-y-auto py-2 mt-2 px-2" },
                    React.createElement("div", { className: "text-gray-700 text-sm font-medium -mt-3" }, "Please provide a link or QR code of your audit results."),
                    React.createElement("fieldset", { className: "space-y-5 text-gray-700 text-sm font-medium" },
                        React.createElement("legend", { className: "sr-only" }, "Notifications"),
                        React.createElement("div", { className: "flex items-center h-5" },
                            React.createElement("input", { id: "auditLink", "aria-describedby": "audit-link", name: "comments", type: "text", placeholder: "Link to audit results", className: inputTextStyle, onChange: function (e) {
                                    setAuditLink(e.target.value);
                                } }))),
                    React.createElement("div", { className: "inline-flex" },
                        error && (React.createElement("div", { className: "text-red-500 text-sm my-3" }, error)),
                        React.createElement("button", { className: "mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 " + (disableAuditRequestModal
                                ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                                : 'bg-blue-500'), type: "submit", disabled: disableAuditRequestModal || loading },
                            React.createElement("span", { className: "flex" },
                                loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                                "Confirm and Submit")),
                        React.createElement("button", { className: "mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2", type: "button", disabled: loading, onClick: goBack },
                            React.createElement("span", { className: "flex" },
                                loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                                "Go Back"))))))),
        openSurveyModal && !displayAuditRequested && (React.createElement(FreeformCModal, { header: 'Quarterly check-in', setOpen: fromMainView ? undefined : function () { return setFloridaDataSurveyModal(); }, customStyle: 'px-2', noExitOutsideModal: true, width: '30%', maxWidth: 'sm:max-w-2xl' },
            React.createElement("form", { onSubmit: function (e) {
                    e.preventDefault();
                    populateResponse();
                } },
                React.createElement("div", { className: "overflow-y-auto py-2 mt-2 px-2" },
                    React.createElement("div", { className: "text-gray-700 text-sm font-medium -mt-3" }, "Before uploading your notices, please complete your quarterly check-in."),
                    React.createElement("fieldset", { className: "space-y-5 text-gray-700 text-sm font-medium" },
                        React.createElement("legend", { className: "sr-only" }, "Florida Data Questionnaire"),
                        React.createElement("div", { className: "relative flex items-start" },
                            React.createElement("div", { className: "flex items-center h-5" },
                                React.createElement("input", { id: "questionOne", "aria-describedby": "questionOne-description", name: "questionOne", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: radioButtonSelected === 1, onChange: function (e) {
                                        setRadioButtonSelected(1);
                                        setIsDisabled(false);
                                    } })),
                            React.createElement("div", { className: "ml-3" },
                                React.createElement("span", { id: "questionOne-description", onClick: function (e) {
                                        setRadioButtonSelected(1);
                                        setIsDisabled(false);
                                    } },
                                    "Our newspaper has a certified audit that the newspaper has a combined number of print copies and monthly online visitors exceeding 10% of the county households for notices affecting a county or the city households where city notices are concerned. See",
                                    ' ',
                                    React.createElement("a", { href: "http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/Sections/0050.011.html", className: "text-blue-500" }, "50.011(c)1"),
                                    "."))),
                        React.createElement("div", { className: "relative flex items-start" },
                            React.createElement("div", { className: "flex items-center h-5" },
                                React.createElement("input", { id: "questionTwo", "aria-describedby": "questionTwo-description", name: "questionTwo", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: radioButtonSelected === 2, onChange: function (e) {
                                        setRadioButtonSelected(2);
                                        setIsDisabled(false);
                                    } })),
                            React.createElement("div", { className: "ml-3" },
                                React.createElement("span", { id: "questionTwo-description", onClick: function (e) {
                                        setRadioButtonSelected(2);
                                        setIsDisabled(false);
                                    } },
                                    "Our newspaper held a periodicals permit as of March 1, 2021 and currently holds a permit [this criteria will only be valid until January 1, 2024]. See",
                                    ' ',
                                    React.createElement("a", { href: "http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/Sections/0050.011.html", className: "text-blue-500" }, "50.011(c)2"),
                                    "."))),
                        React.createElement("div", { className: "relative flex items-start" },
                            React.createElement("div", { className: "flex items-center h-5" },
                                React.createElement("input", { id: "questionThree", "aria-describedby": "questionThree-description", name: "questionThree", type: "radio", className: "form-radio h-4 w-4 text-blue-500 transition duration-150 ease-in-out", checked: radioButtonSelected === 3, onChange: function (e) {
                                        setRadioButtonSelected(3);
                                        setIsDisabled(false);
                                    } })),
                            React.createElement("div", { className: "ml-3" },
                                React.createElement("span", { id: "questionThree-description", onClick: function (e) {
                                        setRadioButtonSelected(3);
                                        setIsDisabled(false);
                                    } },
                                    "Our newspaper publishes public notices in a fiscally constrained county and currently holds a periodicals permit, in which case the audience thresholds do not apply. See",
                                    ' ',
                                    React.createElement("a", { href: "http://www.leg.state.fl.us/statutes/index.cfm?App_mode=Display_Statute&URL=0000-0099/0050/Sections/0050.011.html", className: "text-blue-500" }, "50.011(c)3"),
                                    ".")))),
                    React.createElement("div", null,
                        error && (React.createElement("div", { className: "text-red-500 text-sm my-3" }, error)),
                        React.createElement("button", { className: "mt-7 rounded-md font-semibold bg-opacity-25 text-blue-600 text-sm flex items-center px-5 py-2 " + (isDisabled
                                ? 'cursor-not-allowed pointer-events-none bg-gray-500'
                                : 'bg-blue-500'), type: "submit", disabled: isDisabled || loading },
                            React.createElement("span", { className: "flex" },
                                loading && (React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-5 w-5 mr-2" })),
                                "Confirm and Submit"))))))),
        successToast && (React.createElement(EToast, { message: successToast, close: function () { return setSuccessToast(''); }, type: "success" }))));
};
export default SurveyModal;
