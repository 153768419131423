var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Route } from 'react-router';
import Frame from '../layouts/Frame';
var WithFrame = function (props) {
    var exact = props.exact, path = props.path, component = props.component, hideFrame = props.hideFrame, noFramePadding = props.noFramePadding;
    var WithFrame = function (Component) { return function (props) {
        return (React.createElement(Frame, __assign({ noFramePadding: noFramePadding }, props),
            React.createElement(Component, __assign({}, props))));
    }; };
    var ExtendedComponent = hideFrame ? component : WithFrame(component);
    return (React.createElement(Route, { exact: exact, path: path, render: function () { return React.createElement(ExtendedComponent, null); } }));
};
export default WithFrame;
