var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, takeEvery, takeLatest, select, put, call, delay, race, take } from 'redux-saga/effects';
import api from 'api';
import { ARCHIVED_NOTICES_TAB, DRAFT_NOTICES_TAB, CREATE_TIME, SORT_DESCENDING, CONFIRMED_AT } from 'routes/userNoticeList/table/types';
import { authSelector, AuthTypes } from '../redux/auth';
import { OccupationType } from '../lib/enums';
import NoticeActions, { NoticeTypes, noticesSelector } from '../redux/notices';
function getQuery(hideLoading) {
    var _a, isPublisher, showAllOrgsNotices, _b, tab, sort, search, showUserOnlyNotices, currentPage, pageSize, getNotices, _c, results, page;
    var _this = this;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                _a = _d.sent(), isPublisher = _a.isPublisher, showAllOrgsNotices = _a.showAllOrgsNotices;
                return [4 /*yield*/, select(noticesSelector)];
            case 2:
                _b = _d.sent(), tab = _b.tab, sort = _b.sort, search = _b.search, showUserOnlyNotices = _b.showUserOnlyNotices, currentPage = _b.currentPage, pageSize = _b.pageSize;
                getNotices = function () { return __awaiter(_this, void 0, void 0, function () {
                    var defaultSort;
                    return __generator(this, function (_a) {
                        defaultSort = tab === DRAFT_NOTICES_TAB
                            ? {
                                sortField: sort.field || (search ? '' : CREATE_TIME),
                                sortDirection: sort.direction || (search ? '' : SORT_DESCENDING)
                            }
                            : {
                                sortField: sort.field || (search ? '' : CONFIRMED_AT),
                                sortDirection: sort.direction || (search ? '' : SORT_DESCENDING)
                            };
                        return [2 /*return*/, api.post('search/usernotices', {
                                current: currentPage + 1,
                                size: pageSize,
                                sortDirection: defaultSort.sortDirection,
                                sortField: defaultSort.sortField,
                                search: search,
                                isPublisher: isPublisher,
                                showAllOrgsNotices: showAllOrgsNotices,
                                showUserOnlyNotices: showUserOnlyNotices,
                                isArchivedTab: tab === ARCHIVED_NOTICES_TAB,
                                isDraftTab: tab === DRAFT_NOTICES_TAB
                            })];
                    });
                }); };
                if (!!hideLoading) return [3 /*break*/, 4];
                return [4 /*yield*/, put(NoticeActions.setFetching(true))];
            case 3:
                _d.sent();
                _d.label = 4;
            case 4: return [4 /*yield*/, call(getNotices)];
            case 5:
                _c = _d.sent(), results = _c.results, page = _c.page;
                if (!(results && page)) return [3 /*break*/, 8];
                return [4 /*yield*/, put(NoticeActions.setFetching(false))];
            case 6:
                _d.sent();
                return [4 /*yield*/, put(NoticeActions.setTotal(page.total_results))];
            case 7:
                _d.sent();
                _d.label = 8;
            case 8: return [2 /*return*/, results];
        }
    });
}
function updateNotices(hideLoading) {
    var auth, noticess, _a, total, notices;
    if (hideLoading === void 0) { hideLoading = false; }
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                auth = _b.sent();
                if (!auth.user)
                    return [2 /*return*/];
                if (auth.user.data().occupation !== OccupationType.individual.value &&
                    !auth.activeOrganization)
                    return [2 /*return*/];
                return [4 /*yield*/, call(getQuery, typeof hideLoading === 'boolean' ? hideLoading : false)];
            case 2:
                noticess = _b.sent();
                return [4 /*yield*/, select(noticesSelector)];
            case 3:
                _a = _b.sent(), total = _a.total, notices = _a.notices;
                return [4 /*yield*/, put(NoticeActions.setNotices(noticess))];
            case 4:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
// Resets the current page in the table to the first page upon switching tabs
function resetPage() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(NoticeActions.setCurrentPage(0))];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function setTableParameters(_a) {
    var noticeTablePageSize;
    var user = _a.user;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (!user)
                    return [2 /*return*/];
                noticeTablePageSize = user.data().noticeTablePageSize;
                if (!noticeTablePageSize)
                    return [2 /*return*/];
                return [4 /*yield*/, put(NoticeActions.setPageSize(noticeTablePageSize))];
            case 1:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function refreshActiveNotices() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 3];
                return [4 /*yield*/, delay(60000)];
            case 1:
                _a.sent();
                return [4 /*yield*/, race({
                        task: call(updateNotices, true),
                        cancel: take(AuthTypes.SET_ACTIVE_ORGANIZATION)
                    })];
            case 2:
                _a.sent();
                return [3 /*break*/, 0];
            case 3: return [2 /*return*/];
        }
    });
}
export default function root() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, all([
                    takeLatest([
                        AuthTypes.SET_USER,
                        NoticeTypes.UPDATE_NOTICES,
                        AuthTypes.SET_ACTIVE_ORGANIZATION,
                        NoticeTypes.SET_TAB,
                        NoticeTypes.SET_CURRENT_PAGE,
                        NoticeTypes.SET_PAGE_SIZE,
                        NoticeTypes.SET_SORT,
                        NoticeTypes.SET_SEARCH,
                        NoticeTypes.SET_SHOW_USER_ONLY_NOTICES
                    ], updateNotices),
                    takeEvery(NoticeTypes.SET_TAB, resetPage),
                    takeEvery(AuthTypes.SHOW_ALL_ORGS_NOTICES, updateNotices),
                    takeEvery(AuthTypes.SET_USER, function (action) { return setTableParameters(action); })
                ])];
            case 1:
                _a.sent();
                return [4 /*yield*/, call(refreshActiveNotices)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
