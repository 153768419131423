var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createReducer, createActions } from 'reduxsauce';
/* ------------- Types and Action Creators ------------- */
var _b = createActions({
    setError: ['error']
}), Types = _b.Types, Creators = _b.Creators;
export var ErrorTypes = Types;
export default Creators;
var INITIAL_STATE = {
    error: null
};
/* ------------- Reducer ------------- */
export var reducer = createReducer(INITIAL_STATE, (_a = {},
    _a[Types.SET_ERROR] = function (state, _a) {
        var error = _a.error;
        return (__assign(__assign({}, state), { error: error }));
    },
    _a));
