var EnumItemPrototype = {
    child_item_keys: [],
    parentEnum: {},
    children: function () {
        var _this = this;
        if (!this.child_item_keys) {
            return [];
        }
        var children = this.child_item_keys.map(function (childValue) {
            return _this.parentEnum[childValue];
        });
        return children;
    }
};
var Enum = /** @class */ (function () {
    function Enum(items) {
        var _this = this;
        this._items = {};
        var extendedItems = {};
        Object.keys(items).forEach(function (key) {
            var extended = (extendedItems[key] = Object.create(EnumItemPrototype));
            extended = Object.assign(extended, items[key]);
            extended.parentEnum = _this;
        });
        Object.assign(this, extendedItems);
        this._items = extendedItems;
    }
    Enum.prototype.by_value = function (val) {
        var _this = this;
        if (!this._items_by_value) {
            this._items_by_value = {};
            this.items().forEach(function (item) { return (_this._items_by_value[item.value] = item); });
        }
        return this._items_by_value[parseInt(val, 10)];
    };
    Enum.prototype.by_label = function (label) {
        var _this = this;
        if (!this._items_by_label) {
            this._items_by_label = {};
            this.items().forEach(function (item) { return (_this._items_by_label[item.label] = item); });
        }
        return this._items_by_label[label];
    };
    Enum.prototype.items = function () {
        var _this = this;
        if (!this._items_cache) {
            this._items_cache = Object.keys(this._items)
                .map(function (key) { return _this._items[key]; })
                .filter(function (value) { return value.value; });
        }
        return this._items_cache;
    };
    Enum.prototype.rootItems = function () {
        var _this = this;
        if (!this._items_cache) {
            this._items_cache = Object.keys(this._items)
                .map(function (key) { return _this._items[key]; })
                .filter(function (value) { return value.value; });
        }
        return this._items_cache.filter(function (item) { return item.is_root_item; });
    };
    return Enum;
}());
export default Enum;
