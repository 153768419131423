export default {
    daily: {
        value: 1,
        label: 'Daily',
        key: 'daily'
    },
    weekly: {
        value: 2,
        label: 'Weekly',
        key: 'weekly'
    },
    monthly: {
        value: 3,
        label: 'Monthly',
        key: 'monthly'
    }
};
