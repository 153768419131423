export default {
    'America/New_York': {
        value: 1,
        logical: 'America/New_York',
        label: 'America/New_York (ET)'
    },
    'America/Chicago': {
        value: 2,
        logical: 'America/Chicago',
        label: 'America/Chicago (CT)'
    },
    'America/Denver': {
        value: 3,
        logical: 'America/Denver',
        label: 'America/Denver (MT)'
    },
    'America/Los_Angeles': {
        value: 4,
        logical: 'America/Los_Angeles',
        label: 'America/Los_Angeles (PT)'
    },
    'Europe/London': {
        value: 5,
        logical: 'Europe/London',
        label: 'Europe/London (UTC)'
    },
    'America/Anchorage': {
        value: 6,
        logical: 'America/Anchorage',
        label: 'America/Anchorage (AKT)'
    },
    'America/Honolulu': {
        value: 7,
        logical: 'America/Honolulu',
        label: 'America/Honolulu (HT)'
    },
    'America/Phoenix': {
        value: 8,
        logical: 'America/Phoenix',
        label: 'America/Phoenix (MST)'
    }
};
