export var ACTIVE_NOTICES_TAB = 'active-notices';
export var DRAFT_NOTICES_TAB = 'draft-notices';
export var ARCHIVED_NOTICES_TAB = 'archived-notices';
export var SORT_ASCENDING = 'asc';
export var SORT_DESCENDING = 'desc';
export var PUBLICATION_DATES = 'firstpublicationtimestamp';
export var CONFIRMED = 'isconfirmed';
export var BILLING_STATUS = 'billingstatus';
export var AFFIDAVIT_STATUS = 'affidavitsubmitted';
export var NEWSPAPER_NAME = 'publication';
export var CREATE_TIME = 'createtime';
export var CONFIRMED_AT = 'confirmedat';
