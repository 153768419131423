var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import AuthActions from 'redux/auth';
import NoticeActions from 'redux/notices';
import { OccupationType, OrganizationType } from 'lib/enums';
import { AddIcon, ArrowDownIcon } from 'icons';
import { ACTIVE_NOTICES_TAB } from 'routes/userNoticeList/table/types';
var mapStateToProps = function (state) { return ({
    availableOrganizations: state.auth.availableOrganizations,
    activeOrganization: state.auth.activeOrganization,
    user: state.auth.user,
    showAllOrgsNotices: state.auth.showAllOrgsNotices,
    pathname: state.router.location.pathname
}); };
var mapDispatchToProps = function (dispatch) { return ({
    authActions: bindActionCreators(AuthActions, dispatch),
    noticeActions: bindActionCreators(NoticeActions, dispatch),
    push: function (path) { return dispatch(push(path)); }
}); };
var ALL_ORGANIZATIONS = 'all-organizations';
export var formatPaperName = function (name) {
    if (name.startsWith('The ')) {
        return name.replace('The ', '');
    }
    return name;
};
var ActiveOrganizationSelect = function (_a) {
    var authActions = _a.authActions, noticeActions = _a.noticeActions, activeOrganization = _a.activeOrganization, availableOrganizations = _a.availableOrganizations, push = _a.push, user = _a.user, showAllOrgsNotices = _a.showAllOrgsNotices, open = _a.open, setOpen = _a.setOpen, pathname = _a.pathname;
    var _b, _c, _d;
    var _e = __read(useState(''), 2), selectedOrg = _e[0], setSelectedOrg = _e[1];
    useEffect(function () {
        if (activeOrganization) {
            setSelectedOrg(activeOrganization.id);
        }
    }, [activeOrganization]);
    var shouldShowAllOrgs = user &&
        user.data() &&
        availableOrganizations &&
        availableOrganizations.length > 1;
    var renderMenu = function () {
        if (open)
            return (React.createElement("div", { className: "w-max z-20 border origin-top-right absolute right-1 mt-2 max-w-xs md:max-w-sm lg:max-w-xl rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 overflow-scroll", style: { maxHeight: '92.5vh' } },
                availableOrganizations
                    .sort(function (o1, o2) { return (o1.data().name < o2.data().name ? -1 : 1); })
                    .map(function (o) { return (React.createElement("div", { key: o.id, className: "w-full block px-4 py-2 text-sm font-normal text-gray-750 " + (selectedOrg === o.id ? 'bg-gray-100' : 'hover:bg-gray-100') + " cursor-pointer", onClick: function () {
                        var _a;
                        setSelectedOrg(o.id);
                        authActions.setActiveOrganization(o);
                        if (showAllOrgsNotices)
                            authActions.showAllOrgsNotices(false);
                        noticeActions.setTab(ACTIVE_NOTICES_TAB);
                        noticeActions.setCurrentPage(0);
                        var organizationType = (_a = o === null || o === void 0 ? void 0 : o.data()) === null || _a === void 0 ? void 0 : _a.organizationType;
                        if (organizationType !==
                            OrganizationType.holding_company.value &&
                            pathname !== '/notices/')
                            push('/notices/');
                        else if (organizationType ===
                            OrganizationType.holding_company.value &&
                            pathname !== '/reports/') {
                            push('/reports/');
                        }
                        setOpen(false);
                    } }, formatPaperName(o.data().name))); }),
                shouldShowAllOrgs && (React.createElement("div", { className: "w-full block px-4 py-2 text-sm font-normal text-gray-750 " + (selectedOrg === ALL_ORGANIZATIONS
                        ? 'bg-gray-100'
                        : 'hover:bg-gray-100') + " cursor-pointer", onClick: function () {
                        setSelectedOrg(ALL_ORGANIZATIONS);
                        authActions.showAllOrgsNotices(true);
                        if (window.location.pathname !== '/notices/')
                            push('/notices/');
                        setOpen(false);
                    } }, "All Organizations")),
                user.data().occupation === OccupationType.publishing.value && (React.createElement("div", { "data-testid": "add-publication-button", className: "w-full block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer flex items-center", onClick: function () { return push('/register/organization?type=sub-org'); } },
                    React.createElement(AddIcon, { className: "w-4 h-4 mr-1" }),
                    "Add Publication"))));
    };
    return availableOrganizations.length ? (React.createElement("div", { className: "ml-2", "data-testid": "active-org" }, user.data().occupation !== OccupationType.publishing.value &&
        availableOrganizations.length === 1 ? (React.createElement("div", { className: "bg-white p-1 rounded-full text-gray-700 focus:outline-none" }, formatPaperName(((_c = (_b = availableOrganizations[0]) === null || _b === void 0 ? void 0 : _b.data()) === null || _c === void 0 ? void 0 : _c.name) || ''))) : (React.createElement("div", { className: "ml-2 relative" },
        React.createElement("div", null,
            React.createElement("button", { className: "max-w-xs md:max-w-sm lg:max-w-xl inline-block relative bg-white p-1 rounded-full text-gray-700 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex items-center", onClick: function () { return setOpen(!open); }, id: "activeOrg", "data-testid": "active-org-select" },
                React.createElement("span", { className: "sr-only" }, "View organizations"),
                showAllOrgsNotices ? ('All Organizations') : (React.createElement("span", { className: "truncate" }, formatPaperName(((_d = activeOrganization === null || activeOrganization === void 0 ? void 0 : activeOrganization.data()) === null || _d === void 0 ? void 0 : _d.name) || ''))),
                React.createElement(ArrowDownIcon, { className: "ml-2 mt-1" }))),
        renderMenu())))) : null;
};
export default connect(mapStateToProps, mapDispatchToProps)(ActiveOrganizationSelect);
