import { ReactComponent as cancel } from './cancel.svg';
import { ReactComponent as deleteIcon } from './delete.svg';
import { ReactComponent as document } from './document.svg';
import { ReactComponent as dollar } from './dollar.svg';
import { ReactComponent as edit } from './edit.svg';
import { ReactComponent as lock } from './lock.svg';
import { ReactComponent as download } from './download.svg';
import { ReactComponent as pencil } from './pencil.svg';
import { ReactComponent as invoice } from './invoice.svg';
import { ReactComponent as profile } from './profile.svg';
import { ReactComponent as calendar } from './calendar.svg';
import { ReactComponent as globe } from './globe.svg';
import { ReactComponent as billingStatus } from './billingStatus.svg';
import { ReactComponent as link } from './link.svg';
import { ReactComponent as copy } from './copy.svg';
import { ReactComponent as upload } from './upload.svg';
import { ReactComponent as arrow } from './arrowIcon.svg';
import { ReactComponent as x } from './x.svg';
import { ReactComponent as externalLink } from './externalLink.svg';
// import { ReactComponent as questionMarkRounded } from './questionMarkRounded.svg';
import { ReactComponent as closeIcon } from './closeIcon.svg';
import { ReactComponent as arrowRight } from './arrowRight.svg';
import { ReactComponent as flag } from './flag.svg';
import { ReactComponent as printer } from './printer.svg';
import { ReactComponent as cog } from './cog.svg';
import { ReactComponent as questionMarkRounded } from './questionMarkRounded.svg';
import { ReactComponent as bell } from './bell.svg';
import { ReactComponent as accountCircle } from './accountCircle.svg';
// import { ReactComponent as add } from './add.svg';
import { ReactComponent as arrowDown } from './arrowDown.svg';
import { ReactComponent as questionMarkRoundedFilled } from './questionMarkRoundedFilled.svg';
// import { ReactComponent as closeIcon } from './closeIcon.svg';
import { ReactComponent as invalid } from './invalid.svg';
import { ReactComponent as checkIcon } from './checkIcon.svg';
import { ReactComponent as clockIcon } from './clockIcon.svg';
// import { ReactComponent as arrowRight } from './arrowRight.svg';
import { ReactComponent as arrowLeft } from './arrowLeft.svg';
import { ReactComponent as arrowsExpand } from './arrowsExpand.svg';
import { ReactComponent as searchIcon } from './searchIcon.svg';
import { ReactComponent as addIcon } from './addIcon.svg';
import { ReactComponent as checkRounded } from './checkRounded.svg';
import { ReactComponent as checkOutline } from './checkOutline.svg';
import { ReactComponent as invalidRounded } from './invalidRounded.svg';
import { ReactComponent as arrowRightWithTail } from './arrowRightWithTail.svg';
import { ReactComponent as trash } from './trash.svg';
import { ReactComponent as updateAccount } from './updateAccount.svg';
import { ReactComponent as eye } from './eye.svg';
import { ReactComponent as eyeOff } from './eyeOff.svg';
import { ReactComponent as refresh } from './refresh.svg';
import { ReactComponent as mail } from './mail.svg';
import { ReactComponent as clipboardList } from './clipbaordList.svg';
import { ReactComponent as Bookmark } from './bookmark.svg';
import { ReactComponent as bank } from './bank.svg';
import { ReactComponent as card } from './cards.svg';
import { ReactComponent as column } from './column.svg';
import { ReactComponent as onlinePay } from './online-pay.svg';
import { ReactComponent as rewards } from './rewards.svg';
import { ReactComponent as visa } from './visa.svg';
export var RefreshIcon = refresh;
export var CancelIcon = cancel;
export var DeleteIcon = deleteIcon;
export var DocumentIcon = document;
export var DollarIcon = dollar;
export var EditIcon = edit;
export var LockIcon = lock;
export var DownloadIcon = download;
export var PencilIcon = pencil;
export var InvoiceIcon = invoice;
export var ProfileIcon = profile;
export var CalendarIcon = calendar;
export var GlobeIcon = globe;
export var BillingStatusIcon = billingStatus;
export var LinkIcon = link;
export var CopyIcon = copy;
export var UploadIcon = upload;
export var ArrowIcon = arrow;
export var XIcon = x;
export var ExternalLinkIcon = externalLink;
export var QuestionMarkRoundedIcon = questionMarkRounded;
export var CloseIcon = closeIcon;
export var ArrowRightIcon = arrowRight;
export var FlagIcon = flag;
export var PrinterIcon = printer;
export var CogIcon = cog;
// export const QuestionMarkRoundedIcon = questionMarkRounded;
export var BellIcon = bell;
export var AccountCircleIcon = accountCircle;
// export const AddIcon = add;
export var ArrowDownIcon = arrowDown;
export var QuestionMarkRoundedFilledIcon = questionMarkRoundedFilled;
// export const CloseIcon = closeIcon;
export var InvalidIcon = invalid;
export var CheckIcon = checkIcon;
export var ClockIcon = clockIcon;
export var ArrowRight = arrowRight;
export var ArrowLeft = arrowLeft;
export var SearchIcon = searchIcon;
export var AddIcon = addIcon;
export var CheckRoundedIcon = checkRounded;
export var CheckOutlinedIcon = checkOutline;
export var InvalidRoundedIcon = invalidRounded;
export var ArrowRightWithTailIcon = arrowRightWithTail;
export var Trash = trash;
export var UpdateAccount = updateAccount;
export var Eye = eye;
export var EyeOff = eyeOff;
export var Mail = mail;
export var ClipbaordList = clipboardList;
export var ArrowsExpand = arrowsExpand;
export var BookmarkIcon = Bookmark;
export var BankIcon = bank;
export var CardIcon = card;
export var ColumnLogo = column;
export var OnlinePayIcon = onlinePay;
export var RewardsIcon = rewards;
export var VisaIcon = visa;
