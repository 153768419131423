import moment from 'moment-timezone';
export var nDaysAgo = function (n) {
    var now = new Date();
    var pastDate = now.getDate() - n;
    now.setDate(pastDate);
    return now;
};
export var endOfDay = function () {
    var m = moment().endOf('day');
    m.hour(17);
    return m.utc().toDate();
};
export var dateTimeLikeToDate = function (d) {
    if (!d)
        return null;
    if (d['.sv'])
        return null;
    return d ? (d.toDate ? d.toDate() : d) : null;
};
export var dateTimeLikeToTimestamp = function (d) {
    if (!d)
        return null;
    if (d.toDate)
        return d;
    return { toDate: function () { return d; } };
};
