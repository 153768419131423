var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { all, take, takeEvery, call, put, select, race, takeLatest } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
import { authSelector, AuthTypes } from 'redux/auth';
import JoyRideActions, { joyrideSelector, JoyRideTypes } from 'redux/joyrides';
import pathToRegex from 'path-to-regexp';
import Firebase, { Collections } from '../EnoticeFirebase';
// This file may be deprecated now that we use Pendo for user guides
var test = function (path, pathname) {
    if (!path)
        return false;
    return pathToRegex(path).test(pathname);
};
var placementCB = function (e) { return __awaiter(void 0, void 0, void 0, function () {
    var enableSteps, disableSteps, scrollTo;
    var _a;
    return __generator(this, function (_b) {
        if ((e === null || e === void 0 ? void 0 : e.type) === 'error:target_not_found')
            return [2 /*return*/];
        enableSteps = function () {
            var disabled = document.querySelectorAll("[class*='disabled-scroll-step']");
            Array.from(disabled).forEach(function (el) {
                // eslint-disable-next-line no-param-reassign
                el.style.opacity = 100;
            });
        };
        disableSteps = function () {
            var disabled = document.querySelectorAll("[class*='disabled-scroll-step']");
            Array.from(disabled).forEach(function (el) {
                // eslint-disable-next-line no-param-reassign
                el.style.opacity = '';
            });
        };
        scrollTo = function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var target, navStyle, navHeight, formContainer;
            return __generator(this, function (_a) {
                try {
                    target = e.step.scrollTarget || e.step.target;
                    if (!document.querySelector(target))
                        return [2 /*return*/];
                    navStyle = window.getComputedStyle(document.getElementById('nav-spacer'));
                    navHeight = parseFloat(navStyle.getPropertyValue('height'));
                    formContainer = document.getElementById('place-notice-form-container');
                    formContainer.scrollTo({
                        top: document.querySelector(target).offsetTop - navHeight - 16,
                        behavior: 'smooth'
                    });
                }
                catch (err) {
                    console.error(err);
                }
                return [2 /*return*/];
            });
        }); };
        if (e.action === 'update') {
            enableSteps();
        }
        else if (e.action === 'reset') {
            disableSteps();
        }
        else if (e.action === 'close') {
            disableSteps();
        }
        if (!((_a = e === null || e === void 0 ? void 0 : e.step) === null || _a === void 0 ? void 0 : _a.noScroll)) {
            scrollTo(e);
        }
        return [2 /*return*/];
    });
}); };
var getRelevantJoyRide = function (joyRides) {
    return joyRides
        .filter(function (j) {
        return test(j.data().route, window.location.pathname + window.location.search);
    })
        .sort(function (a, b) {
        return a.data().start.toDate() - b.data().start.toDate();
    })[0];
};
var delay = function (time) {
    return new Promise(function (resolve) { return setTimeout(resolve, time); });
};
function updateJoyRide() {
    var _a, joyRides, activeJoyRide, jr, cb;
    var _this = this;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(joyrideSelector)];
            case 1:
                _a = _b.sent(), joyRides = _a.joyRides, activeJoyRide = _a.activeJoyRide;
                if (!joyRides)
                    return [2 /*return*/];
                jr = getRelevantJoyRide(joyRides);
                if (!jr || (activeJoyRide === null || activeJoyRide === void 0 ? void 0 : activeJoyRide.id) === (jr === null || jr === void 0 ? void 0 : jr.id))
                    return [2 /*return*/];
                cb = function (_) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                    return [2 /*return*/];
                }); }); };
                if (window.location.pathname.includes('/place/')) {
                    cb = placementCB;
                }
                return [4 /*yield*/, call(delay, 200)];
            case 2:
                _b.sent();
                return [4 /*yield*/, put(JoyRideActions.setActiveJoyRide(jr, cb))];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
var extractRelevantJoyRides = function (user, joyrides) { return __awaiter(void 0, void 0, void 0, function () {
    var results;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(joyrides.map(function (joyride) { return __awaiter(void 0, void 0, void 0, function () {
                    var _a, occupations, staging, existingJoyride;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = joyride.data(), occupations = _a.occupations, staging = _a.staging;
                                if (occupations && !occupations.includes(user.data().occupation))
                                    return [2 /*return*/, null];
                                if (staging && !user.data().stagingJoyRides)
                                    return [2 /*return*/, null];
                                return [4 /*yield*/, Firebase.firestore()
                                        .collection(Collections.joyrides)
                                        .doc(joyride.id)
                                        .collection(Collections.users)
                                        .doc(user.id)
                                        .get()];
                            case 1:
                                existingJoyride = _b.sent();
                                if (existingJoyride.exists)
                                    return [2 /*return*/, null];
                                return [2 /*return*/, joyride];
                        }
                    });
                }); }))];
            case 1:
                results = _a.sent();
                return [2 /*return*/, results.filter(Boolean)];
        }
    });
}); };
export function getJoyRide(_a) {
    var joyRidesQuery, joyRidesQueryResults, joyRidesChannel, joyRidesQuery_1, allJoyRides, joyrides;
    var user = _a.user;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                // feature flag to not set any joyrides in e2e env
                if (localStorage.getItem('ise2e'))
                    return [2 /*return*/];
                return [4 /*yield*/, Firebase.firestore().collection(Collections.joyrides)];
            case 1:
                joyRidesQuery = _b.sent();
                return [4 /*yield*/, call([joyRidesQuery, joyRidesQuery.get])];
            case 2:
                joyRidesQueryResults = _b.sent();
                joyRidesChannel = eventChannel(function (emitter) {
                    return joyRidesQueryResults.query.onSnapshot(emitter);
                });
                _b.label = 3;
            case 3:
                if (!true) return [3 /*break*/, 8];
                return [4 /*yield*/, race({
                        joyRidesQuery: take(joyRidesChannel),
                        logout: take(AuthTypes.LOGOUT)
                    })];
            case 4:
                joyRidesQuery_1 = (_b.sent()).joyRidesQuery;
                if (!joyRidesQuery_1)
                    return [3 /*break*/, 8];
                allJoyRides = joyRidesQuery_1.docs;
                return [4 /*yield*/, call(extractRelevantJoyRides, user, allJoyRides)];
            case 5:
                joyrides = _b.sent();
                if (!joyrides) return [3 /*break*/, 7];
                return [4 /*yield*/, put(JoyRideActions.setJoyRides(joyrides))];
            case 6:
                _b.sent();
                _b.label = 7;
            case 7: return [3 /*break*/, 3];
            case 8: return [2 /*return*/];
        }
    });
}
function markJoyRideRead() {
    var user, _a, activeJoyRide, joyRides, markRead;
    var _this = this;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                user = (_b.sent()).user;
                return [4 /*yield*/, select(joyrideSelector)];
            case 2:
                _a = _b.sent(), activeJoyRide = _a.activeJoyRide, joyRides = _a.joyRides;
                if (!activeJoyRide)
                    return [2 /*return*/];
                return [4 /*yield*/, put(JoyRideActions.setActiveJoyRide(null))];
            case 3:
                _b.sent();
                return [4 /*yield*/, put(JoyRideActions.setJoyRides(joyRides.filter(function (j) { return j.id !== activeJoyRide.id; })))];
            case 4:
                _b.sent();
                markRead = function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, Firebase.firestore()
                                    .collection(Collections.joyrides)
                                    .doc(activeJoyRide.id)
                                    .collection(Collections.users)
                                    .doc(user.id)
                                    .set({
                                    seenAt: new Date()
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); };
                return [4 /*yield*/, call(markRead)];
            case 5:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
export default function root() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(LOCATION_CHANGE, updateJoyRide)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(JoyRideTypes.SET_NEW_JOY_RIDE_POSSIBLE, updateJoyRide)];
            case 2:
                _a.sent();
                return [4 /*yield*/, all([
                        takeEvery(LOCATION_CHANGE, updateJoyRide),
                        takeEvery(JoyRideTypes.SET_JOY_RIDES, updateJoyRide),
                        takeEvery(JoyRideTypes.MARK_JOY_RIDE_READ, markJoyRideRead),
                        takeEvery(AuthTypes.SET_USER, function (action) { return getJoyRide(action); })
                    ])];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
