var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import sanitize from 'sanitize-html';
import moment from 'moment-timezone';
import convert from 'convert-length';
import * as Sentry from '@sentry/browser';
import { FIREBASE_PROJECT, CLOUDINARY_BUCKET, IndesignRoute } from './constants';
import { getShouldUseSD, isRouteSupportedBySD } from './silicon_publishing/utils';
import { getDeadlineTimeForPaper, publishingDayEnumValuesFromDeadlines, disableNonPublishingDays } from './utils/deadlines';
import { OrganizationType, OccupationType, RoleType, InvoiceStatus, NoticeStatusType, NoticeType, CurrencyType, Country, RateType } from './enums';
import { FileType } from './types/upload';
import { getOrCreateCustomer, getOrCreateCustomerOrganizationForNotice, getOrCreateCustomerOrganization } from './notice/customer';
import { shouldPreserveLongSequencesForNotice, htmlToIndesignHtml } from './indesign/helpers';
import { addFooterXML, generateFormattedFooter } from './headers_footers/footers';
import { callOldIndesign, callRenderNotice } from './requests';
import { getColumnCentimeters, getColumnInches } from './pricing';
export var getOccupationValFromOrganizationVal = function (organizationVal) {
    var organizationType = OrganizationType.by_value(organizationVal);
    return OccupationType[organizationType.defaultOccupationKey].value;
};
export var getOrganizationValFromOccupationVal = function (occupationVal) {
    var occupationKey = OccupationType.by_value(occupationVal).key;
    return Object.values(OrganizationType).find(function (org) { return org && org.defaultOccupationKey === occupationKey; }).value;
};
export var dateToDateString = function (date, timezone) {
    date.setHours(12);
    return moment(date)
        .tz(timezone || 'America/Chicago')
        .format(!(timezone === null || timezone === void 0 ? void 0 : timezone.startsWith('America')) ? 'D MMM YYYY' : 'MMM. D, YYYY');
};
export var unixTimeStampToDateString = function (timestamp, timezone) {
    return dateToDateString(new Date(timestamp * 1000), timezone);
};
export var dateToAbbrev = function (date, timezone) {
    if (!timezone)
        date.setHours(12);
    return moment(date)
        .tz(timezone || 'America/Chicago')
        .format(timezone && !(timezone === null || timezone === void 0 ? void 0 : timezone.startsWith('America')) ? 'D/M/YY' : 'M/D/YY');
};
export var dateToUtc = function (date) {
    return moment.utc(date).format('MMM. D, YYYY');
};
export var toLocaleString = function (date) {
    return moment(date).format('MMM. D, YYYY');
};
export var firestoreTimestampOrDateToDate = function (date) {
    try {
        if (date._seconds)
            return new Date(date._seconds * 1000);
    }
    catch (err) { }
    if (!date)
        throw new Error('date is undefined');
    try {
        return date.toDate();
    }
    catch (err) {
        if (date.seconds)
            return new Date(date.seconds * 1000);
        if (typeof date === 'number')
            return new Date(date);
        if (typeof date === 'string')
            return new Date(date);
        return date;
    }
};
export var areSameDay = function (d1, d2) {
    return (d1.getDate() === d2.getDate() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getFullYear() === d2.getFullYear());
};
export var escapeHTML = function (str) {
    var entityMap = new Map(Object.entries({
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#39;'
    }));
    return str.replace(/[&<>"']/g, function (tag) { return entityMap.get(tag) || tag; });
};
export var centsToDollarsString = function (cents) {
    return (cents / 100).toFixed(2);
};
export var confirmationNumberFromTransferId = function (transferId) {
    return transferId.slice(-8);
};
export var guidGenerator = function () {
    var S4 = function () {
        return ((1 + Math.random()) * 0x10000 || 0).toString(16).substring(1);
    };
    return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
};
export var rateCodeGenerator = function () {
    return Math.floor(100000 + Math.random() * 900000);
};
export var sameDay = function (d1, d2) {
    return (d1.getFullYear() === d2.getFullYear() &&
        d1.getMonth() === d2.getMonth() &&
        d1.getDate() === d2.getDate());
};
var isValidDate = function (d) {
    return !Number.isNaN(Date.parse(d.toDateString()));
};
export var closestDayFutureDay = function (dates) {
    var closest = Infinity;
    var startOfToday = new Date(Date.now());
    startOfToday.setHours(0, 0, 0, 0);
    var comparableStartOfToday = startOfToday.getTime();
    dates.forEach(function (date) {
        var comparableDate = date.getTime();
        if (comparableDate >= comparableStartOfToday && comparableDate < closest) {
            closest = comparableDate;
        }
    });
    var close = new Date(closest);
    if (!isValidDate(close))
        return null;
    return close;
};
export var lastPublicationDate = function (dates) {
    var lastDate = dates[dates.length - 1];
    if (!lastDate)
        return null;
    if (!isValidDate(lastDate))
        return null;
    return lastDate;
};
export var firstNoticePublicationDate = function (noticeSnap) {
    return noticeSnap
        .data()
        .publicationDates.map(function (fbTime) { return fbTime.toDate(); })
        .sort(function (a, b) { return (a < b ? -1 : a > b ? 1 : 0); })[0];
};
export var lastNoticePublicationDate = function (noticeSnap) {
    return noticeSnap
        .data()
        .publicationDates.map(function (fbTime) { return fbTime.toDate(); })
        .sort(function (a, b) { return (a > b ? -1 : a < b ? 1 : 0); })[0];
};
export var canPublisherEditNoticeWithoutSupport = function (noticeSnap) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        if (!noticeSnap.data().publicationDates)
            return [2 /*return*/, true];
        return [2 /*return*/, new Date() < firstNoticePublicationDate(noticeSnap)];
    });
}); };
export var canAdvertiserEditNoticeWithoutSupport = function (noticeSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaperSnap, publicationDayEnumValues, customPublicationDate, nearestDeadline;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, noticeSnap
                    .data()
                    .newspaper.get()];
            case 1:
                newspaperSnap = (_a.sent());
                publicationDayEnumValues = publishingDayEnumValuesFromDeadlines(newspaperSnap.data().deadlines);
                customPublicationDate = noticeSnap.data().publicationDates.find(function (date) {
                    // returns true if date isn't a valid publication date
                    return disableNonPublishingDays(date.toDate(), publicationDayEnumValues, newspaperSnap.data().deadlineOverrides);
                });
                // if there is an unusual publication date, the advertiser cannot edit this notice
                if (customPublicationDate)
                    return [2 /*return*/, false];
                nearestDeadline = getDeadlineTimeForPaper(firstNoticePublicationDate(noticeSnap), newspaperSnap.data().deadlines, newspaperSnap.data().iana_timezone, noticeSnap.data(), newspaperSnap);
                return [2 /*return*/, moment(new Date()).isBefore(nearestDeadline)];
        }
    });
}); };
export var canEditNoticeWithoutSupport = function (noticeSnap, userSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                if (((_a = userSnap === null || userSnap === void 0 ? void 0 : userSnap.data()) === null || _a === void 0 ? void 0 : _a.role) === RoleType.super.value)
                    return [2 /*return*/, true];
                if (noticeSnap.data().noticeStatus === NoticeStatusType.cancelled.value)
                    return [2 /*return*/, false];
                if (!(userSnap.data().occupation === OccupationType.publishing.value)) return [3 /*break*/, 2];
                return [4 /*yield*/, canPublisherEditNoticeWithoutSupport(noticeSnap)];
            case 1: return [2 /*return*/, _b.sent()];
            case 2: return [4 /*yield*/, canAdvertiserEditNoticeWithoutSupport(noticeSnap)];
            case 3: return [2 /*return*/, _b.sent()];
        }
    });
}); };
export var canCancelInvoiceWithoutSupport = function (invoice, notice) {
    var noticeTransfer = notice === null || notice === void 0 ? void 0 : notice.data().transfer;
    if (noticeTransfer && typeof noticeTransfer !== 'boolean') {
        return false;
    }
    return ((invoice === null || invoice === void 0 ? void 0 : invoice.data()) &&
        (invoice.data().status === InvoiceStatus.unpaid.value ||
            invoice.data().status === InvoiceStatus.payment_failed.value ||
            invoice.data().invoiceOutsideColumn ||
            !invoice.data().isWithinBulkInvoice));
};
export var assetQuality = {
    high: {
        width: 1000
    }
};
export var cdnIfy = function (storagePath, options) {
    if (options === void 0) { options = {}; }
    var fileType = storagePath && storagePath.split('.').pop();
    var requiresImgix = (options === null || options === void 0 ? void 0 : options.useImgix) ||
        [
            FileType.IDML,
            FileType.HTML,
            FileType.CSV,
            FileType.WORD_DOC,
            FileType.TIF,
            FileType.TIFF,
            FileType.HEIC,
            FileType.HEIF
        ].includes(fileType);
    if (requiresImgix)
        return "https://" + FIREBASE_PROJECT + ".imgix.net/" + storagePath + (options.imgixTransformations
            ? "?" + new URLSearchParams(options.imgixTransformations).toString()
            : '');
    var isImage = [
        FileType.TIF,
        FileType.PNG,
        FileType.JPG,
        FileType.JPEG,
        FileType.PDF
    ].includes(fileType);
    if (!FIREBASE_PROJECT)
        throw new Error('Cannot create CDN link without firebase project');
    if (isImage) {
        return "https://res.cloudinary.com/" + CLOUDINARY_BUCKET + "/image/upload" + ((options === null || options === void 0 ? void 0 : options.cloudinaryTransformations) ? "/" + options.cloudinaryTransformations
            : '') + "/" + FIREBASE_PROJECT + "/" + storagePath + "?invalidate=true";
    }
    return "https://res.cloudinary.com/" + CLOUDINARY_BUCKET + "/raw/upload/" + FIREBASE_PROJECT + "/" + storagePath + "?invalidate=true";
};
export var preventXSS = function (html) {
    return sanitize(html, {
        allowedTags: ['div', 'strong', 'ais-highlight-0000000000']
    });
};
export var getNoticeTypeFromNoticeData = function (notice, newspaper, options) {
    var _a, _b;
    var newspaperSpecificType = (_b = (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.allowedNotices) === null || _b === void 0 ? void 0 : _b.find(function (type) {
        if (notice.noticeType === NoticeType.display_ad.value && (options === null || options === void 0 ? void 0 : options.skipDisplayType) &&
            notice.previousNoticeType)
            return type.value === notice.previousNoticeType;
        return type.value === notice.noticeType;
    });
    if (newspaperSpecificType)
        return newspaperSpecificType;
    var noticeType = NoticeType.by_value(notice.noticeType);
    if (noticeType) {
        return noticeType;
    }
    return null;
};
export var getNoticeType = function (noticeSnap, newspaperSnap, options) {
    if (!(noticeSnap === null || noticeSnap === void 0 ? void 0 : noticeSnap.data()))
        return NoticeType.custom;
    return getNoticeTypeFromNoticeData(noticeSnap.data(), newspaperSnap, options);
};
export var DEFAULT_DPI = 300;
export var inchesToPixels = function (inches, cols, ppi) {
    var PPI = { pixelsPerInch: ppi || DEFAULT_DPI };
    return convert(inches, 'in', 'px', PPI);
};
export var noticeNeedsUpFrontInvoice = function (notice, newspaper) {
    var _a;
    if (!newspaper.data().allowedNotices)
        return false;
    if (notice.data().noticeType === NoticeType.custom.value)
        return false;
    if (notice.data().noticeType === NoticeType.display_ad.value)
        return false;
    if (notice.data().invoice)
        return false;
    if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
        return false;
    var typeform = (_a = newspaper
        .data()
        .allowedNotices.find(function (an) { return an.value === notice.data().noticeType; })) === null || _a === void 0 ? void 0 : _a.typeform;
    if (!typeform)
        return false;
    return true;
};
/**
 * A notice should be auto-invoiced if:
 *  - autoInvoice is set on the org or notice type, AND
 *  - notice is not custom or cancelled
 * @param noticeSnap
 * @returns {Promise<boolean>}
 */
export var shouldAutoInvoice = function (noticeSnap) { return __awaiter(void 0, void 0, Promise, function () {
    var newspaperSnap, parentSnap, rateSnap;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, noticeSnap
                    .data()
                    .newspaper.get()];
            case 1:
                newspaperSnap = (_f.sent());
                return [4 /*yield*/, ((_a = newspaperSnap
                        .data()
                        .parent) === null || _a === void 0 ? void 0 : _a.get())];
            case 2:
                parentSnap = (_f.sent());
                return [4 /*yield*/, ((_b = noticeSnap.data().rate) === null || _b === void 0 ? void 0 : _b.get())];
            case 3:
                rateSnap = _f.sent();
                return [2 /*return*/, ((newspaperSnap.data().autoInvoice || ((_c = parentSnap === null || parentSnap === void 0 ? void 0 : parentSnap.data()) === null || _c === void 0 ? void 0 : _c.autoInvoice) || ((_d = rateSnap === null || rateSnap === void 0 ? void 0 : rateSnap.data()) === null || _d === void 0 ? void 0 : _d.autoInvoice) ||
                        !!((_e = newspaperSnap
                            .data()
                            .allowedNotices) === null || _e === void 0 ? void 0 : _e.find(function (an) {
                            return an.value === noticeSnap.data().noticeType && an.autoInvoice;
                        }))) &&
                        noticeSnap.data().noticeType !== NoticeType.custom.value &&
                        noticeSnap.data().noticeStatus !== NoticeStatusType.cancelled.value &&
                        !noticeSnap.data().invoice)];
        }
    });
}); };
export var toggleInvoiceOutsideColumnForNoticeType = function (noticeSnap) { return __awaiter(void 0, void 0, Promise, function () {
    var newspaperSnap;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, noticeSnap
                    .data()
                    .newspaper.get()];
            case 1:
                newspaperSnap = (_b.sent());
                return [2 /*return*/, (!!((_a = newspaperSnap
                        .data()
                        .allowedNotices) === null || _a === void 0 ? void 0 : _a.find(function (an) {
                        return an.value === noticeSnap.data().noticeType &&
                            an.invoiceOutsideColumn;
                    })) &&
                        !!newspaperSnap.data().allowInvoiceOutsideColumn &&
                        noticeSnap.data().noticeType !== NoticeType.custom.value &&
                        noticeSnap.data().noticeStatus !== NoticeStatusType.cancelled.value)];
        }
    });
}); };
/**
 * A notice should be set to invoice outside column if:
 *  - invoiceOutsideColumn is set on the notice type, AND
 *  - allowInvoiceOutsideColumn is set to true on newspaper level
 *  - invoicedOutsideColumn is set to true on notice
 *  - notice is not custom and cancelled
 * @param noticeSnap
 * @returns {Promise<boolean>}
 */
export var invoicedOutsideColumnForNoticeType = function (noticeSnap) { return __awaiter(void 0, void 0, Promise, function () {
    var newspaperSnap, invoiceTypeForNotice, invoicing;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, noticeSnap
                    .data()
                    .newspaper.get()];
            case 1:
                newspaperSnap = (_d.sent());
                invoiceTypeForNotice = !!((_a = newspaperSnap
                    .data()
                    .allowedNotices) === null || _a === void 0 ? void 0 : _a.find(function (an) {
                    return an.value === noticeSnap.data().noticeType &&
                        an.invoiceOutsideColumn;
                })) &&
                    !!newspaperSnap.data().allowInvoiceOutsideColumn &&
                    noticeSnap.data().noticeType !== NoticeType.custom.value &&
                    noticeSnap.data().noticeStatus !== NoticeStatusType.cancelled.value;
                if ((_b = newspaperSnap
                    .data()
                    .allowedNotices) === null || _b === void 0 ? void 0 : _b.find(function (an) {
                    return an.value === noticeSnap.data().noticeType && an.autoInvoice;
                })) {
                    invoicing = invoiceTypeForNotice;
                }
                else {
                    invoicing =
                        invoiceTypeForNotice && !!((_c = noticeSnap.data()) === null || _c === void 0 ? void 0 : _c.invoicedOutsideColumn);
                }
                return [2 /*return*/, invoicing];
        }
    });
}); };
export var shouldReleaseAffidavit = function (noticeSnap, invoiceSnap, advertiserSnap, advertiserOrgSnap, newspaperSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var customer, alwaysAllowAffidavitDownload, isInvoicePaid;
    var _a, _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                if (!noticeSnap.data().affidavit)
                    return [2 /*return*/, false];
                return [4 /*yield*/, getOrCreateCustomer(advertiserSnap, newspaperSnap)];
            case 1:
                customer = _f.sent();
                alwaysAllowAffidavitDownload = ((_a = advertiserSnap.data()) === null || _a === void 0 ? void 0 : _a.alwaysAllowAffidavitDownload) || ((_b = advertiserOrgSnap === null || advertiserOrgSnap === void 0 ? void 0 : advertiserOrgSnap.data()) === null || _b === void 0 ? void 0 : _b.alwaysAllowAffidavitDownload) || ((_c = newspaperSnap.data()) === null || _c === void 0 ? void 0 : _c.alwaysAllowAffidavitDownload) ||
                    customer.data().enableAffidavitsBeforePayment;
                isInvoicePaid = ((_d = invoiceSnap === null || invoiceSnap === void 0 ? void 0 : invoiceSnap.data()) === null || _d === void 0 ? void 0 : _d.status) === InvoiceStatus.paid.value || ((_e = invoiceSnap === null || invoiceSnap === void 0 ? void 0 : invoiceSnap.data()) === null || _e === void 0 ? void 0 : _e.invoiceOutsideColumn);
                if (isInvoicePaid)
                    return [2 /*return*/, true];
                if (alwaysAllowAffidavitDownload)
                    return [2 /*return*/, true];
                return [2 /*return*/, false];
        }
    });
}); };
export var shouldReleaseAffidavitForNotice = function (noticeSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var invoiceSnap, advertiserSnap, advertiserOrgSnap, newspaperSnap;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, ((_a = noticeSnap.data().invoice) === null || _a === void 0 ? void 0 : _a.get())];
            case 1:
                invoiceSnap = _d.sent();
                return [4 /*yield*/, ((_b = noticeSnap
                        .data()
                        .filer) === null || _b === void 0 ? void 0 : _b.get())];
            case 2:
                advertiserSnap = (_d.sent());
                return [4 /*yield*/, ((_c = advertiserSnap.data().organization) === null || _c === void 0 ? void 0 : _c.get())];
            case 3:
                advertiserOrgSnap = _d.sent();
                return [4 /*yield*/, noticeSnap
                        .data()
                        .newspaper.get()];
            case 4:
                newspaperSnap = (_d.sent());
                return [2 /*return*/, shouldReleaseAffidavit(noticeSnap, invoiceSnap, advertiserSnap, advertiserOrgSnap, newspaperSnap)];
        }
    });
}); };
export var getCustomAffidavit = function (noticeSnap, userSnap, newspaperSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var noticeType, rate;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                noticeType = getNoticeType(noticeSnap, newspaperSnap);
                return [4 /*yield*/, ((_a = noticeSnap.data().rate) === null || _a === void 0 ? void 0 : _a.get())];
            case 1:
                rate = _e.sent();
                return [2 /*return*/, (noticeSnap.data().customAffidavit || ((_b = userSnap.data()) === null || _b === void 0 ? void 0 : _b.customAffidavit) || ((_c = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _c === void 0 ? void 0 : _c.customAffidavit) || (noticeType === null || noticeType === void 0 ? void 0 : noticeType.customAffidavit) || ((_d = newspaperSnap.data()) === null || _d === void 0 ? void 0 : _d.customAffidavit))];
        }
    });
}); };
/**
 * This function Ensures that dates passed from frontend to backend are same
 * @param date Date string from new Date().toDateString();
 * @returns newDate;
 */
export var ensureSameDate = function (date) {
    if (!moment(date, 'ddd MMM DD YYYY', true).isValid())
        throw new Error('Invalid Date Format');
    return moment(date).toDate();
};
export var getCurrencySymbol = function (currency) {
    if (!currency) {
        return CurrencyType.usd.symbol;
    }
    return CurrencyType[currency.toLowerCase()].symbol;
};
export var getLocalizedDescription = function (notification, country) {
    if (notification.localized_description &&
        notification.localized_description[Country.by_value(country).key]) {
        return notification.localized_description[Country.by_value(country).key];
    }
    return notification.description;
};
export var showConvertToLinerWarning = function (displayOnlyAds, isDisplay, showDisplayError, postWithoutFormatting) {
    return (!displayOnlyAds && isDisplay && showDisplayError && !postWithoutFormatting);
};
export var isPayingInvoiceThroughSupport = function (change) {
    var _a;
    if (!change.before || !change.after)
        return false;
    if ((_a = change.before.data()) === null || _a === void 0 ? void 0 : _a.payBySupport)
        return false;
    if (!change.after.data().payBySupport)
        return false;
    return true;
};
/*
 * This function is used to determine whether or not a
 * notice requires upfront payment at any time. If
 * called before invoice creation, we pull from the
 * settings of associated objects. During invoice
 * creation, the user can use the override toggle &
 * then that value is set on the notice object.
 */
export var getNoticeRequiresUpfrontPayment = function (noticeSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaperSnap, advertiserSnap, customer, customerOrganization, noticeType;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, noticeSnap
                    .data()
                    .newspaper.get()];
            case 1:
                newspaperSnap = (_a.sent());
                return [4 /*yield*/, noticeSnap
                        .data()
                        .filer.get()];
            case 2:
                advertiserSnap = (_a.sent());
                // Logic hierarchy for requireUpfrontPayment:
                // 0. Notice
                if (noticeSnap.data().requireUpfrontPayment != null) {
                    return [2 /*return*/, noticeSnap.data().requireUpfrontPayment];
                }
                return [4 /*yield*/, getOrCreateCustomer(advertiserSnap, newspaperSnap)];
            case 3:
                customer = _a.sent();
                if (customer.data().requireUpfrontPayment != null) {
                    return [2 /*return*/, customer.data().requireUpfrontPayment];
                }
                return [4 /*yield*/, getOrCreateCustomerOrganizationForNotice(noticeSnap)];
            case 4:
                customerOrganization = _a.sent();
                if ((customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data().requireUpfrontPayment) != null) {
                    return [2 /*return*/, customerOrganization.data().requireUpfrontPayment];
                }
                noticeType = getNoticeType(noticeSnap, newspaperSnap);
                if (noticeType && noticeType.upFrontPayment != null) {
                    return [2 /*return*/, noticeType.upFrontPayment];
                }
                // 4. Newspaper (does the newspaper default to requiring upfront payment?)
                if (newspaperSnap.data().requireUpfrontPayment != null) {
                    return [2 /*return*/, newspaperSnap.data().requireUpfrontPayment];
                }
                return [2 /*return*/, false];
        }
    });
}); };
export var getDueDate = function (noticeSnap, requireUpfrontPayment) { return __awaiter(void 0, void 0, void 0, function () {
    var advertiserSnap, newspaperSnap, _a, deadlines, iana_timezone, endOfNextMonth, closestPublicationDate, deadline;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, noticeSnap
                    .data()
                    .filer.get()];
            case 1:
                advertiserSnap = (_b.sent());
                return [4 /*yield*/, noticeSnap
                        .data()
                        .newspaper.get()];
            case 2:
                newspaperSnap = (_b.sent());
                _a = newspaperSnap.data(), deadlines = _a.deadlines, iana_timezone = _a.iana_timezone;
                if (newspaperSnap &&
                    newspaperSnap.data().bulkPaymentEnabled &&
                    advertiserSnap &&
                    advertiserSnap.data().bulkPaymentEnabled) {
                    endOfNextMonth = moment()
                        .tz(iana_timezone)
                        .add(2, 'M')
                        .startOf('month')
                        .toDate()
                        .getTime() - 2000;
                    return [2 /*return*/, endOfNextMonth / 1000];
                }
                if (requireUpfrontPayment) {
                    closestPublicationDate = firstNoticePublicationDate(noticeSnap);
                    deadline = getDeadlineTimeForPaper(closestPublicationDate, deadlines, iana_timezone, noticeSnap.data(), newspaperSnap);
                    return [2 /*return*/, deadline.unix() - 2];
                }
                return [2 /*return*/, (moment()
                        .tz(iana_timezone)
                        .add(1, 'M')
                        .toDate()
                        .getTime() /
                        1000 -
                        2)];
        }
    });
}); };
export var standardizePhoneNumber = function (phoneNumberString) { return __awaiter(void 0, void 0, void 0, function () {
    var cleaned, match;
    return __generator(this, function (_a) {
        cleaned = ("" + phoneNumberString).replace(/\D/g, '');
        match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return [2 /*return*/, "(" + match[1] + ") " + match[2] + "-" + match[3]];
        }
        return [2 /*return*/, phoneNumberString];
    });
}); };
export var validatePhoneNumbers = function (phoneNumberString) { return __awaiter(void 0, void 0, void 0, function () {
    var phoneRegex;
    return __generator(this, function (_a) {
        phoneRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;
        return [2 /*return*/, !!phoneNumberString.match(phoneRegex)];
    });
}); };
export var getPhoneNumberFromNotice = function (notice) { return __awaiter(void 0, void 0, void 0, function () {
    var userSnap, activeOrganizationSnap, savedPhone, standardizedPhone;
    var _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0: return [4 /*yield*/, notice.data().filer.get()];
            case 1:
                userSnap = (_e.sent());
                return [4 /*yield*/, ((_a = userSnap
                        .data()
                        .activeOrganization) === null || _a === void 0 ? void 0 : _a.get())];
            case 2:
                activeOrganizationSnap = (_e.sent());
                savedPhone = userSnap.data().occupation === OccupationType.publishing.value
                    ? (_b = activeOrganizationSnap === null || activeOrganizationSnap === void 0 ? void 0 : activeOrganizationSnap.data()) === null || _b === void 0 ? void 0 : _b.phone : ((_c = userSnap.data()) === null || _c === void 0 ? void 0 : _c.phone) || ((_d = activeOrganizationSnap === null || activeOrganizationSnap === void 0 ? void 0 : activeOrganizationSnap.data()) === null || _d === void 0 ? void 0 : _d.phone);
                return [4 /*yield*/, standardizePhoneNumber(savedPhone)];
            case 3:
                standardizedPhone = _e.sent();
                if (!(savedPhone !== standardizedPhone)) return [3 /*break*/, 7];
                if (!(userSnap.data().occupation === OccupationType.publishing.value)) return [3 /*break*/, 5];
                return [4 /*yield*/, activeOrganizationSnap.ref.update({
                        phone: standardizedPhone
                    })];
            case 4:
                _e.sent();
                return [3 /*break*/, 7];
            case 5: return [4 /*yield*/, userSnap.ref.update({
                    phone: standardizedPhone
                })];
            case 6:
                _e.sent();
                _e.label = 7;
            case 7: return [2 /*return*/, standardizedPhone];
        }
    });
}); };
export var getXMLExportSettings = function (newspaperSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaperXMLExport, parent;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                newspaperXMLExport = newspaperSnap.data().xmlExport;
                if (newspaperXMLExport)
                    return [2 /*return*/, newspaperXMLExport];
                return [4 /*yield*/, ((_a = newspaperSnap.data().parent) === null || _a === void 0 ? void 0 : _a.get())];
            case 1:
                parent = _d.sent();
                if ((_b = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _b === void 0 ? void 0 : _b.xmlExport)
                    return [2 /*return*/, (_c = parent.data()) === null || _c === void 0 ? void 0 : _c.xmlExport];
                throw new Error("XML export information not specified for paper " + newspaperSnap.data().name + " with ID: " + newspaperSnap.id);
        }
    });
}); };
export var getExportSettings = function (newspaperSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaperFTP, parent;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                newspaperFTP = (_a = newspaperSnap.data().bulkDownload) === null || _a === void 0 ? void 0 : _a.ftp;
                if (newspaperFTP)
                    return [2 /*return*/, newspaperFTP];
                return [4 /*yield*/, ((_b = newspaperSnap.data().parent) === null || _b === void 0 ? void 0 : _b.get())];
            case 1:
                parent = _g.sent();
                if ((_d = (_c = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _c === void 0 ? void 0 : _c.bulkDownload) === null || _d === void 0 ? void 0 : _d.ftp)
                    return [2 /*return*/, (_f = (_e = parent.data()) === null || _e === void 0 ? void 0 : _e.bulkDownload) === null || _f === void 0 ? void 0 : _f.ftp];
                throw new Error("FTP export information not specified for paper " + newspaperSnap.data().name + " with ID: " + newspaperSnap.id);
        }
    });
}); };
export var getShouldShowUpFrontBilling = function (notice, newspaper, invoice, isInvoicedOutsideColumn) {
    var _a;
    // do not show if invoice hasn't been created or if notice is invoiced outside Column
    if (!invoice || isInvoicedOutsideColumn === undefined)
        return false;
    if (isInvoicedOutsideColumn)
        return false;
    // don't run if notice has been cancelled
    if (notice.data().noticeStatus === NoticeStatusType.cancelled.value)
        return false;
    // don't run if the invoice has already been paid
    if (((_a = invoice === null || invoice === void 0 ? void 0 : invoice.data()) === null || _a === void 0 ? void 0 : _a.status) === InvoiceStatus.paid.value)
        return false;
    // if toggled off on the notice level
    if (notice.data().requireUpfrontPayment !== undefined)
        return notice.data().requireUpfrontPayment;
    var noticeType = getNoticeType(notice, newspaper);
    // short circuit if we are specifically setting the property
    if ((noticeType === null || noticeType === void 0 ? void 0 : noticeType.upFrontPayment) !== undefined)
        return noticeType.upFrontPayment;
    if (newspaper.data().requireUpfrontPayment)
        return newspaper.data().requireUpfrontPayment;
    return false;
};
// count bold words from confirmedHtml
export var getBoldWords = function (html, DOMparser) {
    var space = /\s/;
    var doc = new DOMparser().parseFromString(html, 'text/html');
    var totalBoldWords = 0;
    var totalBoldElements = [];
    var elementsWithStrongTag = doc.getElementsByTagName('strong');
    var elementsWithBTag = doc.getElementsByTagName('b');
    for (var i = 0; i < elementsWithStrongTag.length; i++) {
        totalBoldElements.push(elementsWithStrongTag === null || elementsWithStrongTag === void 0 ? void 0 : elementsWithStrongTag[i].innerHTML);
    }
    for (var i = 0; i < elementsWithBTag.length; i++) {
        totalBoldElements.push(elementsWithBTag === null || elementsWithBTag === void 0 ? void 0 : elementsWithBTag[i].innerHTML);
    }
    if (!totalBoldElements.length)
        return 0;
    for (var i = 0; i < totalBoldElements.length; i++) {
        if (space.test(totalBoldElements[i])) {
            var splitText = totalBoldElements[i]
                .split(' ')
                .filter(function (elem) { return elem !== ''; });
            totalBoldWords += splitText.length;
        }
        else
            totalBoldWords += 1;
    }
    return totalBoldWords;
};
export var isPastDueInNewspaperTimezone = function (dueDate, timezone, now) {
    var SECONDS_IN_MINUTE = 60;
    var SECONDS_IN_DAY = 86400;
    var MILLISECONDS_IN_SECOND = 1000;
    var dueDateNewspaperTimeZone = dueDate.tz(timezone);
    var offset = dueDateNewspaperTimeZone.utcOffset() * SECONDS_IN_MINUTE;
    return now.isAfter(moment((Math.ceil(dueDateNewspaperTimeZone.unix() / SECONDS_IN_DAY) *
        SECONDS_IN_DAY -
        offset) *
        MILLISECONDS_IN_SECOND));
};
// check if upload file type is accepted
export var getFileExtension = function (fileName) {
    var _a, _b;
    return (_b = (_a = fileName === null || fileName === void 0 ? void 0 : fileName.split('.')) === null || _a === void 0 ? void 0 : _a.pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase();
};
export var isValidExtension = function (currentFileName, validExtensions) {
    var extension = getFileExtension(currentFileName);
    if (validExtensions.includes(extension)) {
        return true;
    }
    return false;
};
export var getNoticeNumberAndCustomIdFromNewspaper = function (newspaper) {
    var _a, _b, _c, _d, _e, _f, _g;
    var currentNumber = ((_a = newspaper.data()) === null || _a === void 0 ? void 0 : _a.numberOfOrders) || 1;
    var numberOfDigits = ((_c = (_b = newspaper.data()) === null || _b === void 0 ? void 0 : _b.orderNumberGeneration) === null || _c === void 0 ? void 0 : _c.orderNumDigitCount) || 4;
    var numOfZeros = numberOfDigits - ("" + currentNumber).length > 0
        ? numberOfDigits - ("" + currentNumber).length
        : 0;
    var prefix = ((_e = (_d = newspaper.data()) === null || _d === void 0 ? void 0 : _d.orderNumberGeneration) === null || _e === void 0 ? void 0 : _e.orderNumPrefix) || '';
    var suffix = ((_g = (_f = newspaper.data()) === null || _f === void 0 ? void 0 : _f.orderNumberGeneration) === null || _g === void 0 ? void 0 : _g.orderNumSuffix) || '';
    var customId = "" + prefix + '0'.repeat(numOfZeros) + currentNumber + suffix;
    return {
        currentNumber: currentNumber,
        customId: customId
    };
};
export var sendToRenderingEngine = function (_a) {
    var url = _a.url, route = _a.route, backendUrl = _a.backendUrl, request = _a.request, notice = _a.notice, options = _a.options;
    return __awaiter(void 0, void 0, void 0, function () {
        var _b, res, err_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _b = notice &&
                        (options === null || options === void 0 ? void 0 : options.responseType) !== 'arraybuffer' &&
                        isRouteSupportedBySD(route);
                    if (!_b) return [3 /*break*/, 2];
                    return [4 /*yield*/, getShouldUseSD(notice)];
                case 1:
                    _b = (_c.sent());
                    _c.label = 2;
                case 2:
                    if (!_b) return [3 /*break*/, 6];
                    _c.label = 3;
                case 3:
                    _c.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, callRenderNotice(backendUrl, request)];
                case 4:
                    res = _c.sent();
                    if (res.data.success) {
                        return [2 /*return*/, res.data];
                    }
                    console.error(res.data.error);
                    return [3 /*break*/, 6];
                case 5:
                    err_1 = _c.sent();
                    Sentry.captureException(err_1);
                    console.error("Error calling sp/render-notice. Defaulting to old indesign server: " + String(err_1));
                    return [3 /*break*/, 6];
                case 6: return [4 /*yield*/, callOldIndesign(url, request, options)];
                case 7: return [2 /*return*/, _c.sent()];
            }
        });
    });
};
export var requestDocument = function (notice, options, DOMparser, backendUrl) { return __awaiter(void 0, void 0, void 0, function () {
    var format, type, url, optimizeColumns, adTemplate, newspaper, generatedFooter, _a, confirmedHtml, columns, dynamicHeaders, publicationDates, _b, isFirstPHeading, downloadUrl, icmlSubstitutions, linerMaxColumns, preserveLongSequences, dynamicFooter, xmlFooter, request, renderRequestOptions, data, boldWords;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                if (!notice.exists) {
                    throw new Error("Notice not found");
                }
                format = options.format, type = options.type, url = options.url, optimizeColumns = options.optimizeColumns;
                return [4 /*yield*/, notice.data().adTemplate.get()];
            case 1:
                adTemplate = (_c.sent());
                return [4 /*yield*/, notice.data().newspaper.get()];
            case 2:
                newspaper = (_c.sent());
                return [4 /*yield*/, generateFormattedFooter(notice)];
            case 3:
                generatedFooter = _c.sent();
                _a = notice.data(), confirmedHtml = _a.confirmedHtml, columns = _a.columns, dynamicHeaders = _a.dynamicHeaders, publicationDates = _a.publicationDates;
                _b = adTemplate.data(), isFirstPHeading = _b.isFirstPHeading, downloadUrl = _b.downloadUrl, icmlSubstitutions = _b.icmlSubstitutions;
                linerMaxColumns = newspaper.data().linerMaxColumns;
                preserveLongSequences = shouldPreserveLongSequencesForNotice(notice);
                dynamicFooter = notice.data().dynamicFooter || generatedFooter;
                xmlFooter = dynamicFooter && dynamicFooter.length > 0
                    ? addFooterXML(dynamicFooter)
                    : '';
                if (!!notice.data().dynamicFooter) return [3 /*break*/, 5];
                return [4 /*yield*/, notice.ref.update({ dynamicFooter: xmlFooter })];
            case 4:
                _c.sent();
                _c.label = 5;
            case 5:
                request = {
                    id: adTemplate.id,
                    noticeId: notice.id,
                    downloadUrl: downloadUrl,
                    html: htmlToIndesignHtml(confirmedHtml, DOMparser, {
                        isFirstPHeading: isFirstPHeading,
                        preserveLongSequences: preserveLongSequences
                    }, {
                        dates: publicationDates
                    }) + xmlFooter,
                    format: format,
                    quality: 'high',
                    columns: columns ? columns + (optimizeColumns ? 1 : 0) : 1,
                    dynamicHeader: Array.isArray(dynamicHeaders) ? dynamicHeaders[0] : null,
                    linerBorder: adTemplate.data().linerBorder || newspaper.data().linerBorder,
                    icmlSubstitutions: icmlSubstitutions,
                    linerMaxColumns: linerMaxColumns,
                    optimizeColumns: !!optimizeColumns,
                    resizeTextFramesForProofPDF: type === 'DISPLAY_PARAMETERS',
                    outputToBase64: type === 'DISPLAY_PARAMETERS'
                };
                renderRequestOptions = (type === 'RAW'
                    ? {
                        responseType: 'arraybuffer'
                    }
                    : {});
                return [4 /*yield*/, sendToRenderingEngine({
                        url: url,
                        route: IndesignRoute.liner_ad_image,
                        backendUrl: backendUrl,
                        request: request,
                        notice: notice,
                        options: renderRequestOptions
                    })];
            case 6:
                data = (_c.sent()).data;
                if (type === 'RAW') {
                    return [2 /*return*/, { data: data }];
                }
                boldWords = getBoldWords(notice.data().confirmedHtml, DOMparser);
                return [2 /*return*/, __assign(__assign({}, data), { boldWords: boldWords })];
        }
    });
}); };
export var getDaysSinceFirstWeekdayOfQuarter = function (now) {
    var startOfQuarter = moment(now.format('YYYY-MM-DD')).startOf('quarter');
    // handle Saturday
    if (startOfQuarter.day() === 6) {
        startOfQuarter = startOfQuarter.add(2, 'days');
    }
    // handle Sunday
    if (startOfQuarter.day() === 0) {
        startOfQuarter = startOfQuarter.add(1, 'days');
    }
    return now.startOf('day').diff(startOfQuarter.startOf('day'), 'days');
};
export var getAdTemplate = function (newspaper, filer, customTypeTemplate) { return __awaiter(void 0, void 0, void 0, function () {
    var filerOrganization, customerOrganization;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0: return [4 /*yield*/, ((_b = (_a = filer === null || filer === void 0 ? void 0 : filer.data()) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.get())];
            case 1:
                filerOrganization = _g.sent();
                if (!filerOrganization) return [3 /*break*/, 3];
                return [4 /*yield*/, getOrCreateCustomerOrganization(filerOrganization, newspaper)];
            case 2:
                customerOrganization = _g.sent();
                if (customerOrganization && ((_c = customerOrganization === null || customerOrganization === void 0 ? void 0 : customerOrganization.data()) === null || _c === void 0 ? void 0 : _c.adTemplate)) {
                    return [2 /*return*/, customerOrganization.data().adTemplate];
                }
                _g.label = 3;
            case 3:
                if (customTypeTemplate)
                    return [2 /*return*/, customTypeTemplate];
                if ((_d = filer === null || filer === void 0 ? void 0 : filer.data()) === null || _d === void 0 ? void 0 : _d.defaultTemplate)
                    return [2 /*return*/, (_e = filer === null || filer === void 0 ? void 0 : filer.data()) === null || _e === void 0 ? void 0 : _e.defaultTemplate];
                return [2 /*return*/, (_f = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _f === void 0 ? void 0 : _f.adTemplate];
        }
    });
}); };
export var getDisplayUnits = function (rate, newspaper, displayParameters) {
    var _a, _b;
    return ((_a = rate === null || rate === void 0 ? void 0 : rate.data()) === null || _a === void 0 ? void 0 : _a.rateType) === RateType.inch.value
        ? {
            unit: RateType.inch.plural,
            value: displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.area
        }
        : ((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.country) === Country.GBR.value
            ? {
                unit: RateType.single_column_centimetre.plural,
                value: getColumnCentimeters(displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.height, displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.columns)
            }
            : {
                unit: RateType.column_inch.plural,
                value: getColumnInches(displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.height, displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.columns)
            };
};
