var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { all, take, takeEvery, call, put, select, fork, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/browser';
import { eventChannel } from 'redux-saga';
import Firebase from 'EnoticeFirebase';
import { Collections } from 'lib/constants';
import { authSelector, AuthTypes } from 'redux/auth';
import OccupationType from 'lib/enums/OccupationType';
import OrganizationType from 'lib/enums/OrganizationType';
import { push } from 'connected-react-router';
import { NoticeType, State, NoticeStatusType } from 'lib/enums';
import firebase from 'firebase';
import { appendToCurrentParams, updateHistoryNoRerender, getLocationParams } from 'utils/urls';
import { generateFormattedFooter } from 'lib/headers_footers/footers';
import { checkForDefault, rateCanPublishWithPlacementData } from 'routes/placeScroll/rates';
import api from 'api';
import { getAdTemplate } from 'lib/helpers';
import { PlacementError } from '../lib/errors/PlacementError';
import PlacementActions, { PlacementTypes, placementSelector } from '../redux/placement';
var getValidPapers = function (papers, user) {
    var filteredPapers = papers;
    var restrictedPapers = getLocationParams().get('restrictedPapers')
        ? getLocationParams()
            .get('restrictedPapers')
            .split(',')
        : null;
    if (restrictedPapers) {
        filteredPapers = filteredPapers.filter(function (p) {
            return restrictedPapers.includes(p.id);
        });
    }
    if (user && user.data().enabledNewspapers) {
        var enabledIds_1 = user.data().enabledNewspapers.map(function (n) { return n.id; });
        return filteredPapers.filter(function (p) { return enabledIds_1.indexOf(p.id) !== -1; });
    }
    return filteredPapers.sort(function (a, b) {
        if (a.name.toUpperCase() > b.name.toUpperCase())
            return 1;
        if (a.name.toUpperCase() < b.name.toUpperCase())
            return -1;
        return 0;
    });
};
function createNotice() {
    var auth, e_1, newspaperRef, adTemplateRef, rateRef, newspaper, placement, filedBy, createdBy, initialNotice, createRef, ref, draftNotice, draftRef, draftSnap;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 4, , 6]);
                return [4 /*yield*/, select(authSelector)];
            case 1:
                auth = _d.sent();
                if (!(auth.user &&
                    auth.user.data().occupation !== OccupationType.publishing.value)) return [3 /*break*/, 3];
                return [4 /*yield*/, put(PlacementActions.setFiler(auth.user.ref))];
            case 2:
                _d.sent();
                _d.label = 3;
            case 3: return [3 /*break*/, 6];
            case 4:
                e_1 = _d.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 5:
                _d.sent();
                Sentry.captureException(e_1);
                console.error('Placement: Error in createNotice:', e_1);
                return [3 /*break*/, 6];
            case 6:
                newspaperRef = (auth.user &&
                    auth.user.data().occupation === OccupationType.publishing.value &&
                    auth.user.data().activeOrganization) ||
                    (auth.orgContext &&
                        auth.orgContext.data().organizationType ===
                            OrganizationType.newspaper.value &&
                        auth.orgContext.ref) ||
                    null;
                adTemplateRef = null;
                rateRef = null;
                return [4 /*yield*/, put(PlacementActions.setNewspaper(newspaperRef))];
            case 7:
                _d.sent();
                if (!newspaperRef) return [3 /*break*/, 13];
                return [4 /*yield*/, call([newspaperRef, newspaperRef.get])];
            case 8:
                newspaper = _d.sent();
                adTemplateRef = newspaper.data().adTemplate;
                if (!!adTemplateRef) return [3 /*break*/, 10];
                return [4 /*yield*/, put(push('/'))];
            case 9:
                _d.sent();
                return [2 /*return*/];
            case 10: return [4 /*yield*/, put(PlacementActions.setTemplate(adTemplateRef))];
            case 11:
                _d.sent();
                rateRef = newspaper.data().defaultLinerRate;
                return [4 /*yield*/, put(PlacementActions.setRate(rateRef))];
            case 12:
                _d.sent();
                _d.label = 13;
            case 13:
                if (!(auth.user && auth.user.data().defaultTemplate)) return [3 /*break*/, 15];
                adTemplateRef = auth.user.data().defaultTemplate;
                return [4 /*yield*/, put(PlacementActions.setTemplate(adTemplateRef))];
            case 14:
                _d.sent();
                _d.label = 15;
            case 15: return [4 /*yield*/, select(placementSelector)];
            case 16:
                placement = _d.sent();
                filedBy = auth.isPublisher
                    ? null
                    : ((_b = (_a = auth.user) === null || _a === void 0 ? void 0 : _a.data()) === null || _b === void 0 ? void 0 : _b.activeOrganization) || null;
                createdBy = auth.isPublisher ? (_c = auth.user) === null || _c === void 0 ? void 0 : _c.ref : null;
                initialNotice = {
                    noticeType: NoticeType.custom.value,
                    newspaper: placement.newspaper,
                    adTemplate: placement.adTemplate,
                    rate: placement.rate,
                    filer: placement.filer,
                    userId: auth.user && auth.user.id,
                    isArchived: false,
                    createTime: firebase.firestore.FieldValue.serverTimestamp(),
                    filedBy: filedBy,
                    createdBy: createdBy
                };
                createRef = Firebase.firestore().collection(Collections.userNotices);
                return [4 /*yield*/, call([createRef, createRef.add], initialNotice)];
            case 17:
                ref = _d.sent();
                draftNotice = {
                    noticeType: NoticeType.custom.value,
                    newspaper: placement.newspaper,
                    adTemplate: placement.adTemplate,
                    rate: placement.rate,
                    filer: placement.filer,
                    userId: auth.user && auth.user.id,
                    filedBy: filedBy,
                    isArchived: false,
                    createTime: firebase.firestore.FieldValue.serverTimestamp(),
                    original: ref,
                    owner: auth.user && auth.user.ref,
                    createdBy: createdBy
                };
                draftRef = Firebase.firestore()
                    .collection(Collections.userDrafts)
                    .doc();
                return [4 /*yield*/, call([draftRef, draftRef.set], draftNotice)];
            case 18:
                _d.sent();
                return [4 /*yield*/, call([ref, ref.update], { drafts: [draftRef] })];
            case 19:
                _d.sent();
                return [4 /*yield*/, put(PlacementActions.setOriginal(ref))];
            case 20:
                _d.sent();
                return [4 /*yield*/, put(PlacementActions.setDraft(draftRef))];
            case 21:
                _d.sent();
                return [4 /*yield*/, put(PlacementActions.setFiledBy(filedBy))];
            case 22:
                _d.sent();
                return [4 /*yield*/, put(PlacementActions.setCreatedBy(createdBy))];
            case 23:
                _d.sent();
                return [4 /*yield*/, call([draftRef, draftRef.get])];
            case 24:
                draftSnap = _d.sent();
                return [4 /*yield*/, put(PlacementActions.setDraftSnap(draftSnap))];
            case 25:
                _d.sent();
                return [2 /*return*/, ref];
        }
    });
}
function getAvailableStatesAndPapers() {
    var data, user, newspaperList, states_1, filteredStates, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 5, , 7]);
                return [4 /*yield*/, call(function () { return api.get('organizations/newspapers'); })];
            case 1:
                data = _a.sent();
                return [4 /*yield*/, select(authSelector)];
            case 2:
                user = (_a.sent()).user;
                newspaperList = getValidPapers(data.papers, user);
                states_1 = {};
                return [4 /*yield*/, put(PlacementActions.setNewspaperList(newspaperList))];
            case 3:
                _a.sent();
                newspaperList.forEach(function (d) {
                    var npStateValue = d.state;
                    if (State.by_value(npStateValue))
                        states_1[npStateValue] = true;
                });
                filteredStates = __spread(Object.keys(states_1).map(function (key) { return parseInt(key, 10); }));
                return [4 /*yield*/, put(PlacementActions.setAvailableStates(filteredStates))];
            case 4:
                _a.sent();
                return [3 /*break*/, 7];
            case 5:
                e_2 = _a.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 6:
                _a.sent();
                Sentry.captureException(e_2);
                console.error('Placement: Error in getAvailableStatesAndPapers:', e_2);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function watchNotice(noticeRef) {
    var noticeChannel;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!noticeRef)
                    return [2 /*return*/];
                noticeChannel = eventChannel(function (emitter) { return noticeRef.onSnapshot(emitter); });
                // ignore the first update
                return [4 /*yield*/, take(noticeChannel)];
            case 1:
                // ignore the first update
                _a.sent();
                return [4 /*yield*/, takeLatest(noticeChannel, function f(noticeSnap) {
                        var _a, text, confirmedHtml, unusedConfirmedHtml, proofStoragePath;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    if (!!noticeSnap.data()) return [3 /*break*/, 2];
                                    return [4 /*yield*/, put(PlacementActions.setDraftSnap(noticeSnap))];
                                case 1:
                                    _b.sent();
                                    return [2 /*return*/];
                                case 2: return [4 /*yield*/, put(PlacementActions.setDraftSnap(noticeSnap))];
                                case 3:
                                    _b.sent();
                                    _a = noticeSnap.data(), text = _a.text, confirmedHtml = _a.confirmedHtml, unusedConfirmedHtml = _a.unusedConfirmedHtml, proofStoragePath = _a.proofStoragePath;
                                    if (!proofStoragePath) return [3 /*break*/, 5];
                                    return [4 /*yield*/, put(PlacementActions.setProofStoragePath(proofStoragePath))];
                                case 4:
                                    _b.sent();
                                    _b.label = 5;
                                case 5:
                                    if (!unusedConfirmedHtml) return [3 /*break*/, 7];
                                    return [4 /*yield*/, put(PlacementActions.setUnusedConfirmedHtml(unusedConfirmedHtml))];
                                case 6:
                                    _b.sent();
                                    _b.label = 7;
                                case 7:
                                    if (!(text || confirmedHtml)) return [3 /*break*/, 9];
                                    return [4 /*yield*/, put(PlacementActions.setNoticeText(confirmedHtml || text))];
                                case 8:
                                    _b.sent();
                                    _b.label = 9;
                                case 9: return [2 /*return*/];
                            }
                        });
                    })];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function publishNotice() {
    var placement, auth, draft, original, editing, originalNotice, submissionTime, draftData, draftMailRef, draftMail, originalMailRef, originalMail, _a, _b, doc, mRef, e_3_1, _c, _d, doc, mRef, e_4_1, draftUpdates, noticeObject, e_5;
    var e_3, _e, e_4, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                _g.trys.push([0, 27, 28, 30]);
                return [4 /*yield*/, select(placementSelector)];
            case 1:
                placement = _g.sent();
                return [4 /*yield*/, select(authSelector)];
            case 2:
                auth = _g.sent();
                draft = placement.draft, original = placement.original, editing = placement.editing;
                return [4 /*yield*/, call([original, original.get])];
            case 3:
                originalNotice = _g.sent();
                submissionTime = firebase.firestore.Timestamp.now();
                return [4 /*yield*/, call([draft, draft.get])];
            case 4:
                draftData = _g.sent();
                if (draftData.data().inactive)
                    return [2 /*return*/];
                draftMailRef = draft.collection(Collections.mail);
                return [4 /*yield*/, call([draftMailRef, draftMailRef.get])];
            case 5:
                draftMail = _g.sent();
                originalMailRef = original.collection(Collections.mail);
                return [4 /*yield*/, call([originalMailRef, originalMailRef.get])];
            case 6:
                originalMail = _g.sent();
                if (!originalMail.docs) return [3 /*break*/, 14];
                _g.label = 7;
            case 7:
                _g.trys.push([7, 12, 13, 14]);
                _a = __values(originalMail.docs), _b = _a.next();
                _g.label = 8;
            case 8:
                if (!!_b.done) return [3 /*break*/, 11];
                doc = _b.value;
                mRef = doc.ref;
                return [4 /*yield*/, call([mRef, mRef.delete])];
            case 9:
                _g.sent();
                _g.label = 10;
            case 10:
                _b = _a.next();
                return [3 /*break*/, 8];
            case 11: return [3 /*break*/, 14];
            case 12:
                e_3_1 = _g.sent();
                e_3 = { error: e_3_1 };
                return [3 /*break*/, 14];
            case 13:
                try {
                    if (_b && !_b.done && (_e = _a.return)) _e.call(_a);
                }
                finally { if (e_3) throw e_3.error; }
                return [7 /*endfinally*/];
            case 14:
                if (!draftMail.docs) return [3 /*break*/, 23];
                _g.label = 15;
            case 15:
                _g.trys.push([15, 21, 22, 23]);
                _c = __values(draftMail.docs), _d = _c.next();
                _g.label = 16;
            case 16:
                if (!!_d.done) return [3 /*break*/, 20];
                doc = _d.value;
                return [4 /*yield*/, call([originalMailRef, originalMailRef.add], doc.data())];
            case 17:
                _g.sent();
                mRef = doc.ref;
                return [4 /*yield*/, call([mRef, mRef.delete])];
            case 18:
                _g.sent();
                _g.label = 19;
            case 19:
                _d = _c.next();
                return [3 /*break*/, 16];
            case 20: return [3 /*break*/, 23];
            case 21:
                e_4_1 = _g.sent();
                e_4 = { error: e_4_1 };
                return [3 /*break*/, 23];
            case 22:
                try {
                    if (_d && !_d.done && (_f = _c.return)) _f.call(_c);
                }
                finally { if (e_4) throw e_4.error; }
                return [7 /*endfinally*/];
            case 23:
                draftUpdates = draftData.data();
                // TODO: remove this. This line prevents us from nulling out the userId on the notice
                if (!draftUpdates.userId) {
                    delete draftUpdates.userId;
                }
                noticeObject = __assign(__assign({}, draftUpdates), { 
                    // clear the generated PDF document
                    noticeStatus: [
                        NoticeStatusType.affidavit_approved.value,
                        NoticeStatusType.affidavit_submitted.value
                    ].includes(originalNotice.data().noticeStatus)
                        ? originalNotice.data().noticeStatus
                        : NoticeStatusType.pending.value, 
                    /* Note:
                  This is Commented for making sure confirm modal does not reappear on noticeEdit.
                  After Conversation with Haris, no known sideEffects were found and keeping the commented code
                  for reverting back if any extensive sideEffect is found
                  
                  confirmedReceipt: !!auth.isPublisher,
                  */
                    confirmedAt: originalNotice.data().confirmedAt || submissionTime, confirmedReceiptTime: auth.isPublisher ? submissionTime : null, confirmed: true, confirmedBy: auth.isPublisher ? auth.user.ref : null, editedAt: submissionTime, lastEditedBy: auth.user.ref });
                delete noticeObject.owner;
                delete noticeObject.inactive;
                delete noticeObject.original;
                delete noticeObject.confirming;
                delete noticeObject.unusedDisplay;
                delete noticeObject.generatedAffidavitURL;
                delete noticeObject.generatedAffidavitStoragePath;
                noticeObject.displayParams &&
                    delete noticeObject.displayParams.imgs;
                noticeObject.displayParams &&
                    delete noticeObject.displayParams.err;
                return [4 /*yield*/, call([draft, draft.update], {
                        editedAt: submissionTime,
                        lastEditedBy: auth.user.ref
                    })];
            case 24:
                _g.sent();
                return [4 /*yield*/, call([original, original.update], noticeObject)];
            case 25:
                _g.sent();
                return [4 /*yield*/, put(push("/notice/" + placement.original.id))];
            case 26:
                _g.sent();
                return [3 /*break*/, 30];
            case 27:
                e_5 = _g.sent();
                Sentry.captureException(e_5);
                console.error('Placement: Error in publishNotice', e_5);
                return [3 /*break*/, 30];
            case 28: return [4 /*yield*/, put(PlacementActions.setConfirming(false))];
            case 29:
                _g.sent();
                return [7 /*endfinally*/];
            case 30: return [2 /*return*/];
        }
    });
}
function listenToNotice() {
    var placement;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 3];
                return [4 /*yield*/, select(placementSelector)];
            case 1:
                placement = _a.sent();
                return [4 /*yield*/, call(watchNotice, placement.draft)];
            case 2:
                _a.sent();
                return [3 /*break*/, 0];
            case 3: return [2 /*return*/];
        }
    });
}
function fetchAndHydrateNoticeData(action) {
    var ref, currentStep, e_6, noticeRef, notice, draftSnaps, _a, _b, doc, snapshot, e_7_1, draftSnap, auth_1, mailRef, mailObject, mailArray, _c, _d, mail, _e, proofStoragePath, confirming, data, user, action_1, draftRef, draftObject, drafts, noticeRef_1, draftMailRef, mailRef, mailObject, mailArray, _f, _g, mail, e_8_1, draftSnap_1, e_9;
    var e_7, _h, _j, _k, e_10, _l, e_8, _m;
    var _o;
    return __generator(this, function (_p) {
        switch (_p.label) {
            case 0: return [4 /*yield*/, put(PlacementActions.resetState())];
            case 1:
                _p.sent();
                return [4 /*yield*/, call(getAvailableStatesAndPapers)];
            case 2:
                _p.sent();
                if (!!action.noticeId) return [3 /*break*/, 13];
                _p.label = 3;
            case 3:
                _p.trys.push([3, 10, , 12]);
                return [4 /*yield*/, call(createNotice)];
            case 4:
                ref = _p.sent();
                if (!(action.redirect !== false)) return [3 /*break*/, 7];
                return [4 /*yield*/, select(placementSelector)];
            case 5:
                currentStep = (_p.sent()).currentStep;
                return [4 /*yield*/, call(updateHistoryNoRerender, "/place/" + ref.id + "?" + appendToCurrentParams('step', currentStep.id).toString())];
            case 6:
                _p.sent();
                return [3 /*break*/, 9];
            case 7: return [4 /*yield*/, call(fetchAndHydrateNoticeData, {
                    noticeId: ref.id,
                    redirect: false
                })];
            case 8:
                _p.sent();
                _p.label = 9;
            case 9: return [3 /*break*/, 12];
            case 10:
                e_6 = _p.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 11:
                _p.sent();
                Sentry.captureException(e_6);
                console.error('Placement: Error creating notice in fetchAndHydrateNoticeData:', e_6);
                return [3 /*break*/, 12];
            case 12: return [3 /*break*/, 63];
            case 13:
                _p.trys.push([13, 61, , 63]);
                noticeRef = Firebase.firestore()
                    .collection(Collections.userNotices)
                    .doc(action.noticeId);
                return [4 /*yield*/, call([noticeRef, noticeRef.get])];
            case 14:
                notice = _p.sent();
                return [4 /*yield*/, put(PlacementActions.setEditing(!!notice.data().noticeStatus))];
            case 15:
                _p.sent();
                draftSnaps = [];
                _p.label = 16;
            case 16:
                _p.trys.push([16, 21, 22, 23]);
                _a = __values(notice.data().drafts || []), _b = _a.next();
                _p.label = 17;
            case 17:
                if (!!_b.done) return [3 /*break*/, 20];
                doc = _b.value;
                return [4 /*yield*/, call([doc, doc.get])];
            case 18:
                snapshot = _p.sent();
                if (snapshot.data())
                    draftSnaps.push(snapshot);
                _p.label = 19;
            case 19:
                _b = _a.next();
                return [3 /*break*/, 17];
            case 20: return [3 /*break*/, 23];
            case 21:
                e_7_1 = _p.sent();
                e_7 = { error: e_7_1 };
                return [3 /*break*/, 23];
            case 22:
                try {
                    if (_b && !_b.done && (_h = _a.return)) _h.call(_a);
                }
                finally { if (e_7) throw e_7.error; }
                return [7 /*endfinally*/];
            case 23:
                draftSnap = void 0;
                return [4 /*yield*/, select(authSelector)];
            case 24:
                auth_1 = _p.sent();
                /**
                 *
                 * if the drafts exist
                 * if the user exists
                 * if only one draft and no owner
                 * anonymous user flow
                 */
                if (draftSnaps.length) {
                    if (auth_1.user) {
                        if (draftSnaps.length === 1 && !((_o = draftSnaps[0].data()) === null || _o === void 0 ? void 0 : _o.owner)) {
                            _j = __read(draftSnaps, 1), draftSnap = _j[0];
                        }
                        else {
                            draftSnap = draftSnaps.find(function (snap) {
                                return snap.data().owner && snap.data().owner.id === auth_1.user.ref.id;
                            });
                        }
                    }
                    else {
                        draftSnap = draftSnaps.find(function (snap) { return !snap.data().owner; });
                    }
                }
                // eslint-disable-next-line no-extra-boolean-cast
                if (auth_1.user &&
                    auth_1.user.data().occupation !== OccupationType.publishing.value) {
                    if (draftSnaps.length > 1) {
                        draftSnap = draftSnaps[draftSnaps.length - 1];
                    }
                    _k = __read(draftSnaps, 1), draftSnap = _k[0];
                }
                if (!draftSnap) return [3 /*break*/, 39];
                mailRef = draftSnap.ref.collection(Collections.mail);
                return [4 /*yield*/, call([mailRef, mailRef.get])];
            case 25:
                mailObject = _p.sent();
                mailArray = [];
                try {
                    for (_c = __values(mailObject.docs), _d = _c.next(); !_d.done; _d = _c.next()) {
                        mail = _d.value;
                        mailArray.push(mail.data());
                    }
                }
                catch (e_10_1) { e_10 = { error: e_10_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_l = _c.return)) _l.call(_c);
                    }
                    finally { if (e_10) throw e_10.error; }
                }
                return [4 /*yield*/, put(PlacementActions.setDraftSnap(draftSnap))];
            case 26:
                _p.sent();
                return [4 /*yield*/, put(PlacementActions.setMail(mailArray))];
            case 27:
                _p.sent();
                return [4 /*yield*/, put(PlacementActions.setDraft(draftSnap.ref))];
            case 28:
                _p.sent();
                _e = draftSnap.data(), proofStoragePath = _e.proofStoragePath, confirming = _e.confirming, data = __rest(_e, ["proofStoragePath", "confirming"]);
                return [4 /*yield*/, put(PlacementActions.populateNoticeData(data))];
            case 29:
                _p.sent();
                if (!(data.confirmedHtml || data.text)) return [3 /*break*/, 31];
                return [4 /*yield*/, put(PlacementActions.setConfirmedText(data.confirmedHtml || data.text))];
            case 30:
                _p.sent();
                _p.label = 31;
            case 31:
                if (!draftSnap.data().newspaper) return [3 /*break*/, 33];
                return [4 /*yield*/, put(PlacementActions.setNewspaper(draftSnap.data().newspaper))];
            case 32:
                _p.sent();
                _p.label = 33;
            case 33:
                if (!!draftSnap.data().userId) return [3 /*break*/, 38];
                user = void 0;
                if (!auth_1.user) return [3 /*break*/, 34];
                user = auth_1.user;
                return [3 /*break*/, 36];
            case 34: return [4 /*yield*/, take(AuthTypes.SET_USER)];
            case 35:
                action_1 = _p.sent();
                user = action_1.user;
                _p.label = 36;
            case 36:
                if (!(user.data().occupation !== OccupationType.publishing.value)) return [3 /*break*/, 38];
                return [4 /*yield*/, put(PlacementActions.setFiler(user.ref))];
            case 37:
                _p.sent();
                _p.label = 38;
            case 38: return [3 /*break*/, 60];
            case 39:
                draftRef = Firebase.firestore()
                    .collection(Collections.userDrafts)
                    .doc();
                draftObject = __assign(__assign({}, notice.data()), { original: notice.ref, owner: auth_1.user ? auth_1.user.ref : null });
                delete draftObject.editedAt;
                delete draftObject.lastEditedBy;
                delete draftObject.drafts;
                delete draftObject.proofStoragePath;
                drafts = notice.data().drafts || [];
                drafts.push(draftRef);
                noticeRef_1 = notice.ref;
                return [4 /*yield*/, call([noticeRef_1, noticeRef_1.update], { drafts: drafts })];
            case 40:
                _p.sent();
                return [4 /*yield*/, call([draftRef, draftRef.set], draftObject)];
            case 41:
                _p.sent();
                if (!auth_1.user) return [3 /*break*/, 52];
                draftMailRef = draftRef.collection(Collections.mail);
                mailRef = notice.ref.collection(Collections.mail);
                return [4 /*yield*/, call([mailRef, mailRef.get])];
            case 42:
                mailObject = _p.sent();
                mailArray = [];
                _p.label = 43;
            case 43:
                _p.trys.push([43, 48, 49, 50]);
                _f = __values(mailObject.docs), _g = _f.next();
                _p.label = 44;
            case 44:
                if (!!_g.done) return [3 /*break*/, 47];
                mail = _g.value;
                return [4 /*yield*/, call([draftMailRef, draftMailRef.add], mail.data())];
            case 45:
                _p.sent();
                mailArray.push(mail.data());
                _p.label = 46;
            case 46:
                _g = _f.next();
                return [3 /*break*/, 44];
            case 47: return [3 /*break*/, 50];
            case 48:
                e_8_1 = _p.sent();
                e_8 = { error: e_8_1 };
                return [3 /*break*/, 50];
            case 49:
                try {
                    if (_g && !_g.done && (_m = _f.return)) _m.call(_f);
                }
                finally { if (e_8) throw e_8.error; }
                return [7 /*endfinally*/];
            case 50: return [4 /*yield*/, put(PlacementActions.setMail(mailArray))];
            case 51:
                _p.sent();
                _p.label = 52;
            case 52: return [4 /*yield*/, call([draftRef, draftRef.get])];
            case 53:
                draftSnap_1 = _p.sent();
                return [4 /*yield*/, put(PlacementActions.populateNoticeData(draftObject))];
            case 54:
                _p.sent();
                if (!(draftObject.confirmedHtml || draftObject.text)) return [3 /*break*/, 56];
                return [4 /*yield*/, put(PlacementActions.setConfirmedText(draftObject.confirmedHtml || draftObject.text))];
            case 55:
                _p.sent();
                _p.label = 56;
            case 56: return [4 /*yield*/, put(PlacementActions.setDraftSnap(draftSnap_1))];
            case 57:
                _p.sent();
                return [4 /*yield*/, put(PlacementActions.setDraft(draftRef))];
            case 58:
                _p.sent();
                return [4 /*yield*/, put(PlacementActions.setOriginal(noticeRef_1))];
            case 59:
                _p.sent();
                _p.label = 60;
            case 60: return [3 /*break*/, 63];
            case 61:
                e_9 = _p.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 62:
                _p.sent();
                Sentry.captureException(e_9);
                console.error('Placement: Error setting draft contents in fetchAndHydrateNoticeData:', e_9);
                return [3 /*break*/, 63];
            case 63: return [4 /*yield*/, fork(listenToNotice)];
            case 64:
                _p.sent();
                return [2 /*return*/];
        }
    });
}
function saveDraft() {
    var placement, draft, availableStates, newspaperList, file, mail, editing, draftSnap, placementError, confirmedText, currentStep, customer, publicationDates, columnsEditingEnabled, updateObject, mailRef, oldMail, _a, _b, doc, mRef, e_11_1, mail_1, mail_1_1, m, e_12_1, e_13, placementDraft, e_14;
    var e_11, _c, e_12, _d;
    var _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, select(placementSelector)];
            case 1:
                placement = _f.sent();
                draft = placement.draft, availableStates = placement.availableStates, newspaperList = placement.newspaperList, file = placement.file, mail = placement.mail, editing = placement.editing, draftSnap = placement.draftSnap, placementError = placement.placementError, confirmedText = placement.confirmedText, currentStep = placement.currentStep, customer = placement.customer, publicationDates = placement.publicationDates, columnsEditingEnabled = placement.columnsEditingEnabled, updateObject = __rest(placement, ["draft", "availableStates", "newspaperList", "file", "mail", "editing", "draftSnap", "placementError", "confirmedText", "currentStep", "customer", "publicationDates", "columnsEditingEnabled"]);
                _f.label = 2;
            case 2:
                _f.trys.push([2, 20, , 22]);
                if (!draft)
                    return [2 /*return*/];
                mailRef = draft.collection(Collections.mail);
                return [4 /*yield*/, call([mailRef, mailRef.get])];
            case 3:
                oldMail = _f.sent();
                if (!oldMail.docs) return [3 /*break*/, 11];
                _f.label = 4;
            case 4:
                _f.trys.push([4, 9, 10, 11]);
                _a = __values(oldMail.docs), _b = _a.next();
                _f.label = 5;
            case 5:
                if (!!_b.done) return [3 /*break*/, 8];
                doc = _b.value;
                mRef = doc.ref;
                return [4 /*yield*/, call([mRef, mRef.delete])];
            case 6:
                _f.sent();
                _f.label = 7;
            case 7:
                _b = _a.next();
                return [3 /*break*/, 5];
            case 8: return [3 /*break*/, 11];
            case 9:
                e_11_1 = _f.sent();
                e_11 = { error: e_11_1 };
                return [3 /*break*/, 11];
            case 10:
                try {
                    if (_b && !_b.done && (_c = _a.return)) _c.call(_a);
                }
                finally { if (e_11) throw e_11.error; }
                return [7 /*endfinally*/];
            case 11:
                if (!(mail && mail.length)) return [3 /*break*/, 19];
                _f.label = 12;
            case 12:
                _f.trys.push([12, 17, 18, 19]);
                mail_1 = __values(mail), mail_1_1 = mail_1.next();
                _f.label = 13;
            case 13:
                if (!!mail_1_1.done) return [3 /*break*/, 16];
                m = mail_1_1.value;
                return [4 /*yield*/, call([mailRef, mailRef.add], m)];
            case 14:
                _f.sent();
                _f.label = 15;
            case 15:
                mail_1_1 = mail_1.next();
                return [3 /*break*/, 13];
            case 16: return [3 /*break*/, 19];
            case 17:
                e_12_1 = _f.sent();
                e_12 = { error: e_12_1 };
                return [3 /*break*/, 19];
            case 18:
                try {
                    if (mail_1_1 && !mail_1_1.done && (_d = mail_1.return)) _d.call(mail_1);
                }
                finally { if (e_12) throw e_12.error; }
                return [7 /*endfinally*/];
            case 19: return [3 /*break*/, 22];
            case 20:
                e_13 = _f.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 21:
                _f.sent();
                Sentry.captureException(e_13);
                console.error('Placement: Error processing mail in saveDraft:', e_13);
                return [3 /*break*/, 22];
            case 22:
                if (!updateObject.displayParams ||
                    !Object.keys(placement.displayParams).length)
                    delete updateObject.displayParams;
                // Remove imgs from the display params before saving in the database
                // as this field is *massive* and is later encoded in the image urls
                if ((_e = updateObject.displayParams) === null || _e === void 0 ? void 0 : _e.imgs) {
                    delete updateObject.displayParams.imgs;
                }
                // ensure that we don't set the property filingCounty
                delete updateObject.filingCounty;
                _f.label = 23;
            case 23:
                _f.trys.push([23, 27, , 29]);
                console.log(updateObject);
                updateObject.userId = placement.filer ? placement.filer.id : null;
                updateObject.publicationDates = publicationDates || null;
                return [4 /*yield*/, call([draft, draft.get])];
            case 24:
                placementDraft = _f.sent();
                if (!placementDraft) return [3 /*break*/, 26];
                return [4 /*yield*/, call([draft, draft === null || draft === void 0 ? void 0 : draft.update], updateObject)];
            case 25:
                _f.sent();
                _f.label = 26;
            case 26: return [3 /*break*/, 29];
            case 27:
                e_14 = _f.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 28:
                _f.sent();
                Sentry.captureException(e_14);
                console.log('Placement: Error saving draft in saveDraft:', e_14);
                return [3 /*break*/, 29];
            case 29: return [2 /*return*/];
        }
    });
}
function processNewspaperUpdate(action) {
    var newspaperRef, _a, draft, draftSnap, newspaper, _b, noticeType, previousNoticeType, _c, adTemplate, defaultLinerRate, defaultColumns, e_15;
    var _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                if (!action.newspaperRef) return [3 /*break*/, 21];
                newspaperRef = action.newspaperRef;
                _g.label = 1;
            case 1:
                _g.trys.push([1, 19, , 21]);
                return [4 /*yield*/, select(placementSelector)];
            case 2:
                _a = _g.sent(), draft = _a.draft, draftSnap = _a.draftSnap;
                return [4 /*yield*/, call([newspaperRef, newspaperRef.get])];
            case 3:
                newspaper = _g.sent();
                if (!draftSnap) return [3 /*break*/, 6];
                _b = draftSnap.data(), noticeType = _b.noticeType, previousNoticeType = _b.previousNoticeType;
                return [4 /*yield*/, put(PlacementActions.setNoticeType(noticeType))];
            case 4:
                _g.sent();
                if (!previousNoticeType) return [3 /*break*/, 6];
                return [4 /*yield*/, put(PlacementActions.setPreviousNoticeType(previousNoticeType))];
            case 5:
                _g.sent();
                _g.label = 6;
            case 6:
                if (!(newspaper.id !== ((_e = (_d = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _d === void 0 ? void 0 : _d.newspaper) === null || _e === void 0 ? void 0 : _e.id) && draftSnap)) return [3 /*break*/, 11];
                return [4 /*yield*/, put(PlacementActions.setNoticeType(NoticeType.custom.value))];
            case 7:
                _g.sent();
                return [4 /*yield*/, put(PlacementActions.setNoticeText(''))];
            case 8:
                _g.sent();
                return [4 /*yield*/, put(PlacementActions.setConfirmedText(null))];
            case 9:
                _g.sent();
                draftSnap.ref.update({
                    confirmedHtml: '',
                    publicationDates: null
                });
                return [4 /*yield*/, put(PlacementActions.setPublicationDates(null))];
            case 10:
                _g.sent();
                _g.label = 11;
            case 11:
                _c = newspaper.data(), adTemplate = _c.adTemplate, defaultLinerRate = _c.defaultLinerRate, defaultColumns = _c.defaultColumns;
                return [4 /*yield*/, put(PlacementActions.setTemplate(adTemplate))];
            case 12:
                _g.sent();
                return [4 /*yield*/, put(PlacementActions.setRate(defaultLinerRate))];
            case 13:
                _g.sent();
                if (!(defaultColumns && !((_f = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _f === void 0 ? void 0 : _f.columns))) return [3 /*break*/, 15];
                return [4 /*yield*/, put(PlacementActions.setColumns(defaultColumns))];
            case 14:
                _g.sent();
                _g.label = 15;
            case 15:
                if (!draft) return [3 /*break*/, 17];
                return [4 /*yield*/, draft.update({
                        newspaper: newspaperRef
                    })];
            case 16:
                _g.sent();
                _g.label = 17;
            case 17: return [4 /*yield*/, call(saveDraft)];
            case 18:
                _g.sent();
                return [3 /*break*/, 21];
            case 19:
                e_15 = _g.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 20:
                _g.sent();
                Sentry.captureException(e_15);
                console.error('Placement: Error in processNewspaperUpdate:', e_15);
                return [3 /*break*/, 21];
            case 21: return [2 /*return*/];
        }
    });
}
function processAdTemplateChanges(action) {
    var _a, newspaper, filer, noticeType_1, filerSnap, _b, newspaperSnap, chosenCustomType, chosenCustomTypeTemplate, template, e_16;
    var _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 8, , 10]);
                return [4 /*yield*/, select(placementSelector)];
            case 1:
                _a = _e.sent(), newspaper = _a.newspaper, filer = _a.filer, noticeType_1 = _a.noticeType;
                if (!newspaper)
                    return [2 /*return*/];
                if (!filer) return [3 /*break*/, 3];
                return [4 /*yield*/, call([filer, filer.get])];
            case 2:
                _b = _e.sent();
                return [3 /*break*/, 4];
            case 3:
                _b = null;
                _e.label = 4;
            case 4:
                filerSnap = _b;
                return [4 /*yield*/, call([newspaper, newspaper.get])];
            case 5:
                newspaperSnap = _e.sent();
                chosenCustomType = (_d = (_c = newspaperSnap
                    .data()) === null || _c === void 0 ? void 0 : _c.allowedNotices) === null || _d === void 0 ? void 0 : _d.find(function (nt) { return nt.value === noticeType_1; });
                chosenCustomTypeTemplate = chosenCustomType
                    ? chosenCustomType.template
                    : null;
                return [4 /*yield*/, call(getAdTemplate, newspaperSnap, filerSnap, chosenCustomTypeTemplate)];
            case 6:
                template = _e.sent();
                return [4 /*yield*/, put(PlacementActions.setTemplate(template))];
            case 7:
                _e.sent();
                return [3 /*break*/, 10];
            case 8:
                e_16 = _e.sent();
                return [4 /*yield*/, put(PlacementActions.setPlacementError(new PlacementError()))];
            case 9:
                _e.sent();
                Sentry.captureException(e_16);
                console.error('Placement: Error in processFilerUpdate:', e_16);
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function initAdProofGeneration() {
    var draft, noticeId, e_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 4, , 5]);
                return [4 /*yield*/, select(placementSelector)];
            case 1:
                draft = (_a.sent()).draft;
                return [4 /*yield*/, call([draft, draft.update], {
                        proofStoragePath: null,
                        proofUrl: null,
                        jpgStoragePath: null,
                        jpgURL: null
                    })];
            case 2:
                _a.sent();
                noticeId = draft.id;
                return [4 /*yield*/, call([api, api.post], 'documents/generate-proof', {
                        noticeDraft: noticeId
                    })];
            case 3:
                _a.sent();
                return [3 /*break*/, 5];
            case 4:
                e_17 = _a.sent();
                Sentry.captureMessage("Error generating proof in initAdProofGeneration: " + e_17);
                console.error('Placement: Error in initAdProofGeneration:', e_17);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function publishNoticeNoProof() { return __generator(this, function (_a) {
    return [2 /*return*/];
}); }
function setNoticeType(_a) {
    var _b, newspaper, draft, newspaperSnap, draftSnap, _c, chosenCustomType, customAdvertiserRate, _d, _e, defaultRates, placement, isDisplay, minColumnsAllowed;
    var noticeType = _a.noticeType;
    var _f, _g, _h;
    return __generator(this, function (_j) {
        switch (_j.label) {
            case 0: return [4 /*yield*/, select(placementSelector)];
            case 1:
                _b = _j.sent(), newspaper = _b.newspaper, draft = _b.draft;
                if (!newspaper)
                    return [2 /*return*/];
                return [4 /*yield*/, call([newspaper, newspaper.get])];
            case 2:
                newspaperSnap = _j.sent();
                if (!draft) return [3 /*break*/, 4];
                return [4 /*yield*/, call([draft, draft.get])];
            case 3:
                _c = _j.sent();
                return [3 /*break*/, 5];
            case 4:
                _c = null;
                _j.label = 5;
            case 5:
                draftSnap = _c;
                chosenCustomType = (_g = (_f = newspaperSnap
                    .data()) === null || _f === void 0 ? void 0 : _f.allowedNotices) === null || _g === void 0 ? void 0 : _g.find(function (nt) { return nt.value === noticeType; });
                _d = call;
                _e = [checkForDefault];
                return [4 /*yield*/, select(placementSelector)];
            case 6: return [4 /*yield*/, _d.apply(void 0, _e.concat([_j.sent(),
                    newspaperSnap,
                    noticeType]))];
            case 7:
                customAdvertiserRate = _j.sent();
                defaultRates = [
                    newspaperSnap.data().defaultLinerRate.id,
                    newspaperSnap.data().defaultDisplayRate.id
                ];
                if (!(customAdvertiserRate && !defaultRates.includes(customAdvertiserRate.id))) return [3 /*break*/, 9];
                return [4 /*yield*/, put(PlacementActions.setRate(customAdvertiserRate))];
            case 8:
                _j.sent();
                _j.label = 9;
            case 9:
                if (!chosenCustomType) return [3 /*break*/, 18];
                if (!(chosenCustomType.rate &&
                    (!customAdvertiserRate || defaultRates.includes(customAdvertiserRate === null || customAdvertiserRate === void 0 ? void 0 : customAdvertiserRate.id)))) return [3 /*break*/, 11];
                return [4 /*yield*/, put(PlacementActions.setRate(chosenCustomType.rate))];
            case 10:
                _j.sent();
                _j.label = 11;
            case 11: return [4 /*yield*/, select(placementSelector)];
            case 12:
                placement = _j.sent();
                if (!chosenCustomType.columns) return [3 /*break*/, 14];
                return [4 /*yield*/, put(PlacementActions.setColumns(chosenCustomType.columns))];
            case 13:
                _j.sent();
                return [3 /*break*/, 16];
            case 14:
                isDisplay = placement.processedDisplay;
                minColumnsAllowed = isDisplay
                    ? newspaperSnap.data().displayMinColumns
                    : newspaperSnap.data().linerMinColumns;
                if (!(minColumnsAllowed && minColumnsAllowed > ((_h = draftSnap === null || draftSnap === void 0 ? void 0 : draftSnap.data()) === null || _h === void 0 ? void 0 : _h.columns))) return [3 /*break*/, 16];
                return [4 /*yield*/, put(PlacementActions.setColumns(minColumnsAllowed))];
            case 15:
                _j.sent();
                _j.label = 16;
            case 16: return [4 /*yield*/, call(saveDraft)];
            case 17:
                _j.sent();
                return [2 /*return*/];
            case 18:
                if (!customAdvertiserRate) return [3 /*break*/, 19];
                return [3 /*break*/, 23];
            case 19:
                if (!(noticeType === NoticeType.display_ad.value)) return [3 /*break*/, 21];
                return [4 /*yield*/, put(PlacementActions.setRate(newspaperSnap.data().defaultDisplayRate))];
            case 20:
                _j.sent();
                return [3 /*break*/, 23];
            case 21: return [4 /*yield*/, put(PlacementActions.setRate(newspaperSnap.data().defaultLinerRate))];
            case 22:
                _j.sent();
                _j.label = 23;
            case 23: return [2 /*return*/];
        }
    });
}
function updateFooter() {
    var placement, draft, footer, placementDraft, e_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 7, , 8]);
                return [4 /*yield*/, select(placementSelector)];
            case 1:
                placement = _a.sent();
                draft = placement.draft;
                if (!draft)
                    return [2 /*return*/];
                return [4 /*yield*/, call(generateFormattedFooter, placement)];
            case 2:
                footer = _a.sent();
                return [4 /*yield*/, put(PlacementActions.setDynamicFooter(footer))];
            case 3:
                _a.sent();
                return [4 /*yield*/, call([draft, draft.get])];
            case 4:
                placementDraft = _a.sent();
                if (!(placement.dynamicFooter && placementDraft.exists)) return [3 /*break*/, 6];
                return [4 /*yield*/, call([draft, draft.update], {
                        dynamicFooter: placement.dynamicFooter
                    })];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [3 /*break*/, 8];
            case 7:
                e_18 = _a.sent();
                Sentry.captureException(e_18);
                console.error('Placement: Error in updateFooter:', e_18);
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function processPublicationDateUpdate() {
    var placement, rate, newspaper, rateSnapshot, newspaperSnap, newRate, _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0: return [4 /*yield*/, select(placementSelector)];
            case 1:
                placement = _c.sent();
                rate = placement.rate, newspaper = placement.newspaper;
                if (!rate)
                    return [2 /*return*/];
                return [4 /*yield*/, call([rate, rate.get])];
            case 2:
                rateSnapshot = _c.sent();
                if (rateCanPublishWithPlacementData(rateSnapshot, placement))
                    return [2 /*return*/];
                return [4 /*yield*/, call([
                        newspaper,
                        newspaper.get
                    ])];
            case 3:
                newspaperSnap = _c.sent();
                _a = call;
                _b = [checkForDefault];
                return [4 /*yield*/, select(placementSelector)];
            case 4: return [4 /*yield*/, _a.apply(void 0, _b.concat([_c.sent(),
                    newspaperSnap]))];
            case 5:
                newRate = _c.sent();
                if (!newRate) return [3 /*break*/, 7];
                return [4 /*yield*/, put(PlacementActions.setRate(newRate))];
            case 6:
                _c.sent();
                _c.label = 7;
            case 7: return [2 /*return*/];
        }
    });
}
// Override the default column width if the height extends beyond a threshold
function processDisplayParameterUpdate(_a) {
    var _b, newspaper, columns, newspaperSnap, thresholds, minimumColumnSize, totalColumnInches;
    var displayParams = _a.displayParams;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, select(placementSelector)];
            case 1:
                _b = _d.sent(), newspaper = _b.newspaper, columns = _b.columns;
                if (!newspaper)
                    return [2 /*return*/];
                return [4 /*yield*/, call([
                        newspaper,
                        newspaper.get
                    ])];
            case 2:
                newspaperSnap = _d.sent();
                thresholds = (_c = newspaperSnap.data()) === null || _c === void 0 ? void 0 : _c.thresholds;
                if (!thresholds)
                    return [2 /*return*/];
                if (!displayParams)
                    return [2 /*return*/];
                minimumColumnSize = columns;
                totalColumnInches = displayParams.height * columns;
                Object.keys(thresholds)
                    .sort()
                    .forEach(function (thresholdSize) {
                    if (parseFloat(thresholdSize) > totalColumnInches)
                        return;
                    minimumColumnSize = thresholds[thresholdSize];
                });
                if (!(minimumColumnSize > columns)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(PlacementActions.setColumns(minimumColumnSize))];
            case 3:
                _d.sent();
                _d.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function processColumnUpdate() {
    var _a, newspaper, columns, noticeType, adTemplate, newspaperSnap, templateThresholds, updatedTemplate;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(placementSelector)];
            case 1:
                _a = _b.sent(), newspaper = _a.newspaper, columns = _a.columns, noticeType = _a.noticeType, adTemplate = _a.adTemplate;
                if (noticeType === NoticeType.display_ad.value)
                    return [2 /*return*/];
                if (!newspaper)
                    return [2 /*return*/];
                return [4 /*yield*/, call([
                        newspaper,
                        newspaper.get
                    ])];
            case 2:
                newspaperSnap = _b.sent();
                templateThresholds = newspaperSnap.data().templateThresholds;
                if (!templateThresholds)
                    return [2 /*return*/];
                updatedTemplate = adTemplate;
                Object.keys(templateThresholds)
                    .sort()
                    .forEach(function (columnThreshold) {
                    var _a;
                    var thresholdNumber = parseInt(columnThreshold, 10);
                    if (columns < thresholdNumber)
                        return;
                    if (updatedTemplate.id !== ((_a = templateThresholds[thresholdNumber]) === null || _a === void 0 ? void 0 : _a.id)) {
                        updatedTemplate = templateThresholds[thresholdNumber];
                    }
                });
                if (!(updatedTemplate.id !== adTemplate.id)) return [3 /*break*/, 4];
                return [4 /*yield*/, put(PlacementActions.setTemplate(updatedTemplate))];
            case 3:
                _b.sent();
                _b.label = 4;
            case 4: return [2 /*return*/];
        }
    });
}
function placementSaga() {
    var _a, _b;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                _a = all;
                return [4 /*yield*/, takeLatest(PlacementTypes.HYDRATE_NOTICE_DATA, fetchAndHydrateNoticeData)];
            case 1:
                _b = [
                    _c.sent()
                ];
                return [4 /*yield*/, takeEvery(PlacementTypes.SAVE_DRAFT, saveDraft)];
            case 2:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(PlacementTypes.SET_NEWSPAPER, processNewspaperUpdate)];
            case 3:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(PlacementTypes.CONFIRM_SCHEDULE, processPublicationDateUpdate)];
            case 4:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery([
                        PlacementTypes.SET_FILER,
                        PlacementTypes.SET_NOTICE_TYPE,
                        PlacementTypes.SET_NEWSPAPER
                    ], processAdTemplateChanges)];
            case 5:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(PlacementTypes.PUBLISH_NOTICE, publishNotice)];
            case 6:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(PlacementTypes.SET_CONFIRMED_CROP, saveDraft)];
            case 7:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(PlacementTypes.SET_PROOF, initAdProofGeneration)];
            case 8:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery([
                        PlacementTypes.POPULATE_NOTICE_DATA,
                        PlacementTypes.SET_NOTICE_TEXT,
                        PlacementTypes.SET_COLUMNS,
                        PlacementTypes.SET_DISPLAY_PARAMS,
                        PlacementTypes.CONFIRM_SCHEDULE
                    ], updateFooter)];
            case 9:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(PlacementTypes.PUBLISH_NOTICE_NO_PROOF, publishNoticeNoProof)];
            case 10:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(PlacementTypes.SET_NOTICE_TYPE, function (action) {
                        return setNoticeType(action);
                    })];
            case 11:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeLatest(PlacementTypes.SET_DISPLAY_PARAMS, function (action) {
                        return processDisplayParameterUpdate(action);
                    })];
            case 12:
                _b = _b.concat([
                    _c.sent()
                ]);
                return [4 /*yield*/, takeEvery(PlacementTypes.SET_COLUMNS, processColumnUpdate)];
            case 13: return [4 /*yield*/, _a.apply(void 0, [_b.concat([
                        _c.sent()
                    ])])];
            case 14:
                _c.sent();
                return [2 /*return*/];
        }
    });
}
export default placementSaga;
