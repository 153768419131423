export var envs = {
    STAGING: 'STAGING',
    TEST: 'TEST',
    DEMO: 'DEMO',
    PROD: 'PROD',
    DEV: 'DEV'
};
export var IndesignRoute;
(function (IndesignRoute) {
    IndesignRoute["liner_ad_image"] = "/";
    IndesignRoute["display_ad_image"] = "/display/image";
    IndesignRoute["template_specs"] = "/template/specs";
    IndesignRoute["health"] = "/health";
    IndesignRoute["display_ad_marginalia"] = "/display/marginalia";
    IndesignRoute["paginate"] = "/paginate";
    IndesignRoute["paginate_rtf"] = "/paginate/rtf";
})(IndesignRoute || (IndesignRoute = {}));
export var Collections = {
    announcements: 'announcements',
    subscriptions: 'subscriptions',
    publicNotices: 'publicNotices',
    userNotices: 'usernotices',
    userDrafts: 'userdrafts',
    users: 'users',
    organizations: 'organizations',
    affidavits: 'affidavits',
    invoices: 'invoices',
    notifications: 'notifications',
    emails: 'emails',
    mail: 'mail',
    courthouse: 'courthouse',
    invites: 'invites',
    adTemplates: 'adTemplates',
    rates: 'rates',
    notices: 'notices',
    transfers: 'transfers',
    previewNotices: 'previewNotices',
    emailConfirmations: 'emailConfirmations',
    sources: 'sources',
    donationProfiles: 'donationProfiles',
    donors: 'donors',
    donations: 'donations',
    cards: 'cards',
    donationPayouts: 'donationPayouts',
    cardInvoices: 'cardInvoices',
    cardTransactions: 'cardTransactions',
    joyrides: 'joyrides',
    events: 'events',
    customers: 'customers',
    customerOrganizations: 'customerOrganizations',
    survey: 'surveys',
    dwollaEvents: 'dwollaEvents',
    invoiceItems: 'invoiceItems',
    payouts: 'payouts'
};
export var Functions = {
    createCustomer: 'createCustomer',
    grantCustomClaim: 'grantCustomClaim',
    createPublisherDocsOnCall: 'createPublisherDocsOnCall',
    cloudConvertOnCall: 'cloudConvertOnCall',
    donate: 'donate',
    createBillingSession: 'createBillingSession'
};
export var EChars = {
    tab: "__enotice-tab-char"
};
var firebaseProject;
try {
    firebaseProject = require('../src/config.firebase').default.projectId;
}
catch (err) {
    firebaseProject = process.env.GCLOUD_PROJECT;
}
// Avoid any console.log() in the global scope while running jest tests because
// jest reinitializes all dependences for every test case, meaning this would
// print hundreds of times.
if (!process.env.JEST_WORKER_ID) {
    if (firebaseProject) {
        console.log('using: ', firebaseProject);
    }
    else {
        console.log('Cannot find local firebase project ID');
    }
}
export var CLOUDINARY_BUCKET = 'dgqq2xsfd';
export var FIREBASE_PROJECT = firebaseProject;
export var PROJECT_LOCATION = 'us-central1';
export var BACKEND_URL = "https://us-central1-" + FIREBASE_PROJECT + ".cloudfunctions.net/api/";
// export const BACKEND_URL = `http://localhost:5001/${FIREBASE_PROJECT}/us-central1/api/`;
export var PLAID_PUBLIC_KEY = '541c7f3939c359167f48904d2571a0';
export var DEFAULT_ACCEPT = '.docx,.doc,.csv,.xls,.xlsx,.txt,.rtf,.jpg,.jpeg,.png,.pdf,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/rtf,text/rtf,image/jpeg,image/png';
export var PRODUCTION_PROJECT = 'enotice-production';
export var STAGING_PROJECT = 'column-staging';
export var DEMO_PROJECT = 'enotice-demo-8d99a';
export var STRIPE = 'stipe';
export var PAYWAY = 'payway';
export var GATEWAYS = { STRIPE: STRIPE, PAYWAY: PAYWAY };
