import { includeSearchParams } from 'utils/urls';
import { OccupationType } from 'lib/enums';
export var getNotAuthdRedirect = function (userAuth, pathname) {
    var alreadyRedirected = ['/login/'].includes(pathname);
    if (alreadyRedirected)
        return false;
    if (!userAuth)
        return '/login/';
};
var publishingEnum = OccupationType.publishing.value;
export var organizationIsSetup = function (user, organization) {
    return (user.organization &&
        (user.occupation !== publishingEnum ||
            (user.occupation === publishingEnum &&
                organization &&
                organization.accountId))) ||
        user.occupation === OccupationType.individual.value;
};
export var getPostAuthRedirect = function (user, organization, pathname) {
    if (!user)
        return false;
    var alreadyRedirected = [
        '/invites/confirm-organization',
        '/register/organization',
        '/register/organization/post-registration',
        '/new-feature-onboard/advertising-deadlines',
        '/register/confirm'
    ].find(function (path) { return pathname.includes(path); });
    if (alreadyRedirected)
        return false;
    /*
      User has been created through the invitation flow, but has not yet been formally linked to an organization
    */
    if (user.invite) {
        return includeSearchParams('/invites/confirm-organization/');
    }
};
