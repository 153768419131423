export var getBeacon = function () {
    return window.Beacon;
};
export var setupBeacon = function () {
    var scriptTag0 = document.createElement('script');
    scriptTag0.type = 'text/javascript';
    scriptTag0.async = true;
    scriptTag0.innerText =
        '!function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){});';
    var scriptTag1 = document.createElement('script');
    scriptTag1.type = 'text/javascript';
    scriptTag1.async = true;
    scriptTag1.innerText =
        "window.Beacon('init', 'de7afdd6-c3c9-4825-a4df-10fcd06f92e8')";
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(scriptTag0, s);
    s.parentNode.insertBefore(scriptTag1, s);
};
