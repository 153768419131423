var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { guidGenerator } from 'lib/helpers';
import { BellIcon, CheckOutlinedIcon } from 'icons';
import Firebase, { Collections } from '../EnoticeFirebase';
var mapDispatchToProps = function (dispatch) { return ({
    push: function (path) { return dispatch(push(path)); }
}); };
var NotificationDropdown = function (_a) {
    var user = _a.user, push = _a.push, open = _a.open, setOpen = _a.setOpen;
    var _b = __read(useState([]), 2), notifications = _b[0], setNotifications = _b[1];
    var userNotificationQuery = Firebase.firestore()
        .collection(Collections.notifications)
        .where('user', '==', user.ref)
        .where('inApp.read', '==', false);
    var loadRecentNotifications = function () { return __awaiter(void 0, void 0, void 0, function () {
        var recentNotifications;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, userNotificationQuery.limit(100).get()];
                case 1:
                    recentNotifications = _a.sent();
                    setNotifications(recentNotifications.docs);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        loadRecentNotifications();
    }, []);
    var _c = __read(useState(false), 2), clearingNotifications = _c[0], setClearingNotifications = _c[1];
    var getNotifications = function () {
        return notifications.slice(0, 4).map(function (docSnapshot) {
            var _a = docSnapshot.data(), notice = _a.notice, inApp = _a.inApp;
            var link = (inApp && inApp.link) || (notice && "/notice/" + notice.id) || '';
            return (React.createElement("div", { key: guidGenerator(), className: "w-full block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                    var updateObj;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                updateObj = __assign({}, inApp);
                                updateObj.read = true;
                                if (!docSnapshot) return [3 /*break*/, 2];
                                return [4 /*yield*/, docSnapshot.ref.update({ inApp: updateObj })];
                            case 1:
                                _a.sent();
                                _a.label = 2;
                            case 2:
                                setOpen(false);
                                push(link);
                                loadRecentNotifications();
                                return [2 /*return*/];
                        }
                    });
                }); } }, inApp.text));
        });
    };
    var clearAllNotifications = function () { return (React.createElement("div", { key: guidGenerator(), className: "w-full block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer", onClick: function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var userNotificationQuerySnapshot, notifications;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        e.preventDefault();
                        setClearingNotifications(true);
                        return [4 /*yield*/, userNotificationQuery.get()];
                    case 1:
                        userNotificationQuerySnapshot = _a.sent();
                        notifications = userNotificationQuerySnapshot.docs;
                        setOpen(!open);
                        return [4 /*yield*/, Promise.all(notifications.map(function (n) { return __awaiter(void 0, void 0, void 0, function () {
                                var inApp, updateObj;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            inApp = n.data().inApp;
                                            updateObj = __assign({}, inApp);
                                            updateObj.read = true;
                                            return [4 /*yield*/, n.ref.update({ inApp: updateObj })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            }); }))];
                    case 2:
                        _a.sent();
                        setClearingNotifications(false);
                        loadRecentNotifications();
                        return [2 /*return*/];
                }
            });
        }); } },
        React.createElement(CheckOutlinedIcon, { className: "inline-block w-5 h-5 align-center pb-0.25" }),
        "\u00A0\u00A0Clear All Notifications")); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "relative" },
            React.createElement("div", null,
                React.createElement("button", { className: "inline-block relative bg-white p-1 rounded-full text-white hover:text-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500", onClick: function () { return setOpen(!open); } },
                    React.createElement("span", { className: "sr-only" }, "View notifications"),
                    React.createElement(BellIcon, null),
                    notifications.length && !clearingNotifications ? (React.createElement("span", { className: "pt-0.25 absolute top-0 right-0 block h-4 w-4 rounded-circle bg-yellow-350 items-center justify-center text-gray-750 font-bold text-xxs -mt-1 -mr-1 " + (notifications.length >
                            99 && 'w-6') }, notifications.length > 99 ? '99+' : notifications.length)) : clearingNotifications ? (React.createElement("div", { className: "loader-yellow absolute ease-linear rounded-full border-4 border-t-4 border-gray-200 w-3 h-3 top-0 right-0" })) : (React.createElement(React.Fragment, null)))),
            React.createElement("div", { className: (!open &&
                    'hidden') + " w-max border origin-top-right absolute right-0 mt-2 min-w-48 rounded-md py-1 bg-white ring-1 ring-black ring-opacity-5 shadow", role: "menu", "aria-orientation": "vertical", "aria-labelledby": "user-menu" }, notifications.length === 0 ? (React.createElement("div", { className: "block px-4 py-2 text-sm font-normal text-gray-750 hover:bg-gray-100 cursor-pointer w-full" }, "No new notifications")) : (React.createElement(React.Fragment, null,
                getNotifications(),
                clearAllNotifications()))))));
};
export default connect(null, mapDispatchToProps)(NotificationDropdown);
