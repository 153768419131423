var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Switch, Route } from 'react-router';
import React, { lazy, Suspense } from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import LoadingState from './components/LoadingState';
import WithFrame from './routes/WithFrame';
import SuspenseErrorBoundary from './components/SuspenseErrorBoundary';
import history from './customHistory';
var retry = function (fn, retriesLeft, interval) {
    if (retriesLeft === void 0) { retriesLeft = 5; }
    if (interval === void 0) { interval = 1000; }
    return new Promise(function (resolve, reject) {
        fn()
            .then(resolve)
            .catch(function (error) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, new Promise(function (resolve) { return setTimeout(resolve, interval); })];
                    case 1:
                        _a.sent();
                        if (retriesLeft === 1) {
                            return [2 /*return*/, window.location.reload(true)];
                        }
                        retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                        return [2 /*return*/];
                }
            });
        }); }, interval);
    });
};
var Reports = lazy(function () { return retry(function () { return import('./routes/reports'); }); });
var RFP = lazy(function () { return retry(function () { return import('./routes/rfps'); }); });
var Foreclosure = lazy(function () { return retry(function () { return import('./routes/foreclosures'); }); });
var OrganizationSettings = lazy(function () {
    return retry(function () { return import('./routes/settings/OrganizationSettings'); });
});
var Typeform = lazy(function () { return retry(function () { return import('./routes/typeform'); }); });
var NewspaperTypeForm = lazy(function () {
    return retry(function () { return import('./routes/typeform/newspaper'); });
});
var RegisterOrganization = lazy(function () {
    return retry(function () { return import('./routes/register/organization'); });
});
var PostOrgRegistration = lazy(function () {
    return retry(function () { return import('./routes/register/organization/PostOrgRegistration'); });
});
var ConfirmOrganization = lazy(function () {
    return retry(function () { return import('./routes/invites/ConfirmOrganization'); });
});
var ColumnCards = lazy(function () { return retry(function () { return import('./routes/cards'); }); });
var Payments = lazy(function () { return retry(function () { return import('./routes/payments'); }); });
var EmailAction = lazy(function () {
    return retry(function () { return import('./routes/redirects/EmailAction'); });
});
var AddNoticeWrapper = lazy(function () { return retry(function () { return import('./routes/addNotice'); }); });
var UserNotices = lazy(function () { return retry(function () { return import('./routes/userNoticeList'); }); });
var Settings = lazy(function () { return retry(function () { return import('./routes/settings'); }); });
var Register = lazy(function () { return retry(function () { return import('./routes/register/user'); }); });
var Welcome = lazy(function () { return retry(function () { return import('./routes/register/welcome'); }); });
var Occupation = lazy(function () {
    return retry(function () { return import('./routes/register/occupations'); });
});
var Invitation = lazy(function () {
    return retry(function () { return import('./routes/invites/invitation'); });
});
var Login = lazy(function () { return retry(function () { return import('./routes/login'); }); });
var Logout = lazy(function () { return retry(function () { return import('./routes/logout'); }); });
var ErrorPage = lazy(function () { return retry(function () { return import('./routes/errors/ErrorPage'); }); });
var NewspaperSettings = lazy(function () {
    return retry(function () { return import('./routes/settings/NewspaperSettings'); });
});
var NoticeDetail = lazy(function () { return retry(function () { return import('./routes/notice'); }); });
var CreateInvoice = lazy(function () {
    return retry(function () { return import('./routes/notice/billing/CreateInvoice'); });
});
var CreateBulkInvoice = lazy(function () {
    return retry(function () { return import('./routes/notice/billing/CreateBulkInvoice'); });
});
var ReviewInvoice = lazy(function () {
    return retry(function () { return import('./routes/notice/billing/ReviewInvoice'); });
});
var EmailNeedsConfirm = lazy(function () {
    return retry(function () { return import('./routes/register/user/EmailNeedsConfirm'); });
});
var PayInvoice = lazy(function () {
    return retry(function () { return import('./routes/notice/billing/payInvoice/PayInvoice'); });
});
var PlaceScroll = lazy(function () { return import('./routes/placeScroll'); });
var Landing = lazy(function () { return import('./routes/landing'); });
var DonationCampaign = lazy(function () { return import('./routes/donationCampaign'); });
var ResetPassword = lazy(function () { return import('./routes/resetPassword/index'); });
var EnoticeRouter = function () {
    return (React.createElement(ConnectedRouter, { history: history },
        React.createElement(SuspenseErrorBoundary, null,
            React.createElement(Suspense, { fallback: React.createElement(LoadingState, null) },
                React.createElement(Switch, null,
                    React.createElement(WithFrame, { exact: true, path: "/rfps/:id/", hideFrame: true, component: RFP }),
                    React.createElement(WithFrame, { exact: true, path: "/foreclosures/:id/", hideFrame: true, component: Foreclosure }),
                    React.createElement(WithFrame, { exact: true, path: "/login/", component: Login }),
                    React.createElement(WithFrame, { exact: true, path: "/logout/", component: Logout }),
                    React.createElement(WithFrame, { exact: true, path: "/reports/", component: Reports }),
                    React.createElement(WithFrame, { exact: true, path: "/place/", noFramePadding: true, component: PlaceScroll }),
                    React.createElement(WithFrame, { exact: true, path: "/place/:id/", noFramePadding: true, component: PlaceScroll }),
                    React.createElement(WithFrame, { hideFrame: true, exact: true, path: "/register/", component: Register }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, hideFrame: true, path: "/register/welcome", component: Welcome }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, hideFrame: true, path: "/register/occupations", component: Occupation }),
                    React.createElement(WithFrame, { exact: true, path: "/email-action", component: EmailAction }),
                    React.createElement(WithFrame, { exact: true, path: "/register/organization/post-registration/", component: PostOrgRegistration }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/register/organization/", component: RegisterOrganization }),
                    React.createElement(WithFrame, { exact: true, path: "/register/confirm/", noFramePadding: true, component: EmailNeedsConfirm }),
                    React.createElement(WithFrame, { exact: true, path: "/temporary", component: NewspaperSettings }),
                    React.createElement(WithFrame, { exact: true, path: "/settings/", component: Settings }),
                    React.createElement(WithFrame, { exact: true, path: "/form/newspaper/:noticeId", component: NewspaperTypeForm }),
                    React.createElement(WithFrame, { exact: true, path: "/form/:noticeId/:noticeType", component: Typeform }),
                    React.createElement(WithFrame, { exact: true, path: "/file/by-type/:type", component: AddNoticeWrapper }),
                    React.createElement(WithFrame, { exact: true, path: "/file/:id/", component: AddNoticeWrapper }),
                    React.createElement(WithFrame, { exact: true, path: "/file/:id/:noticeSlug?", component: AddNoticeWrapper }),
                    React.createElement(WithFrame, { exact: true, path: "/notices/", component: UserNotices }),
                    React.createElement(WithFrame, { exact: true, path: "/settings/organization/", component: OrganizationSettings }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, path: "/:path(notice|publish)/:id/", component: NoticeDetail }),
                    React.createElement(WithFrame, { exact: true, noFramePadding: true, path: "/:path(notice|publish)/:id/invoice/create", component: CreateInvoice }),
                    React.createElement(WithFrame, { exact: true, path: "/:path(notice|publish)/:id/invoice/review", component: ReviewInvoice }),
                    React.createElement(WithFrame, { exact: true, path: "/:path(notice|publish)/:id/invoice/create-bulk", component: CreateBulkInvoice }),
                    React.createElement(WithFrame, { noFramePadding: true, exact: true, path: "/invites/confirm-organization/", component: ConfirmOrganization }),
                    React.createElement(WithFrame, { exact: true, path: "/cards/", noFramePadding: true, component: ColumnCards }),
                    React.createElement(WithFrame, { exact: true, path: "/payments/", noFramePadding: true, component: Payments }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/invites/:id", component: Invitation }),
                    React.createElement(WithFrame, { exact: true, path: "/error/:code", component: ErrorPage }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/landing", component: Landing }),
                    React.createElement(WithFrame, { exact: true, path: "/donation-campaign", component: DonationCampaign }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/invoices/:id/pay/", component: PayInvoice }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/cards/invoices/:id/pay/", component: PayInvoice }),
                    React.createElement(WithFrame, { exact: true, hideFrame: true, path: "/reset-password/", component: ResetPassword }),
                    React.createElement(Route, { exact: true, path: "/", render: function () { return React.createElement(LoadingState, null); } }))))));
};
var mapStateToProps = function (state) { return ({
    pathname: state.router.location.pathname
}); };
export default connect(mapStateToProps)(EnoticeRouter);
