import Enum from './enums';
import Occupation from './OccupationType';
import BillingStatus from './BillingStatusType';
import Timezones from './Timezones';
import DayEnum from './Day';
import FrequencyData from './Frequency';
import GovernmentTypeData from './GovernmentType';
import LawTypeData from './LawType';
import MailStatusData from './MailStatus';
import NoticeStatusData from './NoticeStatusType';
import NoticeTypeData from './NoticeType';
import NotificationTypeData from './NotificationType';
import OrganizationTypeData from './OrganizationType';
import RateTypeData from './RateType';
import RoleTypeData from './RoleType';
import StateData from './State';
import CountryData from './Country';
import CourtHouseData from './CourtHouse';
import XMLReplacementTagTypeData from './XMLReplacementTagType';
import ExportFormatTypeDate from './ExportFormatType';
import CustomAffidavitData from './CustomAffidavit';
import AdTypeData from './AdTypes';
import BankAccountVerificationData from './BankAccountVerification';
import InvoiceStatusData from './InvoiceStatusType';
import ELabelsData from './Labels';
import AnnouncementTypeData from './AnnouncementType';
import SubdomainStatusTypes from './SubdomainStatus';
import TransactionTypeData from './TransactionType';
import Currency from './Currency';
import DwollaTransferStatusData from './DwollaTransferStatus';
import DwollaCustomerStatusData from './DwollaCustomerStatus';
import InvoiceItemTypeData from './InvoiceItemType';
import PayoutStatusTypeData from './PayoutStatusType';
export var BillingStatusType = new Enum(BillingStatus);
export var TimezoneType = new Enum(Timezones);
export var AnnouncementType = new Enum(AnnouncementTypeData);
export var Day = new Enum(DayEnum);
export var Frequency = new Enum(FrequencyData);
export var GovernmentType = new Enum(GovernmentTypeData);
export var LawType = new Enum(LawTypeData);
export var MailStatus = new Enum(MailStatusData);
export var NoticeStatusType = new Enum(NoticeStatusData);
export var NoticeType = new Enum(NoticeTypeData);
export var NotificationType = new Enum(NotificationTypeData);
export var OccupationType = new Enum(Occupation);
export var OrganizationType = new Enum(OrganizationTypeData);
export var RateType = new Enum(RateTypeData);
export var RoleType = new Enum(RoleTypeData);
export var State = new Enum(StateData);
export var CourtHouse = CourtHouseData;
export var XMLReplacementTagType = new Enum(XMLReplacementTagTypeData);
export var ExportFormatType = new Enum(ExportFormatTypeDate);
export var CustomAffidavit = CustomAffidavitData;
export var AdType = new Enum(AdTypeData);
export var BankAccountVerification = new Enum(BankAccountVerificationData);
export var InvoiceStatus = new Enum(InvoiceStatusData);
export var TransactionType = new Enum(TransactionTypeData);
export var ELabels = new Enum(ELabelsData);
export var SubdomainStatus = new Enum(SubdomainStatusTypes);
export var CurrencyType = new Enum(Currency);
export var Country = new Enum(CountryData);
export var DwollaTransferStatus = new Enum(DwollaTransferStatusData);
export var DwollaCustomerStatus = new Enum(DwollaCustomerStatusData);
export var InvoiceItemType = new Enum(InvoiceItemTypeData);
export var PayoutStatusType = new Enum(PayoutStatusTypeData);
