import React from 'react';
import { CloseIcon } from 'icons';
var FreeformCModal = function (_a) {
    var setOpen = _a.setOpen, header = _a.header, body = _a.body, children = _a.children, id = _a.id, noExitOutsideModal = _a.noExitOutsideModal, afterExit = _a.afterExit, width = _a.width, customStyle = _a.customStyle, maxWidth = _a.maxWidth, disableOverflow = _a.disableOverflow;
    var handleExit = function () {
        if (noExitOutsideModal)
            return;
        setOpen && setOpen(false);
    };
    return (React.createElement("div", { id: id, className: "fixed z-100 inset-0 " + (!disableOverflow ? 'overflow-y-auto' : '') },
        React.createElement("div", { className: "flex items-center md:items-end justify-center min-h-screen pt-4 px-4 md:pb-20 text-center sm:block sm:p-0" },
            React.createElement("div", { className: "fixed inset-0 transition-opacity", onClick: handleExit },
                React.createElement("div", { className: "absolute inset-0 bg-black opacity-50" })),
            React.createElement("span", { className: "hidden sm:inline-block sm:align-middle sm:h-screen" }),
            "\u200B",
            React.createElement("div", { className: "inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle " + (maxWidth ||
                    'sm:max-w-lg') + " " + (!!width && width) + " sm:w-full sm:p-6", role: "dialog", "aria-modal": "true", "aria-labelledby": "modal-headline", id: id + "-inner" },
                setOpen && (React.createElement("div", { className: "absolute top-0 right-0 pt-5 md:pt-6 pr-4" },
                    React.createElement("button", { type: "button", onClick: function (_e) {
                            setOpen(false);
                            afterExit && afterExit();
                        }, className: "bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" },
                        React.createElement("span", { className: "sr-only" }, "Close"),
                        React.createElement(CloseIcon, null)))),
                React.createElement("div", { className: "sm:flex sm:items-start" },
                    React.createElement("div", { className: "sm:mt-0 sm:mx-4 text-left " + customStyle },
                        header && (React.createElement("h3", { className: "text-lg leading-6 font-medium text-gray-900", id: "modal-headline" }, header)),
                        React.createElement("div", { className: "mt-2" },
                            React.createElement("span", { className: "text-sm font-medium leading-5 text-gray-700" }, body)))),
                React.createElement("div", { className: "mt-3 sm:mt-0 sm:mx-4 text-left" }, children)))));
};
export default FreeformCModal;
