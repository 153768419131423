var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import tinycolor from 'tinycolor2';
var getColorObject = function (value, name) {
    var _a;
    var c = tinycolor(value);
    return _a = {},
        _a[name] = c.toHexString(),
        _a;
};
var multiplyColors = function (rgb1, rgb2) {
    return tinycolor("rgb " + Math.floor((rgb1.r * rgb2.r) / 255) + " " + Math.floor((rgb1.g * rgb2.g) / 255) + " " + Math.floor((rgb1.b * rgb2.b) / 255));
};
export var computeColors = function (hex, algorithm) {
    if (algorithm === void 0) { algorithm = ''; }
    var arr;
    var output = {};
    if (algorithm === 'constantin') {
        var baseLight = tinycolor('#ffffff');
        var baseDark = multiplyColors(tinycolor(hex).toRgb(), tinycolor(hex).toRgb());
        var baseTriad = tinycolor(hex).tetrad();
        arr = [
            getColorObject(tinycolor.mix(baseLight, hex, 12), '50'),
            getColorObject(tinycolor.mix(baseLight, hex, 30), '100'),
            getColorObject(tinycolor.mix(baseLight, hex, 50), '200'),
            getColorObject(tinycolor.mix(baseLight, hex, 70), '300'),
            getColorObject(tinycolor.mix(baseLight, hex, 85), '400'),
            getColorObject(tinycolor.mix(baseLight, hex, 100), '500'),
            getColorObject(tinycolor.mix(baseLight, hex, 100), 'main'),
            getColorObject(tinycolor.mix(baseDark, hex, 87), '600'),
            getColorObject(tinycolor.mix(baseDark, hex, 70), '700'),
            getColorObject(tinycolor.mix(baseDark, hex, 54), '800'),
            getColorObject(tinycolor.mix(baseDark, hex, 25), '900'),
            getColorObject(tinycolor
                .mix(baseDark, baseTriad[4], 15)
                .saturate(80)
                .lighten(65), 'A100'),
            getColorObject(tinycolor
                .mix(baseDark, baseTriad[4], 15)
                .saturate(80)
                .lighten(55), 'A200'),
            getColorObject(tinycolor
                .mix(baseDark, baseTriad[4], 15)
                .saturate(100)
                .lighten(45), 'A400'),
            getColorObject(tinycolor
                .mix(baseDark, baseTriad[4], 15)
                .saturate(100)
                .lighten(40), 'A700')
        ];
    }
    else {
        arr = [
            getColorObject(tinycolor(hex).lighten(52), '50'),
            getColorObject(tinycolor(hex).lighten(37), '100'),
            getColorObject(tinycolor(hex).lighten(26), '200'),
            getColorObject(tinycolor(hex).lighten(12), '300'),
            getColorObject(tinycolor(hex).lighten(6), '400'),
            getColorObject(tinycolor(hex), '500'),
            getColorObject(tinycolor(hex), 'main'),
            getColorObject(tinycolor(hex).darken(6), '600'),
            getColorObject(tinycolor(hex).darken(12), '700'),
            getColorObject(tinycolor(hex).darken(18), '800'),
            getColorObject(tinycolor(hex).darken(24), '900'),
            getColorObject(tinycolor(hex)
                .lighten(50)
                .saturate(30), 'A100'),
            getColorObject(tinycolor(hex)
                .lighten(30)
                .saturate(30), 'A200'),
            getColorObject(tinycolor(hex)
                .lighten(10)
                .saturate(15), 'A400'),
            getColorObject(tinycolor(hex)
                .lighten(5)
                .saturate(5), 'A700')
        ];
    }
    arr.forEach(function (entry) {
        var e_1, _a;
        try {
            for (var _b = __values(Object.entries(entry)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var _d = __read(_c.value, 2), key = _d[0], value = _d[1];
                output[key] = value;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    });
    return output;
};
export default computeColors;
