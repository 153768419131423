export default {
    publisher_id: {
        id: 'enotice-publisher-id',
        value: 'Order Number'
    },
    reference_id: {
        id: 'enotice-reference-id',
        value: 'Notice Name'
    }
};
