var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import { EChars } from '../constants';
import { EHandlebars } from '../headers_footers/shared';
var splitArrayIntoChunksOfNLength = function (array, n) {
    var groups = array
        .map(function (_, i) {
        return i % n === 0 ? array.slice(i, i + n) : [];
    })
        .filter(function (e) { return e; });
    return groups;
};
export var documentPagesFromImages = function (img_array, columns) {
    var columnsWideColumnsPerPageMap = {
        1: 3,
        2: 2,
        3: 1
    };
    var groups = splitArrayIntoChunksOfNLength(img_array, columnsWideColumnsPerPageMap[columns] || 1);
    return groups
        .map(function (group) {
        return {
            imgs: group,
            emptyFrames: groups[0].length - group.length
        };
    })
        .filter(function (group) { return group.imgs.length; });
};
var getColRelWidthsFrmPx = function (tableNode) {
    var e_1, _a;
    var table = tableNode.querySelector('tr');
    if (!table)
        return;
    var firstRowCells = table.children;
    var arr = Array.prototype.slice.call(firstRowCells);
    var totalWidth = arr.reduce(function (acc, node) {
        var width = node.getAttribute('computed-width');
        return acc + parseFloat(width);
    }, 0);
    var relWidths = arr.map(function (node, i) {
        var width = node.getAttribute('computed-width');
        return { i: i, val: (parseFloat(width) / totalWidth) * 100 };
    });
    relWidths.sort(function (a, b) { return a.val - b.val; });
    var MIN_WIDTH_PCT = 9;
    try {
        for (var relWidths_1 = __values(relWidths), relWidths_1_1 = relWidths_1.next(); !relWidths_1_1.done; relWidths_1_1 = relWidths_1.next()) {
            var w = relWidths_1_1.value;
            if (w.val < MIN_WIDTH_PCT) {
                var diff = MIN_WIDTH_PCT - w.val;
                w.val += diff;
                relWidths[relWidths.length - 1].val -= diff;
            }
            else
                break;
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (relWidths_1_1 && !relWidths_1_1.done && (_a = relWidths_1.return)) _a.call(relWidths_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return relWidths.sort(function (a, b) { return a.i - b.i; }).map(function (w) { return w.val; });
};
var htmlToIndesignCellAlignerMap = {
    left: 'LeftAlign',
    right: 'RightAlign',
    center: 'CenterAlign'
};
var addColGroupsToTables = function (fakeDOM) {
    var div = fakeDOM.body;
    var tables = div.querySelectorAll('table');
    var _loop_1 = function (i) {
        var ogTable = tables[i];
        var relWidths = getColRelWidthsFrmPx(ogTable);
        var colGroup = fakeDOM.createElement('colgroup');
        relWidths.forEach(function (widthPct) {
            var col = fakeDOM.createElement('col');
            col.setAttribute('width', widthPct + "%");
            colGroup.appendChild(col);
        });
        ogTable.insertBefore(colGroup, ogTable.firstChild);
        // add aligner divs inside cells
        var cells = ogTable.querySelectorAll('td');
        for (var cellIndex = 0; cellIndex < cells.length; cellIndex++) {
            var cell = cells[cellIndex];
            var alignment = cell.style['text-align'];
            var currentStyleTag = cell.getAttribute('style');
            if (currentStyleTag) {
                cell.setAttribute('style', currentStyleTag.replace(/text-align: (\w*);/g, ''));
            }
            var idCellStyle = htmlToIndesignCellAlignerMap[alignment] ||
                htmlToIndesignCellAlignerMap.left;
            cell.innerHTML = "<div custom-style=" + idCellStyle + "> " + cell.innerHTML + " </div>";
        }
    };
    for (var i = 0; i < tables.length; i++) {
        _loop_1(i);
    }
    return fakeDOM;
};
var addHeadingStyle = function (fakeDOM) {
    var firstParagraph = fakeDOM.body.querySelector('p');
    if (firstParagraph) {
        firstParagraph.innerHTML = "<span custom-style=\"Heading\">" + firstParagraph.textContent + "</span>";
    }
};
export var headers = {
    addXML: function (str) {
        return "<?xml version=\"1.0\" encoding=\"UTF-8\"?><dynamic-header xmlns:aid=\"http://ns.adobe.com/AdobeInDesign/4.0/\" xmlns:aid5=\"http://ns.adobe.com/AdobeInDesign/5.0/\">" + str + "</dynamic-header>";
    },
    removeXML: function (str) {
        var match = str.match(new RegExp(/<dynamic-header.*?>(.+)<\/dynamic-header>/));
        return Array.isArray(match) ? match[1] : '';
    }
};
export var MCEChars = {
    tab: '<span class="mce-nbsp-wrap" contenteditable="false">&nbsp;&nbsp;&nbsp;</span>'
};
var NEWSPAPERS_WITH_HYPHENATION_SET = [
    'WC7k2p3rmrcJuges3N3i',
    'Mx5Fm2sRUEXmG6otInoc'
];
export var shouldPreserveLongSequencesForNewspaper = function (newspaper) {
    return Boolean(1 || NEWSPAPERS_WITH_HYPHENATION_SET.includes(newspaper === null || newspaper === void 0 ? void 0 : newspaper.id));
};
export var shouldPreserveLongSequencesForNotice = function (notice) {
    var _a, _b;
    return Boolean(1 ||
        NEWSPAPERS_WITH_HYPHENATION_SET.includes(((_b = (_a = notice === null || notice === void 0 ? void 0 : notice.data()) === null || _a === void 0 ? void 0 : _a.newspaper) === null || _b === void 0 ? void 0 : _b.id) || ''));
};
/**
 * @param {string} html
 * @param {object} DOMParser
 * @param {object} options
 */
export var htmlToIndesignHtml = function (html, DOMparser, _a, template) {
    var isFirstPHeading = _a.isFirstPHeading, preserveLongSequences = _a.preserveLongSequences;
    var fakeDOM = new DOMparser().parseFromString(html, 'text/html');
    addColGroupsToTables(fakeDOM);
    if (isFirstPHeading)
        addHeadingStyle(fakeDOM);
    var transformed = fakeDOM.body.innerHTML;
    var replaced = transformed
        .replace(/data-custom-style/g, 'custom-style')
        /*
          handle both alignment attributes of the form
          style="text-align: center;" and style="text-align:center"
          both are valid CSS, and different packages we rely on generate the two types
        */
        .replace(/(?:margin-left|margin-right):[^;]*;/gi, '')
        .replace(/style="\s?text-align:\s?(\w*)\s?;?"/g, 'custom-style="align-$1"')
        .replace(/<\/p>/g, '</div>')
        .replace(/<p>/g, '<div>')
        .replace(/<p /g, '<div ')
        .replace(/text-decoration:underline/g, 'text-decoration: underline')
        .replace(/<span><strong>(.|\n)*?<\/strong>.{0,1}<\/span>/g, function (res) {
        var withoutStartOrEndTags = res
            .replace('<span><strong>', '')
            .replace('</strong>', '')
            .replace('</span>', '');
        return "<strong><span>" + withoutStartOrEndTags + "</span></strong>";
    })
        .replace(/<br>/g, '<div><br></div>')
        .replace(/_{10,}/g, function (match) {
        var str = "<span custom-style=\"Underline\">";
        for (var i = 0; i < match.length; i++) {
            str += '&nbsp;';
        }
        str += '</span>';
        return str;
    })
        .replace(
    // eslint-disable-next-line no-useless-escape
    /[\w:\/\.,=?]{20,}/g, function (match) {
        if (preserveLongSequences) {
            return match;
        }
        return match.slice(0, 16) + " " + match.slice(16);
    })
        .replace(/<span style="text-decoration:\s?underline;?" data-mce-style="text-decoration:\s?underline;?">/g, '<span custom-style="Underline">')
        .replace(/<u>/g, '<span custom-style="Underline">')
        .replace(new RegExp(MCEChars.tab, 'g'), EChars.tab)
        // eslint-disable-next-line no-useless-escape
        .replace(/<\/u>/g, '</span>')
        .replace(/<figure>.*?<\/figure>/g, EChars.tab);
    var compiled = EHandlebars.compile(replaced);
    var templated = compiled(template);
    return templated;
};
export var displayParamsFromNoticeAndPageParams = function (crop, pageParams, selectedColumns) {
    var minColumns = 1;
    var columns = selectedColumns
        ? Math.max(minColumns, selectedColumns)
        : minColumns;
    var scaledWidth = pageParams.columnWidth * columns + (columns - 1) * pageParams.columnGutter;
    var scaledHeight = (scaledWidth / crop.absWidth) * crop.absHeight +
        pageParams.headerHeight +
        pageParams.footerHeight;
    return {
        height: scaledHeight,
        width: scaledWidth,
        area: scaledWidth * scaledHeight,
        columns: selectedColumns || minColumns,
        minColumns: minColumns
    };
};
