var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createReducer, createActions } from 'reduxsauce';
import { ACTIVE_NOTICES_TAB } from 'routes/userNoticeList/table/types';
export var SHOW_CURRENT_USERS_NOTICES = 'my-notices';
export var SHOW_ALL_NOTICES = 'all-notices';
/* ------------- Types and Action Creators ------------- */
var _b = createActions({
    updateNotices: [],
    setSort: ['sort'],
    setTab: ['tab'],
    setCurrentPage: ['currentPage'],
    setNotices: ['notices'],
    setTotal: ['total'],
    setShowUserOnlyNotices: ['showUserOnlyNotices'],
    setFetching: ['fetching'],
    setSearch: ['search'],
    setPageSize: ['pageSize']
}), Types = _b.Types, Creators = _b.Creators;
export var NoticeTypes = Types;
export default Creators;
var INITIAL_STATE = {
    tab: ACTIVE_NOTICES_TAB,
    sort: { direction: null, field: null },
    currentPage: 0,
    notices: null,
    showUserOnlyNotices: false,
    fetching: true,
    search: '',
    total: 0,
    pageSize: 5
};
export var noticesSelector = function (state) { return state.notices; };
/* ------------- Reducer ------------- */
export var reducer = createReducer(INITIAL_STATE, (_a = {},
    _a[Types.SET_SEARCH] = function (state, _a) {
        var search = _a.search;
        return (__assign(__assign({}, state), { search: search }));
    },
    _a[Types.SET_TAB] = function (state, _a) {
        var tab = _a.tab;
        return (__assign(__assign({}, state), { tab: tab }));
    },
    _a[Types.SET_SORT] = function (state, _a) {
        var sort = _a.sort;
        return (__assign(__assign({}, state), { sort: sort }));
    },
    _a[Types.SET_CURRENT_PAGE] = function (state, _a) {
        var currentPage = _a.currentPage;
        return (__assign(__assign({}, state), { currentPage: currentPage }));
    },
    _a[Types.SET_NOTICES] = function (state, _a) {
        var notices = _a.notices;
        return (__assign(__assign({}, state), { notices: notices }));
    },
    _a[Types.SET_SHOW_USER_ONLY_NOTICES] = function (state, _a) {
        var showUserOnlyNotices = _a.showUserOnlyNotices;
        return (__assign(__assign({}, state), { showUserOnlyNotices: showUserOnlyNotices }));
    },
    _a[Types.SET_FETCHING] = function (state, _a) {
        var fetching = _a.fetching;
        return (__assign(__assign({}, state), { fetching: fetching }));
    },
    _a[Types.SET_TOTAL] = function (state, _a) {
        var total = _a.total;
        return (__assign(__assign({}, state), { total: total }));
    },
    _a[Types.SET_PAGE_SIZE] = function (state, _a) {
        var pageSize = _a.pageSize;
        return (__assign(__assign({}, state), { pageSize: pageSize }));
    },
    _a));
