var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Firebase from 'EnoticeFirebase';
import { store } from '../index';
var Api = /** @class */ (function () {
    function Api() {
    }
    Api.prototype.getRestUrl = function (path) {
        return Firebase.functions()._url("api/" + path);
    };
    /**
     * When calling our API we send a Firebase ID token which has a 1-hour lifetime.
     * The Firebase Auth SDK automatically refreshes tokens when they're within 5m of
     * expiring and makes sure tokens returned from getIdTokenI() have at least 30s
     * remaining.
     *
     * However if the client and server have significant clock skew then the Firebase
     * Auth SDK can fail to detect that the token is actually expired and the server
     * will reject it.
     */
    Api.prototype.checkForClockSkew = function (resp) {
        var _a;
        var dateHeader = (_a = resp === null || resp === void 0 ? void 0 : resp.headers) === null || _a === void 0 ? void 0 : _a.get('date');
        if (!dateHeader) {
            return;
        }
        var serverTime = new Date(dateHeader).getTime();
        if (isNaN(serverTime)) {
            return;
        }
        var clientTime = new Date().getTime();
        var diff = clientTime - serverTime;
        if (Math.abs(diff) > 60000) {
            console.error("Client time and server time differ by more than 1m (" + diff + "ms)");
        }
    };
    Api.prototype.getToken = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userAuth, idTokenRes, expirationDate, timeToExpiry;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userAuth = store.getState().auth.userAuth;
                        if (!userAuth)
                            throw new Error('No userauth set');
                        return [4 /*yield*/, userAuth.getIdTokenResult()];
                    case 1:
                        idTokenRes = _a.sent();
                        expirationDate = new Date(idTokenRes.expirationTime);
                        timeToExpiry = expirationDate.getTime() - new Date().getTime();
                        if (!(timeToExpiry < 5 * 60 * 1000)) return [3 /*break*/, 3];
                        return [4 /*yield*/, userAuth.getIdToken(true)];
                    case 2: return [2 /*return*/, _a.sent()];
                    case 3: return [2 /*return*/, idTokenRes.token];
                }
            });
        });
    };
    Api.prototype.get = function (path, params) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, token, err_1, apiUrl, resp, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        headers = {};
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getToken()];
                    case 2:
                        token = _c.sent();
                        headers.Authorization = "Bearer " + token;
                        return [3 /*break*/, 4];
                    case 3:
                        err_1 = _c.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        apiUrl = this.getRestUrl(path);
                        if (params) {
                            apiUrl += "?" + Object.keys(params)
                                .map(function (k) { return k + "=" + String(params[k]); })
                                .join('&');
                        }
                        return [4 /*yield*/, fetch(apiUrl, {
                                headers: headers
                            })];
                    case 5:
                        resp = _c.sent();
                        this.checkForClockSkew(resp);
                        if (!(resp.status !== 200)) return [3 /*break*/, 7];
                        _a = Error.bind;
                        _b = resp.status + " error: ";
                        return [4 /*yield*/, resp.text()];
                    case 6: throw new (_a.apply(Error, [void 0, _b + (_c.sent())]))();
                    case 7: return [4 /*yield*/, resp.json()];
                    case 8: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    Api.prototype.post = function (path, data) {
        return __awaiter(this, void 0, void 0, function () {
            var headers, token, err_2, url, resp, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        headers = {
                            'Content-Type': 'application/json'
                        };
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.getToken()];
                    case 2:
                        token = _c.sent();
                        headers.Authorization = "Bearer " + token;
                        return [3 /*break*/, 4];
                    case 3:
                        err_2 = _c.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        url = this.getRestUrl(path);
                        return [4 /*yield*/, fetch(url, {
                                method: 'POST',
                                body: JSON.stringify(data || {}),
                                headers: headers
                            })];
                    case 5:
                        resp = _c.sent();
                        this.checkForClockSkew(resp);
                        if (!(resp.status !== 200)) return [3 /*break*/, 7];
                        _a = Error.bind;
                        _b = resp.status + " error: ";
                        return [4 /*yield*/, resp.text()];
                    case 6: throw new (_a.apply(Error, [void 0, _b + (_c.sent())]))();
                    case 7: return [4 /*yield*/, resp.json()];
                    case 8: return [2 /*return*/, _c.sent()];
                }
            });
        });
    };
    return Api;
}());
export default new Api();
