var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { State, OrganizationType, NoticeType } from '../enums';
import Country from '../enums/Country';
export var shouldShowPaperRegardlessOfUploadReadiness = function (org) { var _a; return (_a = org.data()) === null || _a === void 0 ? void 0 : _a.alwaysShowInPlacementFlow; };
export var publisherReadyToUpload = function (org) {
    return !org.data().disabled &&
        org.data().name &&
        org.data().address &&
        org.data().phone &&
        org.data().city &&
        org.data().county &&
        org.data().state &&
        org.data().adTemplate &&
        org.data().deadlines &&
        (org.data().affidavitDisabled ||
            (org.data().signatoryName &&
                org.data().signatoryRole &&
                org.data().publicationFrequency));
};
export var paperIsValid = function (org) {
    return publisherReadyToUpload(org) ||
        shouldShowPaperRegardlessOfUploadReadiness(org);
};
export var getNoticeTypeData = function (noticeType, org) {
    var _a;
    var selectedNoticeType = ((_a = org
        .data()
        .allowedNotices) === null || _a === void 0 ? void 0 : _a.find(function (allowedNotice) { return allowedNotice.value === parseInt(noticeType, 10); })) || NoticeType.by_value(noticeType);
    if (!selectedNoticeType) {
        console.error("Unable to find typeform value " + noticeType + " for organization " + org.id);
        return NoticeType.custom;
    }
    return selectedNoticeType;
};
export var getNoticeTypeStr = function (noticeType, org) {
    if (noticeType)
        return getNoticeTypeData(noticeType, org).label;
    return 'Public';
};
export var getNoticeTypeDataFromNotice = function (notice) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaper;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, notice.data().newspaper.get()];
            case 1:
                newspaper = _a.sent();
                return [2 /*return*/, getNoticeTypeData(notice.data().noticeType, newspaper)];
        }
    });
}); };
export var isBattlebornSync = function (org, parent) {
    if (parent === void 0) { parent = null; }
    var _a;
    var name = org.data().name;
    if (name.toLowerCase().includes('sparks') ||
        name.toLowerCase().includes('mineral county') ||
        name.toLowerCase().includes('mesquite local') ||
        name.toLowerCase().includes('eureka sentinel') ||
        name.toLowerCase().includes('ely'))
        return true;
    if ((_a = parent === null || parent === void 0 ? void 0 : parent.data()) === null || _a === void 0 ? void 0 : _a.name.toLowerCase().includes('battle born')) {
        return true;
    }
    return false;
};
// Organization matchers
export var isBattleborn = function (org) { return __awaiter(void 0, void 0, void 0, function () {
    var parent, p;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!org)
                    return [2 /*return*/];
                parent = org.data().parent;
                if (!parent) return [3 /*break*/, 2];
                return [4 /*yield*/, parent.get()];
            case 1:
                p = (_a.sent());
                _a.label = 2;
            case 2: return [2 /*return*/, isBattlebornSync(org, p)];
        }
    });
}); };
export var isMontroseOrDelta = function (org) {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport &&
        (((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_MONTROSE' ||
            ((_b = org.data().xmlExport) === null || _b === void 0 ? void 0 : _b.format) === 'VD_DELTA'));
};
export var isGreenValleyOrSahuarita = function (org) {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport &&
        (((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_GREEN_VALLEY' ||
            ((_b = org.data().xmlExport) === null || _b === void 0 ? void 0 : _b.format) === 'VD_SAHUARITA'));
};
export var isEasternArizonaOrCopperEra = function (org) {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport &&
        (((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_EASTERN_ARIZONA' ||
            ((_b = org.data().xmlExport) === null || _b === void 0 ? void 0 : _b.format) === 'VD_COPPER_ERA'));
};
export var isNogales = function (org) {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_NOGALES';
};
export var isSierraVista = function (org) {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_HERALD';
};
export var isDailyTerritorial = function (org) {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport &&
        ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_DAILY_TERRITORIAL');
};
export var isNogalesOrDailyTerritorial = function (org) {
    if (!org)
        return;
    return isNogales(org) || isDailyTerritorial(org);
};
export var isSantaMaria = function (org) {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_SANTA_MARIA');
};
export var isLompocRecord = function (org) {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_LOMPOC';
};
export var isSantaYnez = function (org) {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_SANTA_YNEZ');
};
export var isSantaMariaGroup = function (org) {
    if (!org)
        return;
    return isSantaMaria(org) || isSantaYnez(org) || isLompocRecord(org);
};
export var isMatSU = function (org) {
    var _a;
    if (!org)
        return;
    return org.data().xmlExport && ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_MAT_SU';
};
export var isAnchoragePress = function (org) {
    var _a;
    if (!org)
        return;
    return (org.data().xmlExport &&
        ((_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === 'VD_ANCHORAGE_PRESS');
};
export var isWick = function (org) {
    if (!org)
        return;
    return (isMontroseOrDelta(org) ||
        isGreenValleyOrSahuarita(org) ||
        isEasternArizonaOrCopperEra(org) ||
        isNogalesOrDailyTerritorial(org) ||
        isSierraVista(org) ||
        isMatSU(org) ||
        isAnchoragePress(org));
};
export var isOrganizationSetForRPA = function (org) {
    var _a, _b;
    if (!org)
        return;
    return (org.data().xmlExport && ((_b = (_a = org.data().xmlExport) === null || _a === void 0 ? void 0 : _a.format) === null || _b === void 0 ? void 0 : _b.startsWith('VD_')));
};
// Orgs that only run donations
export var isDonationOrg = function (org) {
    var _a, _b;
    return org &&
        ((_a = org.data()) === null || _a === void 0 ? void 0 : _a.organizationType) === OrganizationType.newspaper.value &&
        State.by_value((_b = org.data()) === null || _b === void 0 ? void 0 : _b.state).run_donations_by_default;
};
// Orgs that can run donations and public notices
export var canRunDonations = function (org) {
    var _a, _b;
    return org &&
        ((_a = org.data()) === null || _a === void 0 ? void 0 : _a.organizationType) === OrganizationType.newspaper.value &&
        State.by_value((_b = org.data()) === null || _b === void 0 ? void 0 : _b.state).can_run_donations;
};
export var getMemoText = function (customMemo, referenceId, publisherId, isReceipt, country) {
    if (customMemo === void 0) { customMemo = ''; }
    var customHeading = '=== Notes ===';
    var boilerplateHeading = '=== How to pay this invoice ===';
    var noticeName = referenceId ? "Notice Name: " + referenceId + "\n" : '';
    var publisher = publisherId ? "Order Number: " + publisherId + "\n" : '';
    var customIds = "" + noticeName + publisher;
    var check = country === Country.GBR.value ? 'cheque' : 'check';
    var boilerplate = "We accept ACH bank transfers, debit/ credit cards, and " + check + "s. If you would like to pay by " + check + ", please make it out to ";
    var memo = '';
    memo += customHeading + "\n" + customMemo + "\n" + customIds + "\n";
    if (!isReceipt)
        memo += boilerplateHeading + "\n" + boilerplate;
    return memo;
};
