var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
var Tooltip = function (_a) {
    var step = _a.step, index = _a.index, primaryProps = _a.primaryProps, tooltipProps = _a.tooltipProps, backProps = _a.backProps;
    return (React.createElement("div", __assign({}, tooltipProps, { className: "relative flex flex-col content-end justify-end items-end bg-white w-full rounded-lg px-8 py-5 overflow-hidden shadow-xl transform transition-all max-w-2xl" }),
        step.title && (React.createElement("div", { className: "w-full font-semibold text-lg text-gray-900 mb-4 flex justify-between" }, step.title)),
        React.createElement("div", { className: "font-normal text-sm text-gray-700 mr-4" }, step.content),
        React.createElement("div", { className: "flex justify-center aligned-center mt-4" },
            index > 0 && (React.createElement("button", __assign({ type: "button", className: "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-blue-900 hover:text-blue-800 bg-transparent transition duration-150 ease-in-out" }, backProps), "Previous")),
            React.createElement("button", __assign({ type: "button", className: "hover:bg-blue-800 inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-900 transition duration-150 ease-in-out" }, primaryProps), "Next"))));
};
export default Tooltip;
