var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import moment from 'moment';
export var timeStringToDate = function (timeString) {
    var date = new Date(Date.now());
    var h = timeString.split(':')[0];
    var m = timeString.split(':')[1];
    date.setHours(parseInt(h, 10), parseInt(m, 10));
    return date;
};
export var getDeadlineOverrideKeyFromDate = function (date) {
    return date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear();
};
export var deadlineOverrideToDeadlineOverrideDate = function (deadlineOverrideKey, deadline) {
    var _a = __read(deadlineOverrideKey.split('/'), 3), month = _a[0], day = _a[1], year = _a[2];
    var publishingDate = new Date(Number(year), Number(month), Number(day));
    var savedTime = timeStringToDate(deadline.deadline.time);
    var deadlineMoment = moment(publishingDate)
        .set('day', deadline.deadline.dayEnum - 1)
        .set('hour', savedTime.getHours())
        .set('minute', savedTime.getMinutes());
    if (deadlineMoment.isAfter(publishingDate)) {
        deadlineMoment = deadlineMoment.subtract(1, 'week');
    }
    if (deadline.weeks) {
        deadlineMoment = deadlineMoment.subtract(deadline.weeks, 'week');
    }
    return deadlineMoment.toDate();
};
