var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import { ENV, PROD, DEMO } from '../constants';
var SuspenseErrorBoundary = /** @class */ (function (_super) {
    __extends(SuspenseErrorBoundary, _super);
    function SuspenseErrorBoundary(props) {
        var _this = _super.call(this, props) || this;
        _this.state = {
            hasError: false
        };
        return _this;
    }
    SuspenseErrorBoundary.getDerivedStateFromError = function (error) {
        if (ENV === DEMO || ENV === PROD) {
            Sentry.captureException(error);
        }
        return {
            hasError: true
        };
    };
    SuspenseErrorBoundary.prototype.render = function () {
        if (this.props.error || this.state.hasError) {
            return (React.createElement(Grid, { container: true, style: {
                    width: '100vw',
                    height: '100vh',
                    alignItems: 'center',
                    justifyContent: 'center'
                } },
                React.createElement(Grid, null,
                    React.createElement(Typography, { variant: "h5" }, "Whoops! We've encountered an error"),
                    React.createElement(Typography, null, "Please refresh the page. If that doesn't help, email help@column.us and we will assist you!"))));
        }
        return this.props.children;
    };
    return SuspenseErrorBoundary;
}(React.Component));
var mapStateToProps = function (state) { return ({
    error: state.errors.error
}); };
export default connect(mapStateToProps)(SuspenseErrorBoundary);
