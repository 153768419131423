export default {
    pending: {
        value: 1,
        label: 'Pending',
        key: 'pending'
    },
    live: {
        value: 2,
        label: 'Live',
        key: 'live'
    }
};
