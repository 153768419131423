var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import { getSubdomain } from 'utils/urls';
import { State } from 'lib/enums';
import { setupBeacon } from 'utils/beacon';
import { ENV, PROD, DEMO, DEV, LOGROCKET_KEY, SENTRY_DSN, PENDO_KEY, SHOULD_RUN_PENDO, IS_LOCALHOST, RECORD } from '../constants';
export default function root() {
    var sanitizeLogRocketRequests, shouldDisplayChat;
    return __generator(this, function (_a) {
        if (ENV === PROD || ENV === DEMO) {
            Sentry.init({
                dsn: SENTRY_DSN,
                environment: ENV
            });
        }
        sanitizeLogRocketRequests = function (request) {
            if (request.url.toLowerCase().indexOf('register') !== -1) {
                request.body = null;
            }
            return request;
        };
        if (ENV === PROD ||
            ENV === DEMO ||
            (ENV === DEV && !IS_LOCALHOST && RECORD)) {
            LogRocket.init(LOGROCKET_KEY, {
                network: {
                    requestSanitizer: sanitizeLogRocketRequests
                }
            });
            try {
                LogRocket.getSessionURL(function (sessionURL) {
                    var _a;
                    Sentry.configureScope(function (scope) {
                        scope.setExtra('sessionURL', sessionURL);
                    });
                    (_a = window.pendo) === null || _a === void 0 ? void 0 : _a.track('Session Replay Created', {
                        sessionURL: sessionURL
                    });
                });
            }
            catch (e) {
                console.error('Setup: Unable to link LogRocket session to Sentry', e);
            }
        }
        if (SHOULD_RUN_PENDO) {
            try {
                (function (apiKey) {
                    (function (p, e, n, d, o) {
                        var v;
                        var w;
                        var x;
                        var y;
                        var z;
                        // eslint-disable-next-line
                        o = p[d] = p[d] || {};
                        // eslint-disable-next-line
                        o._q = o._q || [];
                        // eslint-disable-next-line
                        v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
                        for (w = 0, x = v.length; w < x; ++w)
                            (function (m) {
                                // eslint-disable-next-line
                                o[m] =
                                    o[m] ||
                                        function () {
                                            o._q[m === v[0] ? 'unshift' : 'push'](
                                            // eslint-disable-next-line
                                            [m].concat([].slice.call(arguments, 0)));
                                        };
                            })(v[w]);
                        // eslint-disable-next-line
                        y = e.createElement(n);
                        // eslint-disable-next-line
                        y.async = !0;
                        y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js";
                        // eslint-disable-next-line
                        z = e.getElementsByTagName(n)[0];
                        z.parentNode.insertBefore(y, z);
                    })(window, document, 'script', 'pendo', {});
                })(PENDO_KEY);
            }
            catch (err) {
                console.log("Failed to load pendo: " + err);
            }
        }
        shouldDisplayChat = ENV === PROD || ENV === DEMO;
        if (shouldDisplayChat) {
            if (window.location.href.indexOf('/search') === -1 &&
                !State[getSubdomain()]) {
                (function () {
                    setupBeacon();
                })();
            }
        }
        return [2 /*return*/, true];
    });
}
