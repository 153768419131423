export default {
    sunday: {
        value: 1,
        label: 'Sunday',
        key: 'sunday'
    },
    monday: {
        value: 2,
        label: 'Monday',
        key: 'monday'
    },
    tuesday: {
        value: 3,
        label: 'Tuesday',
        key: 'tuesday'
    },
    wednesday: {
        value: 4,
        label: 'Wednesday',
        key: 'wednesday'
    },
    thursday: {
        value: 5,
        label: 'Thursday',
        key: 'thursday'
    },
    friday: {
        value: 6,
        label: 'Friday',
        key: 'friday'
    },
    saturday: {
        value: 7,
        label: 'Saturday',
        key: 'saturday'
    }
};
