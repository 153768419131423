var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
// external
import { applyMiddleware, compose, createStore } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
// internal
import makeRootReducer from './reducers';
import IndexSaga from '../sagas';
import history from '../customHistory';
export default (function (initialState) {
    if (initialState === void 0) { initialState = {}; }
    // ======================================================
    // Middleware Configuration
    // ======================================================
    var sagaMiddleware = createSagaMiddleware();
    var middleware = [routerMiddleware(history), thunk, sagaMiddleware];
    // ======================================================
    // Store Enhancers
    // ======================================================
    var enhancers = [];
    var composeEnhancers = compose;
    if (process.env.NODE_ENV === 'development') {
        var composeWithDevToolsExtension = window
            .__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        if (typeof composeWithDevToolsExtension === 'function') {
            composeEnhancers = composeWithDevToolsExtension;
        }
    }
    // ======================================================
    // Store Instantiation and HMR Setup
    // ======================================================
    var store = createStore(makeRootReducer(history), initialState, composeEnhancers.apply(void 0, __spread([applyMiddleware.apply(void 0, __spread(middleware))], enhancers)));
    store.asyncReducers = {};
    // run our index saga
    sagaMiddleware.run(IndexSaga);
    if (module.hot) {
        module.hot.accept('./reducers', function () {
            var reducers = require('./reducers').default;
            store.replaceReducer(reducers(store.asyncReducers));
            sagaMiddleware.run(IndexSaga);
        });
    }
    return store;
});
