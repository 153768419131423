var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, take, select, put, takeLatest } from 'redux-saga/effects';
import { routerSelector } from 'redux/router';
import { authSelector, AuthTypes } from 'redux/auth';
import { push, LOCATION_CHANGE, replace } from 'connected-react-router';
import { OrganizationType, OccupationType, State } from 'lib/enums';
import pathToRegex from 'path-to-regexp';
import { organizationIsSetup } from 'routes/redirects';
import { includeSearchParams, getSubdomain, getHostname } from 'utils/urls';
import { PUBLISHER_CAN_REGISTER } from '../constants';
function getLandingPage() {
    var auth, user, userAuth, organization, _a, userData, activeOrg, _b, subdomain, hostname;
    var _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                auth = _f.sent();
                user = auth.user;
                userAuth = auth.userAuth;
                if (!(userAuth && !user)) return [3 /*break*/, 3];
                return [4 /*yield*/, take(AuthTypes.SET_USER)];
            case 2:
                user = (_f.sent()).user;
                _f.label = 3;
            case 3:
                _a = user &&
                    user.data() &&
                    user.data().organization;
                if (!_a) return [3 /*break*/, 5];
                return [4 /*yield*/, call([user.data().organization, user.data().organization.get])];
            case 4:
                _a = (_f.sent());
                _f.label = 5;
            case 5:
                organization = _a;
                if (!user) return [3 /*break*/, 8];
                if (((_c = organization === null || organization === void 0 ? void 0 : organization.data()) === null || _c === void 0 ? void 0 : _c.organizationType) ===
                    OrganizationType.newspaper.value) {
                    if (State.by_value(organization.data().state).run_donations_by_default)
                        return [2 /*return*/, '/donation-campaign/'];
                }
                userData = user.data();
                _b = (userData === null || userData === void 0 ? void 0 : userData.activeOrganization);
                if (!_b) return [3 /*break*/, 7];
                return [4 /*yield*/, call([
                        userData.activeOrganization,
                        userData.activeOrganization.get
                    ])];
            case 6:
                _b = (_f.sent());
                _f.label = 7;
            case 7:
                activeOrg = _b;
                return [2 /*return*/, organization &&
                        ((_d = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.data()) === null || _d === void 0 ? void 0 : _d.organizationType) ===
                            OrganizationType.holding_company.value && ((_e = activeOrg === null || activeOrg === void 0 ? void 0 : activeOrg.data()) === null || _e === void 0 ? void 0 : _e.reportUrl)
                        ? '/reports/'
                        : '/notices/'];
            case 8:
                subdomain = getSubdomain();
                if (['florida'].indexOf(subdomain) !== -1) {
                    return [2 /*return*/, '/landing/'];
                }
                if (State[subdomain]) {
                    return [2 /*return*/, '/search/'];
                }
                if (['wapo-production'].indexOf(subdomain) !== -1) {
                    return [2 /*return*/, '/search/'];
                }
                hostname = getHostname();
                if (['publicnoticecolorado', 'washingtonpost'].indexOf(hostname) !== -1)
                    return [2 /*return*/, '/search/'];
                if (['localhost:3000', 'app', 'www', 'demo', 'staging'].includes(subdomain))
                    return [2 /*return*/, '/landing/'];
                return [2 /*return*/, '/login/'];
        }
    });
}
export function getRedirect() {
    var r;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, select(routerSelector)];
            case 1:
                r = _a.sent();
                return [2 /*return*/, decodeURIComponent(r.location.query.redirect || '/')];
        }
    });
}
function checkNeedToRedirect() {
    var user, location, userData, invite, _a, redirectUrl, needsToCompleteOrgSetupStepOne, activeOrg, _b, alreadyRedirected;
    var _c;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                user = (_d.sent()).user;
                return [4 /*yield*/, select(routerSelector)];
            case 2:
                location = (_d.sent()).location;
                if (!user)
                    return [2 /*return*/, false];
                if (location.pathname.includes('/landing'))
                    return [2 /*return*/, false];
                userData = user.data();
                _a = (userData === null || userData === void 0 ? void 0 : userData.invite);
                if (!_a) return [3 /*break*/, 4];
                return [4 /*yield*/, call([userData.invite, userData.invite.get])];
            case 3:
                _a = (_d.sent());
                _d.label = 4;
            case 4:
                invite = _a;
                redirectUrl = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('placementRedirectUrl');
                if (!(userData &&
                    redirectUrl &&
                    location.pathname.includes('/register/welcome'))) return [3 /*break*/, 6];
                return [4 /*yield*/, put(push('/register/occupations'))];
            case 5:
                _d.sent();
                return [2 /*return*/, true];
            case 6:
                if (!((userData === null || userData === void 0 ? void 0 : userData.occupation) === OccupationType.individual.value &&
                    !userData.postRegistrationComplete &&
                    !location.pathname.includes('/register/organization') &&
                    !userData.state)) return [3 /*break*/, 8];
                if (location.pathname.includes('/register/'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push('/register/organization'))];
            case 7:
                _d.sent();
                return [2 /*return*/, true];
            case 8:
                if (!((userData === null || userData === void 0 ? void 0 : userData.occupation) &&
                    (location.pathname === '/register/' || location.pathname === '/register'))) return [3 /*break*/, 10];
                return [4 /*yield*/, put(push('/register/organization'))];
            case 9:
                _d.sent();
                return [2 /*return*/, true];
            case 10:
                if (!(userData &&
                    !userData.occupation &&
                    (location.pathname === '/register/' || location.pathname === '/register'))) return [3 /*break*/, 12];
                return [4 /*yield*/, put(push('/register/welcome'))];
            case 11:
                _d.sent();
                return [2 /*return*/, true];
            case 12:
                if (!userData.emailNeedsConfirm) return [3 /*break*/, 14];
                if (location.pathname.includes('/register/confirm/'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push('/register/confirm/'))];
            case 13:
                _d.sent();
                return [2 /*return*/, true];
            case 14:
                if (!(!userData.occupation &&
                    !PUBLISHER_CAN_REGISTER &&
                    location.pathname.includes('/register/') &&
                    !location.pathname.includes('/register/occupations'))) return [3 /*break*/, 16];
                return [4 /*yield*/, put(push(includeSearchParams('/register/occupations')))];
            case 15:
                _d.sent();
                return [2 /*return*/, true];
            case 16:
                if (!(!userData.occupation &&
                    location.pathname.includes('/register/occupations'))) return [3 /*break*/, 18];
                if (location.pathname.includes('/register/'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push(includeSearchParams('/register/occupations')))];
            case 17:
                _d.sent();
                return [2 /*return*/, true];
            case 18:
                if (!!userData.occupation) return [3 /*break*/, 20];
                if (location.pathname.includes('/register/'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push(includeSearchParams('/register/welcome')))];
            case 19:
                _d.sent();
                return [2 /*return*/, true];
            case 20:
                needsToCompleteOrgSetupStepOne = userData.occupation !== OccupationType.individual.value &&
                    !userData.organization;
                if (!needsToCompleteOrgSetupStepOne) return [3 /*break*/, 22];
                if (location.pathname.includes('/register/'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push('/register/organization'))];
            case 21:
                _d.sent();
                return [2 /*return*/, true];
            case 22:
                if (!userData.invite) return [3 /*break*/, 24];
                if ((_c = invite === null || invite === void 0 ? void 0 : invite.data()) === null || _c === void 0 ? void 0 : _c.user)
                    return [2 /*return*/, false];
                if (location.pathname.includes('/invites/confirm-organization/'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push(includeSearchParams('/invites/confirm-organization/')))];
            case 23:
                _d.sent();
                return [2 /*return*/, true];
            case 24:
                _b = userData.activeOrganization;
                if (!_b) return [3 /*break*/, 26];
                return [4 /*yield*/, call([
                        userData.activeOrganization,
                        userData.activeOrganization.get
                    ])];
            case 25:
                _b = (_d.sent());
                _d.label = 26;
            case 26:
                activeOrg = _b;
                if (!activeOrg)
                    return [2 /*return*/, false];
                if (!(organizationIsSetup(userData, activeOrg.data()) &&
                    (activeOrg.data().organizationType === OrganizationType.government.value ||
                        activeOrg.data().organizationType === OrganizationType.law_firm.value ||
                        activeOrg.data().organizationType ===
                            OrganizationType.other_organization.value) &&
                    activeOrg.data().postRegistrationComplete &&
                    redirectUrl)) return [3 /*break*/, 28];
                if (location.pathname.includes('/post-registration'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push("/?redirect=" + encodeURIComponent(redirectUrl)))];
            case 27:
                _d.sent();
                sessionStorage.clear();
                return [2 /*return*/, true];
            case 28:
                if (!(organizationIsSetup(userData, activeOrg.data()) &&
                    (activeOrg.data().organizationType === OrganizationType.government.value ||
                        activeOrg.data().organizationType === OrganizationType.law_firm.value ||
                        activeOrg.data().organizationType ===
                            OrganizationType.other_organization.value ||
                        activeOrg.data().organizationType === OrganizationType.newspaper.value) &&
                    !activeOrg.data().postRegistrationComplete)) return [3 /*break*/, 30];
                if (location.pathname.includes('/post-registration'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push("/register/organization/post-registration?redirect=" + encodeURIComponent(location.pathname + location.search)))];
            case 29:
                _d.sent();
                return [2 /*return*/, true];
            case 30:
                if (!!organizationIsSetup(userData, activeOrg.data())) return [3 /*break*/, 32];
                if (location.pathname.includes('/register/organization'))
                    return [2 /*return*/, false];
                return [4 /*yield*/, put(push("/register/organization" + (activeOrg ? '?step=3' : '')))];
            case 31:
                _d.sent();
                return [2 /*return*/, true];
            case 32:
                alreadyRedirected = [
                    '/invites/confirm-organization',
                    '/register/organization',
                    '/register/organization/post-registration',
                    '/new-feature-onboard/advertising-deadlines'
                ].find(function (path) { return location.pathname.includes(path); });
                if (alreadyRedirected)
                    return [2 /*return*/, false];
                return [2 /*return*/];
        }
    });
}
function redirect(action) {
    function restrict() {
        var pathname, userAuth;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    pathname = action.payload.location.pathname;
                    return [4 /*yield*/, select(authSelector)];
                case 1:
                    userAuth = (_a.sent()).userAuth;
                    if (!!userAuth) return [3 /*break*/, 3];
                    return [4 /*yield*/, put(push("/login/?redirect=" + encodeURIComponent(pathname)))];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }
    var user, pathname, test, _a, landingPage, userAuth, redirect_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                user = (_b.sent()).user;
                if (!!user) return [3 /*break*/, 3];
                return [4 /*yield*/, take([AuthTypes.SET_USER, AuthTypes.END_AUTH])];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [4 /*yield*/, call(checkNeedToRedirect)];
            case 4:
                if (_b.sent())
                    return [2 /*return*/];
                pathname = action.payload.location.pathname;
                test = function (path) {
                    return pathToRegex(path).test(pathname);
                };
                _a = true;
                switch (_a) {
                    case test('/'): return [3 /*break*/, 5];
                    case test('/login/'): return [3 /*break*/, 8];
                    case test('/search/'): return [3 /*break*/, 13];
                    case test('/register/'): return [3 /*break*/, 13];
                    case test('/landing/'): return [3 /*break*/, 13];
                    case test('/place/:id?'): return [3 /*break*/, 13];
                    case test('/rfps/:id'): return [3 /*break*/, 13];
                    case test('/foreclosures/:id'): return [3 /*break*/, 13];
                    case test('/form/:noticeType/:noticeId'): return [3 /*break*/, 13];
                    case test('/form/newspaper/:noticeId'): return [3 /*break*/, 13];
                    case test('/association/'): return [3 /*break*/, 13];
                    case test('/file/by-type/:type'): return [3 /*break*/, 13];
                    case test('/file/:id/:noticeSlug?'): return [3 /*break*/, 13];
                    case test('/email-action'): return [3 /*break*/, 13];
                    case test('/invites/:id'): return [3 /*break*/, 13];
                    case test('/invoices/:id/pay'): return [3 /*break*/, 13];
                    case test('/reset-password/'): return [3 /*break*/, 13];
                    case test('/public-notice/:id'): return [3 /*break*/, 13];
                    case test('/logout/'): return [3 /*break*/, 14];
                    case test('/register/welcome/'): return [3 /*break*/, 14];
                    case test('/register/occupations/'): return [3 /*break*/, 14];
                    case test('/register/organization/'): return [3 /*break*/, 14];
                    case test('/register/organization/post-registration/'): return [3 /*break*/, 14];
                    case test('/register/confirm/'): return [3 /*break*/, 14];
                    case test('/reports/'): return [3 /*break*/, 14];
                    case test('/cards/'): return [3 /*break*/, 14];
                    case test('/payments/'): return [3 /*break*/, 14];
                    case test('/temporary'): return [3 /*break*/, 14];
                    case test('/place_press/:bulkId?'): return [3 /*break*/, 14];
                    case test('/bulk/:bulkId'): return [3 /*break*/, 14];
                    case test('/settings/'): return [3 /*break*/, 14];
                    case test('/settings/organization/'): return [3 /*break*/, 14];
                    case test('/:path(notice|publish)/:id/'): return [3 /*break*/, 14];
                    case test('/:path(notice|publish)/:id/invoice/create'): return [3 /*break*/, 14];
                    case test('/:path(notice|publish)/:id/invoice/review'): return [3 /*break*/, 14];
                    case test('/:path(notice|publish)/:id/invoice/create-bulk'): return [3 /*break*/, 14];
                    case test('/invites/confirm-organization/'): return [3 /*break*/, 14];
                    case test('/error/:code'): return [3 /*break*/, 14];
                    case test('/cards/invoices/:id/pay'): return [3 /*break*/, 14];
                    case test('/donation-campaign'): return [3 /*break*/, 14];
                    case test('/notices/'): return [3 /*break*/, 14];
                }
                return [3 /*break*/, 16];
            case 5: return [4 /*yield*/, call(getLandingPage)];
            case 6:
                landingPage = _b.sent();
                return [4 /*yield*/, put(replace(includeSearchParams(landingPage)))];
            case 7:
                _b.sent();
                return [3 /*break*/, 18];
            case 8: return [4 /*yield*/, select(authSelector)];
            case 9:
                userAuth = (_b.sent()).userAuth;
                if (!userAuth) return [3 /*break*/, 12];
                return [4 /*yield*/, call(getRedirect)];
            case 10:
                redirect_1 = _b.sent();
                return [4 /*yield*/, put(push(redirect_1))];
            case 11:
                _b.sent();
                _b.label = 12;
            case 12: return [3 /*break*/, 18];
            case 13:
                {
                    return [3 /*break*/, 18];
                }
                _b.label = 14;
            case 14: return [4 /*yield*/, call(restrict)];
            case 15:
                _b.sent();
                return [3 /*break*/, 18];
            case 16: return [4 /*yield*/, put(push('/'))];
            case 17:
                _b.sent();
                _b.label = 18;
            case 18: return [2 /*return*/];
        }
    });
}
export default function root() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(LOCATION_CHANGE, redirect)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
