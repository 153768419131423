var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Typography, Paper, Button, Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { BROWSER, BROWSERS } from '../constants';
var mapStateToProps = function (state) { return ({
    location: state.router.location
}); };
var styles = function (theme) {
    return createStyles({
        paper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme.palette.background.paper
        },
        confirmButton: {
            justifyContent: 'center',
            backgroundColor: theme.palette.primary.main,
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.primary[600] + " !important;"
            },
            maxWidth: '40%'
        },
        notInterested: {
            justifyContent: 'center',
            backgroundColor: theme.palette.grey[300],
            color: 'white',
            '&:hover': {
                backgroundColor: theme.palette.grey[400] + " !important;"
            },
            maxWidth: '40%',
            marginRight: theme.spacing(1)
        },
        headingText: {
            color: theme.palette.grey[700],
            textTransform: 'uppercase'
        },
        heading: {
            fontWeight: 600
        },
        enoticeIcon: {
            maxWidth: 48,
            maxHeight: 48
        }
    });
};
var SESSION_VAR = 'browserSwitchPopperSeen';
var shouldDisplay = function () {
    return (BROWSER === BROWSERS.ie || BROWSER === BROWSERS.edge) &&
        !window.sessionStorage.getItem(SESSION_VAR);
};
var PositionedPopper = function (_a) {
    var classes = _a.classes, theme = _a.theme, hideOnTheseRoutes = _a.hideOnTheseRoutes, location = _a.location;
    var _b = __read(React.useState(shouldDisplay()), 2), open = _b[0], setOpen = _b[1];
    useEffect(function () {
        for (var i = 0; i < hideOnTheseRoutes.length; i++) {
            if (location.pathname.includes(hideOnTheseRoutes[i]))
                return setOpen(false);
        }
        return setOpen(true);
    }, [location]);
    if (!open || !shouldDisplay())
        return null;
    return (React.createElement(Paper, { style: {
            padding: theme.spacing(2),
            position: 'fixed',
            top: theme.spacing(10),
            right: theme.spacing(2),
            zIndex: 20
        } },
        React.createElement(Grid, { container: true, direction: "column", alignItems: "center", justify: "center" },
            React.createElement(Grid, { item: true },
                React.createElement("img", { src: "https://firebasestorage.googleapis.com/v0/b/enotice-demo-8d99a.appspot.com/o/column-landing%2FColumn_Logo_Final-09.svg?alt=media&token=0b92098f-9471-4135-809e-64869779a6e5", height: "48", className: classes.enoticeIcon })),
            React.createElement(Grid, { item: true, style: { textAlign: 'center' } },
                React.createElement(Typography, { variant: "subtitle2", className: classes.heading }, "Consider Switching to Chrome"),
                React.createElement(Box, { mt: 1 },
                    React.createElement(Grid, { style: { maxWidth: 300 } },
                        React.createElement(Typography, { variant: "subtitle2" }, "Column uses advanced web technology that works best in an updated browser."))))),
        React.createElement(Box, { mt: 2 },
            React.createElement(Grid, { container: true, direction: "row", justify: "center" },
                React.createElement(Button, { className: classes.notInterested, onClick: function () {
                        window.sessionStorage.setItem(SESSION_VAR, 'true');
                        setOpen(false);
                    } }, "Not Now"),
                React.createElement(Button, { className: classes.confirmButton, onClick: function () {
                        window.sessionStorage.setItem(SESSION_VAR, 'true');
                        window.open('https://www.google.com/chrome/');
                        setOpen(false);
                    } }, "Get Chrome")))));
};
export default connect(mapStateToProps)(withStyles(styles, { withTheme: true })(PositionedPopper));
