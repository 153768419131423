var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { BROWSER, BROWSERS } from '../constants';
export var getLocationParams = function (location) {
    var l = location !== null && location !== void 0 ? location : window.location;
    return new URLSearchParams(l.search);
};
export var getHostname = function () {
    return window.location.hostname.split('.').slice(-2)[0];
};
export var getRedirect = function () {
    return getLocationParams().get('redirect') || '/?noredirect';
};
export var includeSearchParams = function (url) {
    return "" + url + window.location.search;
};
var locationParamsToTuples = function (searchParams) {
    var e_1, _a;
    var result = [];
    try {
        for (var searchParams_1 = __values(searchParams), searchParams_1_1 = searchParams_1.next(); !searchParams_1_1.done; searchParams_1_1 = searchParams_1.next()) {
            var entry = searchParams_1_1.value;
            var _b = __read(entry, 2), key = _b[0], value = _b[1];
            result.push([key, value]);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (searchParams_1_1 && !searchParams_1_1.done && (_a = searchParams_1.return)) _a.call(searchParams_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return result;
};
export var appendToCurrentParams = function (key, value) {
    var params = new URLSearchParams(Object.fromEntries(__spread(locationParamsToTuples(getLocationParams()), [
        [key, value]
    ])));
    return params;
};
export var updateHistoryNoRerender = function (path) {
    window.history.replaceState(null, document.title, path);
    var event;
    if (BROWSER === BROWSERS.ie) {
        event = document.createEvent('CustomEvent');
        event.initCustomEvent('replacestate', true, true, {
            detail: 'replacestate'
        });
    }
    else {
        event = new CustomEvent('replacestate', { detail: 'replacestate' });
    }
    dispatchEvent(event);
};
export var getSubdomain = function () { return window.location.host.split('.')[0]; };
export var getSubdomainOnly = function () {
    var domains = window.location.host.split('.');
    if (domains.includes('web') || domains.includes('firebaseapp'))
        return null;
    if (domains.length > 2)
        return domains[0];
    return null;
};
