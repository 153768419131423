import firebase from 'firebase';
import { Collections, Functions } from './lib/constants';
import 'firebase/storage';
import FIREBASE_CONFIG from './config.firebase';
var Firebase = firebase.initializeApp(FIREBASE_CONFIG);
export { Collections, Functions };
export var deleteFieldValue = firebase.firestore.FieldValue.delete;
if (process.env.NODE_ENV === 'development') {
    Firebase.functions()._url = function (name) {
        return "http://localhost:5001/" + FIREBASE_CONFIG.projectId + "/us-central1/" + name;
    };
}
window.Firebase = Firebase;
export default Firebase;
