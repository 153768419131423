var _a, _b, _c, _d, _e;
import { detect } from 'detect-browser';
import { envs } from './lib/constants';
import FIREBASE_CONFIG from './config.firebase';
export var PROD = envs.PROD;
export var STAGING = envs.STAGING;
export var DEV = envs.DEV;
export var TEST = envs.TEST;
export var DEMO = envs.DEMO;
export var ENOTICE_SUPPORT_EMAIL = 'help@column.us';
export var BROWSER = detect() ? detect().name : null;
export var BROWSERS = {
    ie: 'ie',
    edge: 'edge',
    safari: 'safari',
    chrome: 'chrome',
    firefox: 'firefox'
};
export var RECORD = false;
// determine the corresponding environment
export var ENV = (process.env.NODE_ENV === 'production' &&
    FIREBASE_CONFIG.projectId === 'enotice-production' &&
    PROD) ||
    (window.location.host.split('.')[0] === 'staging' && STAGING) ||
    (FIREBASE_CONFIG.projectId === 'enotice-demo-8d99a' && DEMO) ||
    (process.env.NODE_ENV === 'test' && TEST) ||
    DEV;
export var HOST = window.location.host.split(':')[0].replace('//', '');
export var PORT = window.location.host.split(':')[1];
export var IS_LOCALHOST = PORT ||
    HOST === 'localhost' ||
    HOST === '127.0.0.1' ||
    false;
console.log('USING ENVIRONMENT', ENV, HOST, IS_LOCALHOST, window.location.host);
export var IMAGES = {
    SHAPE_LEFT: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff',
    SHAPE_RIGHT: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fe-notice%2Frectangles.png?alt=media&token=6bab4a5b-fb6d-43a3-8425-cbc22ee8fdff'
};
// Stripe
var DEV_STRIPE_VARS = {
    invoices: 'https://dashboard.stripe.com/test/invoices/create',
    transfer: 'https://dashboard.stripe.com/test/connect/accounts/',
    key: 'pk_test_lHCdsg49uFGcdXruY4mpo0Y6008ZDcTc2P',
    oauthUrl: "https://connect.stripe.com/express/oauth/authorize?response_type=code&redirect_uri=" + window.location.origin + "/register/organization/&client_id=ca_FCoJmwUpr1XVipWIjUQy7Ty0WlOBOlx1&stripe_user[business_type]=company"
};
var LIVE_STRIPE_VARS = {
    invoices: 'https://dashboard.stripe.com/invoices/create',
    transfer: 'https://dashboard.stripe.com/connect/accounts/',
    key: 'pk_live_rcQzdsMuxEALkEV9qiOcyLBi001yQrpvs5',
    oauthUrl: "https://connect.stripe.com/express/oauth/authorize?response_type=code&redirect_uri=" + window.location.origin + "/register/organization/&client_id=ca_FCoJqm431x55xVYdszWhc7UpwSwVXGaE&stripe_user[business_type]=company"
};
export var TINY_MCE_KEY = ENV === PROD || ENV === DEMO
    ? 's6l4pcl31len6pryiz7kb7r6l058nzynil908zgpyjezx7j7'
    : '';
export var LOGROCKET_KEY = ENV === PROD
    ? 'risv5h/web-app'
    : ENV === DEV || ENV === TEST
        ? 'risv5h/column-testing'
        : 'risv5h/enotice-demo';
export var SENTRY_DSN = 'https://17f327f9eaba4d249da251c9c10c6077@sentry.io/1487041';
export var STRIPE_VARS = ENV === PROD ? LIVE_STRIPE_VARS : DEV_STRIPE_VARS;
export var AUTH_TOKEN = 'auth-token';
export var GOOGLE_API_KEY = 'AIzaSyBHww1bTf3bX-cqdpexvYIBgRM7FVTX70U';
// the google maps api key created for the enotice-app project
export var GOOGLE_MAPS_API_KEY_DEV = 'AIzaSyBODcyh0QZoBEPWyrNlW--p4Xx5czgKqVQ';
var DEV_ALGOLIA_CONFIG = {
    index: 'dev_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
var STAGING_ALGOLIA_CONFIG = {
    index: 'staging_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
var DEMO_ALGOLIA_CONFIG = {
    index: 'demo_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
var PROD_ALGOLIA_CONFIG = {
    index: 'prod_NOTICES',
    key: '89023e0749c0d91659d2d1eaf67c7a94',
    app: 'DE9Y8KBO76'
};
var DEV_INDESIGN_URL = 'https://indesign-dev.enotice.io';
var PROD_INDESIGN_URL = 'https://indesign.enotice.io';
export var INDESIGN_URL = (_a = {},
    _a[DEV] = DEV_INDESIGN_URL,
    _a[STAGING] = DEV_INDESIGN_URL,
    _a[DEMO] = PROD_INDESIGN_URL,
    _a[PROD] = PROD_INDESIGN_URL,
    _a[TEST] = DEV_INDESIGN_URL,
    _a)[ENV];
export var ALGOLIA_CONFIG = (_b = {},
    _b[DEV] = DEV_ALGOLIA_CONFIG,
    _b[STAGING] = STAGING_ALGOLIA_CONFIG,
    _b[DEMO] = DEMO_ALGOLIA_CONFIG,
    _b[PROD] = PROD_ALGOLIA_CONFIG,
    _b[TEST] = DEV_ALGOLIA_CONFIG,
    _b)[ENV];
export var ANY_ORIGIN_ENUM = '*';
export var ORIGINS_WHITELISTED_FOR_PUBLISHER_REGISTRATION = (_c = {},
    _c[DEV] = [
        'http://localhost:3000',
        'https://enotice-testing.web.app',
        'https://enotice-testing2.web.app',
        'https://enotice-hotfix.firebaseapp.com',
        'https://enotice-hotfix.web.app',
        'https://enotice-betts.web.app',
        'https://enotice-sheraz.web.app',
        'https://enotice-teresa.web.app',
        'https://enotice-example.web.app',
        'https://enotice-setup-env.web.app'
    ],
    _c[STAGING] = [ANY_ORIGIN_ENUM],
    _c[DEMO] = ['https://florida-demo.column.us', 'https://demo.enotice.io'],
    _c[PROD] = [
        'https://kansas.enotice.io',
        'https://kansas.column.us',
        'https://florida.column.us',
        'https://app.enotice.io',
        'https://enotice.io',
        'https://www.enotice.io',
        'https://www.column.us',
        'https://app.column.us',
        'https://column.us'
    ],
    _c[TEST] = [ANY_ORIGIN_ENUM],
    _c);
export var PUBLISHER_CAN_REGISTER = ORIGINS_WHITELISTED_FOR_PUBLISHER_REGISTRATION[ENV].includes(ANY_ORIGIN_ENUM) ||
    ORIGINS_WHITELISTED_FOR_PUBLISHER_REGISTRATION[ENV].includes(window.location.origin) ||
    window.location.origin.includes('netlify.app');
export var PENDO_KEY = '5f61770e-7118-4f29-785e-28a8beae9b78';
export var SHOULD_RUN_PENDO = ENV === PROD || ENV === DEMO;
var DEV_LOB_CONFIG = {
    key: 'test_pub_1759d77711aca0d3075f59541b5d713',
    address: { primary_line: 'undeliverable no match' }
};
var PROD_LOB_CONFIG = {
    key: 'live_pub_439e498c923e164febdcafbf0cdd62f',
    address: { primary_line: '' }
};
export var LOB_CONFIG = (_d = {},
    _d[PROD] = PROD_LOB_CONFIG,
    _d[DEV] = DEV_LOB_CONFIG,
    _d[DEMO] = DEV_LOB_CONFIG,
    _d[STAGING] = DEV_LOB_CONFIG,
    _d[TEST] = DEV_LOB_CONFIG,
    _d);
export var ENOTICE_ACCOUNT_ID = ENV === PROD ? 'acct_1F4vICCGonLIgiaZ' : 'acct_1FzOn6EwSpcYm9q2';
export var DUMMY_ACCOUNT_ID = '123456';
export var PLAID_ENV = (_e = {},
    _e[PROD] = 'development',
    _e[DEV] = 'sandbox',
    _e[DEMO] = 'sandbox',
    _e[STAGING] = 'sandbox',
    _e[TEST] = 'sandbox',
    _e);
export var DWOLLA_ENV = ENV === PROD ? 'prod' : 'sandbox';
