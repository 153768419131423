var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import moment from 'moment-timezone';
import { getDeadlineOverrideKeyFromDate } from '../types/deadlines';
import { getNoticeTypeFromNoticeData } from '../helpers';
import { InvoiceStatus, NoticeType } from '../enums';
export var disableNonPublishingDays = function (day, publishingDayEnumValues, overrides) {
    var publishingDayOverride = overrides && overrides[getDeadlineOverrideKeyFromDate(day)];
    if (publishingDayOverride) {
        return !publishingDayOverride.publish;
    }
    return !publishingDayEnumValues.includes(day.getDay() + 1);
};
export var getRelevantDeadline = function (publicationDate, deadlines, overrides) {
    var publishingDayOverride = overrides && overrides[getDeadlineOverrideKeyFromDate(publicationDate)];
    if (publishingDayOverride) {
        return publishingDayOverride;
    }
    var m = moment(publicationDate).clone();
    var publicationDayOfWeek = m.day();
    // enums days of week are off by one from moment days of week
    var relevantDeadline = deadlines.find(function (day) { return day.dayEnum === publicationDayOfWeek + 1; });
    return relevantDeadline;
};
export var getDeadlineTimeForPaper = function (publicationDate, deadlines, newspaperTimezone, notice, newspaper) {
    var _a;
    var relevantDeadline = getRelevantDeadline(publicationDate, deadlines, (_a = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _a === void 0 ? void 0 : _a.deadlineOverrides);
    var deadlineDayOfWeek = relevantDeadline.deadline.dayEnum - 1;
    var m = moment(publicationDate).clone();
    var publicationDayOfWeek = m.day();
    var startOfDeadlineDay;
    // e.g. publication on Sunday (day 0), deadline on Saturday (day 6)
    if (publicationDayOfWeek - deadlineDayOfWeek < 0) {
        startOfDeadlineDay = moment(publicationDate)
            .clone()
            .tz(newspaperTimezone)
            .subtract(1, 'week')
            .add(deadlineDayOfWeek - publicationDayOfWeek, 'd')
            .startOf('day');
    }
    else {
        // e.g. publication on Monday (day 1), deadline on Sunday (day 0)
        startOfDeadlineDay = moment(publicationDate)
            .clone()
            .tz(newspaperTimezone)
            .subtract(publicationDayOfWeek - deadlineDayOfWeek, 'day')
            .startOf('day');
    }
    var noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
    if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.publicationOffsetDays) {
        startOfDeadlineDay = startOfDeadlineDay.subtract(noticeType.publicationOffsetDays, 'days');
    }
    if (relevantDeadline.weeks) {
        startOfDeadlineDay = startOfDeadlineDay.subtract(relevantDeadline.weeks, 'weeks');
    }
    if (notice.noticeType === NoticeType.display_ad.value && (relevantDeadline === null || relevantDeadline === void 0 ? void 0 : relevantDeadline.displayOffset)) {
        startOfDeadlineDay = startOfDeadlineDay.subtract(relevantDeadline === null || relevantDeadline === void 0 ? void 0 : relevantDeadline.displayOffset, 'hours');
    }
    var deadlineHoursFromStartOfDay = parseInt(relevantDeadline.deadline.time.split(':')[0], 10);
    var deadlineMinutes = parseInt(relevantDeadline.deadline.time.split(':')[1], 10);
    var deadlineTimeForPaper = startOfDeadlineDay
        .add(deadlineHoursFromStartOfDay, 'hours')
        .add(deadlineMinutes, 'minutes');
    return deadlineTimeForPaper.clone();
};
export var getIsAfterPublishingDeadline = function (publicationDate, deadlines, newspaperTimezone, notice, newspaper) {
    if (!deadlines || !publicationDate)
        return true;
    var currentTimeForPaper = moment(Date.now()).tz(newspaperTimezone);
    var deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, newspaperTimezone, notice, newspaper);
    return currentTimeForPaper.isAfter(deadlineTimeForPaper);
};
export var isNoticeAfterPublicationDeadline = function (notice) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaperSnap, firstPublication, _a, deadlines, iana_timezone;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, notice.data().newspaper.get()];
            case 1:
                newspaperSnap = _b.sent();
                firstPublication = notice.data().publicationDates[0];
                _a = newspaperSnap.data(), deadlines = _a.deadlines, iana_timezone = _a.iana_timezone;
                return [2 /*return*/, getIsAfterPublishingDeadline(firstPublication.toDate(), deadlines, iana_timezone, notice, newspaperSnap)];
        }
    });
}); };
export var canCancelNoticeWithoutSupport = function (noticeSnap, newspaper, invoice, isPublisher) {
    try {
        return (isPublisher ||
            (!getIsAfterPublishingDeadline(noticeSnap.data().publicationDates[0].toDate(), newspaper.data().deadlines, newspaper.data().iana_timezone, noticeSnap.data(), newspaper) &&
                (invoice
                    ? invoice.data().status === InvoiceStatus.payment_failed.value ||
                        invoice.data().status === InvoiceStatus.unpaid.value
                    : true)));
    }
    catch (err) {
        return false;
    }
};
export var publishingDayEnumValuesFromDeadlines = function (deadlines) {
    if (!deadlines)
        return [];
    return deadlines.filter(function (day) { return day.publish; }).map(function (day) { return day.dayEnum; });
};
// MAX_WEEKS_TO_TRY is changed here to 20 instead of 3 because in some circumstances, the original start date
// of a notice + 3 weeks is before the current date. Thus, when a user tries to add run dates on notices that
// started more than 3 weeks ago, the edit flow stops working. The fix here is to change MAX_WEEKS_TO_TRY to
// a much larger number (in this case, 20 weeks)
var MAX_WEEKS_TO_TRY = 20;
export var getClosestFuturePublishingDay = function (deadlines, newspaperTimezone, notice, newspaper, initialStartDate) {
    if (initialStartDate === void 0) { initialStartDate = new Date(); }
    var publishingDayEnumValues = publishingDayEnumValuesFromDeadlines(deadlines).sort();
    var startDate = initialStartDate;
    var noticeType = getNoticeTypeFromNoticeData(notice, newspaper);
    if (noticeType === null || noticeType === void 0 ? void 0 : noticeType.publicationOffsetDays)
        startDate = moment(startDate)
            .add(noticeType.publicationOffsetDays, 'days')
            .toDate();
    // returns the first available date in the week on which the notice can be
    // published. If there is no available day, returns null
    var findFirstAvailableDateInWeek = function (firstAvailableDate) {
        var e_1, _a;
        var _b;
        try {
            for (var publishingDayEnumValues_1 = __values(publishingDayEnumValues), publishingDayEnumValues_1_1 = publishingDayEnumValues_1.next(); !publishingDayEnumValues_1_1.done; publishingDayEnumValues_1_1 = publishingDayEnumValues_1.next()) {
                var deadlineDay = publishingDayEnumValues_1_1.value;
                // e.g. next tuesday
                var desiredWeekdayValue = deadlineDay - 1;
                var nextWeekDateMomentObj = moment(firstAvailableDate).day(desiredWeekdayValue);
                var deadlineOverride = getDeadlineOverrideKeyFromDate(nextWeekDateMomentObj.toDate());
                var publishingDayOverride = newspaper.data().deadlineOverrides &&
                    newspaper.data().deadlineOverrides[deadlineOverride] &&
                    !((_b = newspaper.data().deadlineOverrides[deadlineOverride]) === null || _b === void 0 ? void 0 : _b.publish);
                if (publishingDayOverride)
                    continue;
                // ignore deadlines that are before the start date
                if (nextWeekDateMomentObj.isBefore(moment(startDate)))
                    continue;
                var deadlinePassed = getIsAfterPublishingDeadline(nextWeekDateMomentObj.toDate(), deadlines, newspaperTimezone, notice, newspaper);
                if (!deadlinePassed)
                    return nextWeekDateMomentObj;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (publishingDayEnumValues_1_1 && !publishingDayEnumValues_1_1.done && (_a = publishingDayEnumValues_1.return)) _a.call(publishingDayEnumValues_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return null;
    };
    var nextPublishingDay;
    for (var i = 0; i < MAX_WEEKS_TO_TRY; i++) {
        nextPublishingDay = findFirstAvailableDateInWeek(moment(startDate)
            .add(i, 'weeks')
            .toDate());
        if (nextPublishingDay)
            break;
    }
    if (!nextPublishingDay)
        throw new Error("Unable to find next publishing day for paper: " + newspaper.data().name);
    var nextDate = nextPublishingDay.toDate();
    nextDate.setHours(12, 0, 0, 0);
    return nextDate;
};
export var dateObjectToDay = function (date) {
    var m = moment(date);
    return m.format('MMMM Do');
};
export var getDeadlineString = function (publicationDate, deadlines, newspaperTimezone, notice, newspaper) {
    var t = moment(Date.now());
    var newspaperTime = t.tz(newspaperTimezone).format('z');
    var deadlineTimeForPaper = getDeadlineTimeForPaper(publicationDate, deadlines, newspaperTimezone, notice, newspaper);
    return moment(deadlineTimeForPaper).format('dddd, MMMM Do, h:mm a') + " " + newspaperTime;
};
export var dateObjectToDayEnum = function (date) { return moment(date).day() + 1; };
