export default {
    alabama: {
        value: 2,
        label: 'Alabama',
        abbrev: 'al',
        us_census_fips_code: '01',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.alabamapublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'app.scraper.PressAssociationScraper.AlabamaScraper',
        key: 'alabama'
    },
    alaska: {
        value: 3,
        label: 'Alaska',
        abbrev: 'ak',
        us_census_fips_code: '02',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'https://aws.state.ak.us/OnlinePublicNotices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '4',
        scraper: 'app.scraper.AlaskaScraper.AlaskaIncrementScraper',
        key: 'alaska'
    },
    arizona: {
        value: 4,
        label: 'Arizona',
        abbrev: 'az',
        us_census_fips_code: '04',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/AZ/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.ArizonaScraper',
        key: 'arizona'
    },
    arkansas: {
        value: 5,
        label: 'Arkansas',
        abbrev: 'ar',
        us_census_fips_code: '05',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/AR/',
        custom_site: 'http://www.arkansasonline.com/legals/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.ArkansasScraper',
        key: 'arkansas'
    },
    california: {
        value: 6,
        label: 'California',
        abbrev: 'ca',
        us_census_fips_code: '06',
        newzgroup: false,
        my_public_notices: 'http://ca.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.capublicnotice.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 5,
        scraper: '',
        key: 'california'
    },
    colorado: {
        value: 7,
        label: 'Colorado',
        abbrev: 'co',
        us_census_fips_code: '08',
        newzgroup: false,
        my_public_notices: 'http://co.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticecolorado.com/',
        press_association_ssid: 663,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        hasTypeform: true,
        key: 'colorado'
    },
    connecticut: {
        value: 8,
        label: 'Connecticut',
        abbrev: 'ct',
        us_census_fips_code: '09',
        newzgroup: false,
        my_public_notices: 'http://ct.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'connecticut'
    },
    delaware: {
        value: 9,
        label: 'Delaware',
        abbrev: 'de',
        us_census_fips_code: '10',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.mddcnews.com/index.php/public_notices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'delaware'
    },
    district_of_columbia: {
        value: 10,
        label: 'District of Columbia',
        abbrev: 'dc',
        us_census_fips_code: '11',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://classified.washingtontimes.com/category/315/Legal-Notices.html',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'district_of_columbia'
    },
    florida: {
        value: 11,
        label: 'Florida',
        abbrev: 'fl',
        us_census_fips_code: '12',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: '',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: 'http://www.floridapublicnotices.com/',
        legal_notice_dot_org: 10,
        scraper: '',
        key: 'florida',
        can_run_donations: true,
        donation_subdomain: 'fpf',
        donations_legal_docs: [
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Flegal%2Fdonations%2FFlorida_W9.pdf?alt=media&token=e8a302ef-9e17-42ef-966a-4b0b575b4aad',
                fileName: 'W-9.pdf'
            },
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fdonations%2Fflorida%2FFiscal%20Sponsorship.pdf?alt=media&token=379b47a2-37f5-4941-a35f-60aa3cdadb3c',
                fileName: 'Fiscal_Sponsorship_Agreement.pdf'
            }
        ],
        donations_product: {
            prod: 'prod_I3ehNnDHotRSI7',
            test: 'prod_HyHTVkeafU79cD'
        },
        stripe_account_id: {
            prod: 'acct_1HSlBQJEXHMRrM6c',
            test: 'acct_1HTVNlKO1H032WHx'
        },
        feePct: '3',
        lob_address_id: 'adr_1bcc80e032d26830',
        lob_bank_account_id: {
            test: 'bank_e90ac64381c00df'
        },
        stripe_customer_id: {
            prod: 'cus_IE8DTFEV1lWsag',
            test: 'cus_I8mlKFtwJ0S7dG'
        }
    },
    georgia: {
        value: 12,
        label: 'Georgia',
        abbrev: 'ga',
        us_census_fips_code: '13',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.georgiapublicnotice.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 11,
        scraper: '',
        key: 'georgia'
    },
    guam: {
        value: 54,
        label: 'Guam',
        abbrev: 'gu',
        us_census_fips_code: 14,
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'guam'
    },
    hawaii: {
        value: 13,
        label: 'Hawaii',
        abbrev: 'hi',
        us_census_fips_code: '15',
        newzgroup: false,
        my_public_notices: 'http://hi.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: 'http://statelegals.staradvertiser.com/category/public-notices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'hawaii'
    },
    idaho: {
        value: 14,
        label: 'Idaho',
        abbrev: 'id',
        us_census_fips_code: '16',
        newzgroup: false,
        my_public_notices: 'http://id.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.idahopublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 14,
        scraper: '',
        key: 'idaho'
    },
    illinois: {
        value: 15,
        label: 'Illinois',
        abbrev: 'il',
        us_census_fips_code: '17',
        newzgroup: false,
        my_public_notices: 'http://il.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticeillinois.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'illinois'
    },
    indiana: {
        value: 16,
        label: 'Indiana',
        abbrev: 'in',
        us_census_fips_code: '18',
        newzgroup: false,
        my_public_notices: 'http://in.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://hspa.eclipping.org/hspa/legals/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 16,
        scraper: '',
        key: 'indiana'
    },
    iowa: {
        value: 17,
        label: 'Iowa',
        abbrev: 'ia',
        us_census_fips_code: '19',
        newzgroup: 'http://iapublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'iowa'
    },
    kansas: {
        value: 18,
        label: 'Kansas',
        abbrev: 'ks',
        us_census_fips_code: '20',
        newzgroup: 'http://kspublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '17',
        scraper: '',
        hasTypeform: true,
        key: 'kansas',
        can_run_donations: true,
        one_time_donation_template: 'd-41c040eb584646389ba7d79f4a82fa42',
        recurring_donation_template: 'd-f3f25ea16e6b44e79b791c5e5da8f5e6',
        donation_subdomain: 'knf',
        feePct: '3',
        lob_address_id: 'adr_2fb684459071fe8c',
        lob_bank_account_id: {
            prod: 'bank_dc616c8d076842c',
            test: 'bank_e90ac64381c00df'
        },
        donations_product: {
            prod: 'prod_I6DhEEaeT7F6de',
            test: 'prod_I5UWFn7erXiRvi'
        },
        stripe_account_id: {
            prod: 'acct_1HX65UJU1AKKkGS9',
            test: 'acct_1HTVNlKO1H032WHx'
        },
        stripe_customer_id: {
            prod: 'cus_IE8BnBuH5zBaZV',
            test: 'cus_I8mn4zkqciVlpW'
        },
        donations_legal_docs: [
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fdonations%2Fkansas%2Fw9_ks.pdf?alt=media&token=c5d4b411-8080-4a76-a1a8-07179fda7461',
                fileName: 'W-9.pdf'
            },
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fdonations%2Fkansas%2Fks_fiscalsponsorshipagreement.pdf?alt=media&token=3ace9813-feeb-422a-8791-e6261ee141ca',
                fileName: 'Fiscal_Sponsorship_Agreement.pdf'
            }
        ]
    },
    kentucky: {
        value: 19,
        label: 'Kentucky',
        abbrev: 'ky',
        us_census_fips_code: '21',
        newzgroup: 'http://kypublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 18,
        scraper: '',
        key: 'kentucky'
    },
    louisiana: {
        value: 20,
        label: 'Louisiana',
        abbrev: 'la',
        us_census_fips_code: '22',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/LA/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.LouisianaScraper',
        key: 'louisiana'
    },
    maine: {
        value: 21,
        label: 'Maine',
        abbrev: 'me',
        us_census_fips_code: '23',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: '',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: 'http://www.mainenotices.com/',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'maine'
    },
    maryland: {
        value: 22,
        label: 'Maryland',
        abbrev: 'md',
        us_census_fips_code: '24',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.mddcnews.com/index.php/public_notices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'maryland'
    },
    massachusetts: {
        value: 23,
        label: 'Massachusetts',
        abbrev: 'ma',
        us_census_fips_code: '25',
        newzgroup: false,
        my_public_notices: 'http://ma.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.masspublicnotices.org/',
        press_association_ssid: 162,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'massachusetts'
    },
    michigan: {
        value: 24,
        label: 'Michigan',
        abbrev: 'mi',
        us_census_fips_code: '26',
        newzgroup: 'http://mipublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'michigan'
    },
    minnesota: {
        value: 25,
        label: 'Minnesota',
        abbrev: 'mn',
        us_census_fips_code: '27',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.mnpublicnotice.com/',
        press_association_ssid: 527,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'minnesota'
    },
    mississippi: {
        value: 26,
        label: 'Mississippi',
        abbrev: 'ms',
        us_census_fips_code: '28',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/MS/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 25,
        scraper: 'scraper.PublicNoticeAdsScraper.MississippiScraper',
        key: 'mississippi'
    },
    missouri: {
        value: 27,
        label: 'Missouri',
        abbrev: 'mo',
        us_census_fips_code: '29',
        newzgroup: 'http://mopublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'missouri'
    },
    montana: {
        value: 28,
        label: 'Montana',
        abbrev: 'mt',
        us_census_fips_code: '30',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://www.montanapublicnotices.com/mna/legals/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'montana'
    },
    nebraska: {
        value: 29,
        label: 'Nebraska',
        abbrev: 'ne',
        us_census_fips_code: '31',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://nepublicnotices.com/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'nebraska'
    },
    nevada: {
        value: 30,
        label: 'Nevada',
        abbrev: 'nv',
        us_census_fips_code: '32',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/NV/',
        custom_site: 'https://notice.nv.gov/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.NevadaScraper',
        key: 'nevada'
    },
    new_hampshire: {
        value: 31,
        label: 'New Hampshire',
        abbrev: 'nh',
        us_census_fips_code: '33',
        newzgroup: false,
        my_public_notices: 'http://nh.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'new_hampshire'
    },
    new_jersey: {
        value: 32,
        label: 'New Jersey',
        abbrev: 'nj',
        us_census_fips_code: '34',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/NJ/',
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.njpublicnotices.com/',
        press_association_ssid: 4831,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.NewJerseyScraper',
        key: 'new_jersey'
    },
    new_mexico: {
        value: 33,
        label: 'New Mexico',
        abbrev: 'nm',
        us_census_fips_code: '35',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/NM/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.NewMexicoScraper',
        key: 'new_mexico'
    },
    new_york: {
        value: 34,
        label: 'New York',
        abbrev: 'ny',
        us_census_fips_code: '36',
        newzgroup: false,
        my_public_notices: 'http://ny.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.newyorkpublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'new_york',
        donation_subdomain: 'nynpa',
        one_time_donation_template: 'd-d44adc4c46d64d3a8a747d87ad58b831',
        recurring_donation_template: 'd-6bda6b92ced241a0a32cc14d8a522904',
        donations_legal_docs: [
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Flegal%2Fdonations%2FFlorida_W9.pdf?alt=media&token=e8a302ef-9e17-42ef-966a-4b0b575b4aad',
                fileName: 'W-9.pdf'
            },
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fdonations%2Fflorida%2FFiscal%20Sponsorship.pdf?alt=media&token=379b47a2-37f5-4941-a35f-60aa3cdadb3c',
                fileName: 'Fiscal_Sponsorship_Agreement.pdf'
            }
        ],
        donations_product: {
            prod: 'prod_IPGneAeirXrGCt',
            test: 'prod_IOWLZtHwyNIPyF'
        },
        stripe_account_id: {
            prod: 'acct_1Keg2BQadZZGhCfW',
            test: 'acct_1HRLJQBUfy4eFp3V'
        },
        feePct: '3',
        lob_address_id: '',
        lob_bank_account_id: {
            test: 'bank_e90ac64381c00df'
        },
        stripe_customer_id: {
            prod: 'cus_IOXC0co20zdD5D',
            test: 'cus_IOX9jG1IOOY2Hn'
        },
        hasSubscribers: true
    },
    north_carolina: {
        value: 35,
        label: 'North Carolina',
        abbrev: 'nc',
        us_census_fips_code: '37',
        newzgroup: false,
        my_public_notices: 'http://nc.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: '',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: 'http://www.ncnotices.com/',
        legal_notice_dot_org: 34,
        scraper: '',
        key: 'north_carolina'
    },
    north_dakota: {
        value: 36,
        label: 'North Dakota',
        abbrev: 'nd',
        us_census_fips_code: '38',
        newzgroup: 'http://ndpublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.nd.gov/dhs/info/publicnotice/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'north_dakota'
    },
    ohio: {
        value: 37,
        label: 'Ohio',
        abbrev: 'oh',
        us_census_fips_code: '39',
        newzgroup: false,
        my_public_notices: true,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticesohio.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'app.scraper.PressAssociationScraper.OhioScraper',
        key: 'ohio'
    },
    oklahoma: {
        value: 38,
        label: 'Oklahoma',
        abbrev: 'ok',
        us_census_fips_code: '40',
        newzgroup: false,
        my_public_notices: 'http://ok.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://opa.eclipping.org/opa/legals/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'oklahoma'
    },
    oregon: {
        value: 39,
        label: 'Oregon',
        abbrev: 'or',
        us_census_fips_code: '41',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticeoregon.com/',
        press_association_ssid: 700,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'oregon'
    },
    pennsylvania: {
        value: 40,
        label: 'Pennsylvania',
        abbrev: 'pa',
        us_census_fips_code: '42',
        newzgroup: false,
        my_public_notices: 'http://pa.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticepa.com/',
        press_association_ssid: 431,
        foundation: '',
        legal_notice_dot_org: 39,
        scraper: '',
        key: 'pennsylvania',
        can_run_donations: true,
        donation_subdomain: 'pna',
        donations_product: {
            test: 'prod_I5UWFn7erXiRvi'
        },
        stripe_account_id: {
            test: 'acct_1HTVNlKO1H032WHx'
        },
        donations_legal_docs: [
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fdonations%2Fkansas%2Fw9_ks.pdf?alt=media&token=c5d4b411-8080-4a76-a1a8-07179fda7461',
                fileName: 'W-9.pdf'
            },
            {
                url: 'https://firebasestorage.googleapis.com/v0/b/enotice-production.appspot.com/o/oneoff%2Fdonations%2Fkansas%2Fks_fiscalsponsorshipagreement.pdf?alt=media&token=3ace9813-feeb-422a-8791-e6261ee141ca',
                fileName: 'Fiscal_Sponsorship_Agreement.pdf'
            }
        ]
    },
    rhode_island: {
        value: 42,
        label: 'Rhode Island',
        abbrev: 'ri',
        us_census_fips_code: '44',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'rhode_island'
    },
    south_carolina: {
        value: 43,
        label: 'South Carolina',
        abbrev: 'sc',
        us_census_fips_code: '45',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://www.sclegalnotice.com/',
        tecnavia: false,
        press_association: 'https://www.scpublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 41,
        scraper: '',
        key: 'south_carolina'
    },
    south_dakota: {
        value: 44,
        label: 'South Dakota',
        abbrev: 'sd',
        us_census_fips_code: '46',
        newzgroup: 'http://sdpublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'south_dakota'
    },
    tennessee: {
        value: 45,
        label: 'Tennessee',
        abbrev: 'tn',
        us_census_fips_code: '47',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: 'http://publicnoticeads.com/TN/',
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 43,
        scraper: '',
        key: 'tennessee'
    },
    texas: {
        value: 46,
        label: 'Texas',
        abbrev: 'tx',
        us_census_fips_code: '48',
        newzgroup: 'http://www.texaslegalnotices.com/',
        my_public_notices: 'http://tx.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 44,
        scraper: '',
        key: 'texas'
    },
    utah: {
        value: 47,
        label: 'Utah',
        abbrev: 'ut',
        us_census_fips_code: '49',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'https://www.utah.gov/pmn/index.html',
        tecnavia: false,
        press_association: 'https://utahlegals.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'utah'
    },
    vermont: {
        value: 48,
        label: 'Vermont',
        abbrev: 'vt',
        us_census_fips_code: '50',
        newzgroup: false,
        my_public_notices: 'http://vt.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'vermont'
    },
    virginia: {
        value: 49,
        label: 'Virginia',
        abbrev: 'va',
        us_census_fips_code: '51',
        newzgroup: false,
        my_public_notices: 'http://va.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.publicnoticevirginia.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'virginia'
    },
    washington: {
        value: 50,
        label: 'Washington',
        abbrev: 'wa',
        us_census_fips_code: '53',
        newzgroup: false,
        my_public_notices: 'http://wa.mypublicnotices.com/',
        public_notice_ads: false,
        custom_site: false,
        tecnavia: false,
        press_association: 'https://www.wapublicnotices.com/',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: 48,
        scraper: '',
        key: 'washington'
    },
    west_virginia: {
        value: 51,
        label: 'West Virginia',
        abbrev: 'wv',
        us_census_fips_code: '54',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: 'http://wvlegals.com/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'west_virginia'
    },
    wisconsin: {
        value: 52,
        label: 'Wisconsin',
        abbrev: 'wi',
        us_census_fips_code: '55',
        newzgroup: false,
        my_public_notices: false,
        public_notice_ads: false,
        custom_site: false,
        tecnavia: 'http://www.wisconsinpublicnotices.org/',
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: '',
        key: 'wisconsin'
    },
    wyoming: {
        value: 53,
        label: 'Wyoming',
        abbrev: 'wy',
        us_census_fips_code: '56',
        newzgroup: 'http://wypublicnotices.newzgroup.com/',
        my_public_notices: false,
        public_notice_ads: true,
        custom_site: 'http://www.wyomingworkforce.org/news/publicnotices/',
        tecnavia: false,
        press_association: '',
        press_association_ssid: null,
        foundation: '',
        legal_notice_dot_org: '',
        scraper: 'scraper.PublicNoticeAdsScraper.WyomingScraper',
        key: 'wyoming'
    }
};
