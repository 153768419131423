export default {
    pending: {
        value: 1,
        label: 'Pending',
        name: 'Pending',
        in_app_notification_text: 'New Notice Submitted',
        key: 'pending'
    },
    cancelled: {
        value: 2,
        label: 'Cancelled',
        name: 'Cancelled',
        key: 'cancelled'
    },
    published: {
        value: 3,
        label: 'Published',
        name: 'Published',
        key: 'published'
    },
    affidavit_submitted: {
        value: 4,
        label: 'Affidavit Submitted',
        name: 'Affidavit Submitted',
        key: 'affidavit_submitted'
    },
    affidavit_approved: {
        value: 5,
        label: 'Affidavit Submitted',
        in_app_notification_text: 'Affidavit Submitted',
        name: 'Affidavit Approved',
        key: 'affidavit_approved'
    }
};
