var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { all, take, takeEvery, call, put, select, race, fork, takeLatest, delay } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import { OccupationType, RoleType, State } from 'lib/enums';
import { push } from 'connected-react-router';
import api from 'api';
import AuthActions, { AuthTypes, authSelector } from '../redux/auth';
import ErrorActions from '../redux/errors';
import Firebase, { Collections } from '../EnoticeFirebase';
import { getLocationParams, getSubdomain, getHostname } from '../utils/urls';
import { ENV, PROD, DEMO, SHOULD_RUN_PENDO } from '../constants';
import { getRedirect } from './RoutingSaga';
/**
 *
 * @param {snapshot} org Organization to watch
 * Firebase snapshot to watch for updates on
 */
function watchActiveOrg(org) {
    var orgChannel;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!org)
                    return [2 /*return*/];
                orgChannel = eventChannel(function (emitter) { return org.ref.onSnapshot(emitter); });
                // ignore the first update
                return [4 /*yield*/, take(orgChannel)];
            case 1:
                // ignore the first update
                _a.sent();
                return [4 /*yield*/, takeEvery(orgChannel, function f(org) {
                        var _a, user, organization, allowedOrganizations, childOrgsQuery, childOrgSnapshot, allPotentialOrgs, availableOrgs;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, select(authSelector)];
                                case 1:
                                    _a = _b.sent(), user = _a.user, organization = _a.organization;
                                    allowedOrganizations = user.data().allowedOrganizations;
                                    childOrgsQuery = Firebase.firestore()
                                        .collection(Collections.organizations)
                                        .where('parent', '==', organization.ref);
                                    return [4 /*yield*/, call([childOrgsQuery, childOrgsQuery.get])];
                                case 2:
                                    childOrgSnapshot = _b.sent();
                                    allPotentialOrgs = [organization].concat(childOrgSnapshot.docs);
                                    availableOrgs = allPotentialOrgs.filter(function (org) {
                                        return !allowedOrganizations ||
                                            allowedOrganizations
                                                .map(function (o) { return o.id; })
                                                .includes(org.id);
                                    });
                                    return [4 /*yield*/, put(AuthActions.setAvailableOrganizations(availableOrgs))];
                                case 3:
                                    _b.sent();
                                    return [4 /*yield*/, put(AuthActions.setActiveOrganization(org))];
                                case 4:
                                    _b.sent();
                                    return [2 /*return*/];
                            }
                        });
                    })];
            case 2:
                _a.sent();
                return [4 /*yield*/, take(AuthTypes.LOGOUT_SUCCESS)];
            case 3:
                _a.sent();
                orgChannel.close();
                return [2 /*return*/];
        }
    });
}
function listenActiveOrg() {
    var auth;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!true) return [3 /*break*/, 3];
                return [4 /*yield*/, select(authSelector)];
            case 1:
                auth = _a.sent();
                return [4 /*yield*/, race([
                        call(watchActiveOrg, auth.activeOrganization),
                        take(AuthTypes.SET_ACTIVE_ORGANIZATION)
                    ])];
            case 2:
                _a.sent();
                return [3 /*break*/, 0];
            case 3: return [2 /*return*/];
        }
    });
}
function fetchUser(userRef) {
    var i, userSnap, err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                i = 0;
                _a.label = 1;
            case 1:
                if (!(i < 5)) return [3 /*break*/, 8];
                _a.label = 2;
            case 2:
                _a.trys.push([2, 4, , 7]);
                return [4 /*yield*/, call([userRef, userRef.get])];
            case 3:
                userSnap = _a.sent();
                if (!userSnap.exists)
                    throw new Error('User not found');
                return [2 /*return*/, userSnap];
            case 4:
                err_1 = _a.sent();
                if (!(i < 4)) return [3 /*break*/, 6];
                return [4 /*yield*/, delay(2000)];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [3 /*break*/, 7];
            case 7:
                i++;
                return [3 /*break*/, 1];
            case 8: return [4 /*yield*/, put(ErrorActions.setError('Unable to fetch user'))];
            case 9:
                _a.sent();
                throw new Error('User fetch failed');
        }
    });
}
function watchActiveUser(userSnapshot) {
    var userChannel;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                userChannel = eventChannel(function (emitter) {
                    return userSnapshot.ref.onSnapshot(emitter);
                });
                return [4 /*yield*/, takeEvery(userChannel, function f(user) {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, put(AuthActions.setUser(user))];
                                case 1:
                                    _a.sent();
                                    return [2 /*return*/];
                            }
                        });
                    })];
            case 1:
                _a.sent();
                return [4 /*yield*/, take(AuthTypes.LOGOUT_SUCCESS)];
            case 2:
                _a.sent();
                userChannel.close();
                return [2 /*return*/];
        }
    });
}
function setUserParameters(user) {
    var userRef, userdataSnapshot, userdata_1, organizationSnapshot, activeOrgQuery, orgFromQuery, ref, userCanAccessThisOrg, isDefaultOrg, orgFromUserdata, childOrgsQuery, childOrgSnapshot, allPotentialOrgs, availableOrgs, err_2, _a, user_1, userAuth;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 19, 20, 25]);
                if (!user) {
                    return [2 /*return*/];
                }
                return [4 /*yield*/, put(AuthActions.setUserAuth(user))];
            case 1:
                _b.sent();
                userRef = Firebase.firestore()
                    .collection(Collections.users)
                    .doc(user.uid);
                return [4 /*yield*/, call(fetchUser, userRef)];
            case 2:
                userdataSnapshot = _b.sent();
                userdataSnapshot.ref.update({
                    lastSignInTime: new Date(Date.now())
                });
                return [4 /*yield*/, fork(watchActiveUser, userdataSnapshot)];
            case 3:
                _b.sent();
                userdata_1 = userdataSnapshot.data();
                if (!!userdata_1.organization) return [3 /*break*/, 5];
                return [4 /*yield*/, put(AuthActions.setActiveOrganization(null))];
            case 4:
                _b.sent();
                return [2 /*return*/];
            case 5: return [4 /*yield*/, call([
                    userdata_1.organization,
                    userdata_1.organization.get
                ])];
            case 6:
                organizationSnapshot = _b.sent();
                activeOrgQuery = getLocationParams().get('activeOrg');
                orgFromQuery = void 0;
                if (!activeOrgQuery) return [3 /*break*/, 8];
                ref = Firebase.firestore()
                    .collection(Collections.organizations)
                    .doc(activeOrgQuery);
                return [4 /*yield*/, call([ref, ref.get])];
            case 7:
                orgFromQuery = _b.sent();
                userCanAccessThisOrg = (orgFromQuery.exists && userdata_1.organization.id === orgFromQuery.id) ||
                    (orgFromQuery.data().parent &&
                        orgFromQuery.data().parent.id === userdata_1.organization.id);
                isDefaultOrg = orgFromQuery.data().id === userdata_1.organization.id;
                if (!userCanAccessThisOrg || isDefaultOrg)
                    orgFromQuery = null;
                _b.label = 8;
            case 8:
                orgFromUserdata = void 0;
                if (!userdata_1.activeOrganization) return [3 /*break*/, 10];
                return [4 /*yield*/, call([
                        userdata_1.activeOrganization,
                        userdata_1.activeOrganization.get
                    ])];
            case 9:
                orgFromUserdata = _b.sent();
                return [3 /*break*/, 11];
            case 10:
                orgFromUserdata = null;
                _b.label = 11;
            case 11: return [4 /*yield*/, put(AuthActions.setOrganization(organizationSnapshot))];
            case 12:
                _b.sent();
                return [4 /*yield*/, put(AuthActions.setActiveOrganization(orgFromQuery || orgFromUserdata || organizationSnapshot))];
            case 13:
                _b.sent();
                childOrgsQuery = Firebase.firestore()
                    .collection(Collections.organizations)
                    .where('parent', '==', userdata_1.organization);
                return [4 /*yield*/, call([childOrgsQuery, childOrgsQuery.get])];
            case 14:
                childOrgSnapshot = _b.sent();
                allPotentialOrgs = [organizationSnapshot].concat(childOrgSnapshot.docs);
                availableOrgs = allPotentialOrgs.filter(function (org) {
                    return userdata_1.allowedOrganizations
                        ? userdata_1.allowedOrganizations.map(function (o) { return o.id; }).includes(org.id)
                        : true;
                });
                return [4 /*yield*/, put(AuthActions.setAvailableOrganizations(availableOrgs))];
            case 15:
                _b.sent();
                return [4 /*yield*/, fork(listenActiveOrg)];
            case 16:
                _b.sent();
                if (!userdata_1.organization) return [3 /*break*/, 18];
                return [4 /*yield*/, call([api, api.post], 'users/grant-claim')];
            case 17:
                _b.sent();
                user.getIdToken(true);
                _b.label = 18;
            case 18: return [3 /*break*/, 25];
            case 19:
                err_2 = _b.sent();
                Sentry.captureException(err_2);
                console.error('Auth: Error in setUserParameters', err_2);
                return [3 /*break*/, 25];
            case 20: return [4 /*yield*/, select(authSelector)];
            case 21:
                _a = _b.sent(), user_1 = _a.user, userAuth = _a.userAuth;
                if (!(userAuth && !user_1)) return [3 /*break*/, 23];
                return [4 /*yield*/, take(AuthTypes.SET_USER)];
            case 22:
                _b.sent();
                _b.label = 23;
            case 23: return [4 /*yield*/, put(AuthActions.endAuth())];
            case 24:
                _b.sent();
                return [7 /*endfinally*/];
            case 25: return [2 /*return*/];
        }
    });
}
function register() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(setUserParameters, Firebase.auth().currentUser)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function loginTokenFlow(_a) {
    var _b, _c;
    var token = _a.token;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0: return [4 /*yield*/, put(AuthActions.startAuth())];
            case 1:
                _d.sent();
                return [4 /*yield*/, call([Firebase.auth(), Firebase.auth().signInWithCustomToken], token)];
            case 2:
                _d.sent();
                _b = put;
                _c = push;
                return [4 /*yield*/, getRedirect()];
            case 3: return [4 /*yield*/, _b.apply(void 0, [_c.apply(void 0, [_d.sent()])])];
            case 4:
                _d.sent();
                return [2 /*return*/];
        }
    });
}
function loginFlow(_a) {
    var placementRedirectUrl, _b, _c, err_3;
    var email = _a.email, password = _a.password;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                _d.trys.push([0, 8, , 10]);
                return [4 /*yield*/, put(AuthActions.startAuth())];
            case 1:
                _d.sent();
                return [4 /*yield*/, call([Firebase.auth(), Firebase.auth().signInWithEmailAndPassword], email, password)];
            case 2:
                _d.sent();
                placementRedirectUrl = sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('placementRedirectUrl');
                if (!placementRedirectUrl) return [3 /*break*/, 4];
                return [4 /*yield*/, put(push("/?redirect=" + encodeURIComponent(placementRedirectUrl)))];
            case 3:
                _d.sent();
                _d.label = 4;
            case 4:
                _b = put;
                _c = push;
                return [4 /*yield*/, getRedirect()];
            case 5: return [4 /*yield*/, _b.apply(void 0, [_c.apply(void 0, [_d.sent()])])];
            case 6:
                _d.sent();
                return [4 /*yield*/, call([api, api.post], 'notifications/slack', {
                        message: "New login from " + email
                    })];
            case 7:
                _d.sent();
                return [3 /*break*/, 10];
            case 8:
                err_3 = _d.sent();
                Sentry.captureException(err_3);
                console.error('Auth: Error in loginFlow', err_3);
                return [4 /*yield*/, put(AuthActions.setAuthError(err_3.message))];
            case 9:
                _d.sent();
                return [3 /*break*/, 10];
            case 10: return [2 /*return*/];
        }
    });
}
function listenOnAuth() {
    var shouldDisplayChat, authChannel;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                shouldDisplayChat = ENV === PROD || ENV === DEMO;
                authChannel = eventChannel(function (emitter) {
                    return Firebase.auth().onAuthStateChanged(function (authState) {
                        if (authState) {
                            emitter(authState);
                            if (ENV === PROD || ENV === DEMO) {
                                Sentry.configureScope(function (scope) {
                                    return scope.setUser({
                                        email: authState.email
                                    });
                                });
                            }
                            if (shouldDisplayChat) {
                                window.Beacon('identify', {
                                    name: authState.displayName,
                                    email: authState.email,
                                    PhoneNumber: authState.phoneNumber
                                });
                                window.Beacon('prefill', {
                                    name: authState.displayName,
                                    email: authState.email
                                });
                            }
                            LogRocket.identify(authState.uid, {
                                name: authState.displayName,
                                email: authState.email,
                                enviornment: ENV
                            });
                            if (SHOULD_RUN_PENDO) {
                                try {
                                    window.pendo.initialize({
                                        visitor: {
                                            id: authState.uid,
                                            email: authState.email
                                        }
                                    });
                                }
                                catch (err) {
                                    Sentry.captureException(err);
                                    console.error('Auth: Failed to initialize pendo', err);
                                }
                            }
                        }
                        else {
                            emitter(false);
                        }
                    });
                });
                return [4 /*yield*/, takeEvery(authChannel, setUserParameters)];
            case 1:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
function logoutFlow() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, put(AuthActions.startAuth())];
            case 1:
                _a.sent();
                return [4 /*yield*/, call([Firebase.auth(), Firebase.auth().signOut])];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(AuthActions.logoutSuccess())];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(push('/'))];
            case 4:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
/**
 * Sets the organization context from the current subdomain or hostname
 * or, if one exists, from the current active organization
 */
var getContextKey = function () {
    var hostname = getHostname();
    if (['publicnoticecolorado'].indexOf(hostname) !== -1)
        return hostname;
    return getSubdomain();
};
function getOrgContext() {
    var contextKey, orgQuery, childOrgSnapshot, orgContext, activeOrganization;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                contextKey = getContextKey();
                orgQuery = Firebase.firestore()
                    .collection(Collections.organizations)
                    .where('subdomain', '==', contextKey);
                return [4 /*yield*/, call([orgQuery, orgQuery.get])];
            case 1:
                childOrgSnapshot = _a.sent();
                if (!childOrgSnapshot.docs.length) return [3 /*break*/, 3];
                orgContext = childOrgSnapshot.docs[0];
                return [4 /*yield*/, put(AuthActions.setOrgContext(orgContext))];
            case 2:
                _a.sent();
                return [3 /*break*/, 6];
            case 3: return [4 /*yield*/, take(AuthTypes.SET_ACTIVE_ORGANIZATION)];
            case 4:
                activeOrganization = (_a.sent()).activeOrganization;
                if (!activeOrganization) return [3 /*break*/, 6];
                return [4 /*yield*/, put(AuthActions.setOrgContext(activeOrganization))];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [2 /*return*/];
        }
    });
}
function updateActiveOrganization(_a) {
    var user, err_4;
    var activeOrganization = _a.activeOrganization;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 4, , 5]);
                if (!activeOrganization) return [3 /*break*/, 3];
                return [4 /*yield*/, select(authSelector)];
            case 1:
                user = (_b.sent()).user;
                user.ref.update({
                    activeOrganization: activeOrganization.ref
                });
                return [4 /*yield*/, call(getOrgContext)];
            case 2:
                _b.sent();
                _b.label = 3;
            case 3: return [3 /*break*/, 5];
            case 4:
                err_4 = _b.sent();
                Sentry.captureException(err_4);
                console.error('Auth: Error in updateActiveOrganization', err_4);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function updatePermissions() {
    var _a, activeOrganization, user, updatedPermissions;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                _a = _b.sent(), activeOrganization = _a.activeOrganization, user = _a.user;
                if (!activeOrganization || !user)
                    return [2 /*return*/];
                updatedPermissions = {
                    billing: user.data().role === RoleType.billing.value,
                    admin: user.data().role === RoleType.admin.value ||
                        user.id === activeOrganization.data().createdBy,
                    user: user.data().role === RoleType.user.value,
                    super: user.data().role === RoleType.super.value
                };
                return [4 /*yield*/, put(AuthActions.setPermissions(updatedPermissions))];
            case 2:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
/* Announcement viewers can be specified by:
   list of users || occupation || state
*/
export var extractRelevantAnnouncements = function (user, announcements) { return __awaiter(void 0, void 0, void 0, function () {
    var results;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all(announcements.map(function (announcement) { return __awaiter(void 0, void 0, void 0, function () {
                    var _a, start, occupation, state, users, existingAnnouncement;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _a = announcement.data(), start = _a.start, occupation = _a.occupation, state = _a.state, users = _a.users;
                                // Only refer to the user list if one exists on the announcement
                                if ((users === null || users === void 0 ? void 0 : users.length) > 0) {
                                    if (users.map(function (item) { return item.id; }).indexOf(user.ref.id) === -1)
                                        return [2 /*return*/, null];
                                }
                                else {
                                    if (start.toDate() > new Date())
                                        return [2 /*return*/, null];
                                    if (occupation && occupation !== user.data().occupation)
                                        return [2 /*return*/, null];
                                    if (state && state !== user.data().state)
                                        return [2 /*return*/, null];
                                }
                                return [4 /*yield*/, Firebase.firestore()
                                        .collection(Collections.announcements)
                                        .doc(announcement.id)
                                        .collection(Collections.users)
                                        .doc(user.id)
                                        .get()];
                            case 1:
                                existingAnnouncement = _b.sent();
                                if (existingAnnouncement.exists)
                                    return [2 /*return*/, null];
                                return [2 /*return*/, announcement];
                        }
                    });
                }); }))];
            case 1:
                results = _a.sent();
                return [2 /*return*/, results.filter(Boolean)];
        }
    });
}); };
export function getAnnouncement(_a) {
    var announcementQuery, announcementQueryResults, announcementChannel, announcementQuery_1, announcements, _b, announcement;
    var user = _a.user;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                announcementQuery = Firebase.firestore()
                    .collection(Collections.announcements)
                    .where('end', '>', new Date());
                return [4 /*yield*/, call([
                        announcementQuery,
                        announcementQuery.get
                    ])];
            case 1:
                announcementQueryResults = _c.sent();
                announcementChannel = eventChannel(function (emitter) {
                    return announcementQueryResults.query.onSnapshot(emitter);
                });
                _c.label = 2;
            case 2:
                if (!true) return [3 /*break*/, 7];
                return [4 /*yield*/, race({
                        announcementQuery: take(announcementChannel),
                        logout: take(AuthTypes.LOGOUT)
                    })];
            case 3:
                announcementQuery_1 = (_c.sent()).announcementQuery;
                if (!announcementQuery_1)
                    return [3 /*break*/, 7];
                announcements = announcementQuery_1.docs;
                return [4 /*yield*/, call(extractRelevantAnnouncements, user, announcements)];
            case 4:
                _b = __read.apply(void 0, [_c.sent(), 1]), announcement = _b[0];
                if (!announcement) return [3 /*break*/, 6];
                return [4 /*yield*/, put(AuthActions.setAnnouncement(announcement))];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6: return [3 /*break*/, 2];
            case 7: return [2 /*return*/];
        }
    });
}
function markAnnouncementRead() {
    var _a, user, announcement, markRead;
    var _this = this;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, select(authSelector)];
            case 1:
                _a = _b.sent(), user = _a.user, announcement = _a.announcement;
                if (!announcement)
                    return [2 /*return*/];
                return [4 /*yield*/, put(AuthActions.setAnnouncement(null))];
            case 2:
                _b.sent();
                markRead = function () { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, Firebase.firestore()
                                    .collection(Collections.announcements)
                                    .doc(announcement.id)
                                    .collection(Collections.users)
                                    .doc(user.id)
                                    .set({
                                    seenAt: new Date()
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                }); };
                return [4 /*yield*/, call(markRead)];
            case 3:
                _b.sent();
                return [2 /*return*/];
        }
    });
}
function initializeProductTracking(_a) {
    var userOccupation, userRole, userState, activeOrgSnap, pendoOrganizationData, err_5;
    var user = _a.user;
    var _b, _c, _d, _e;
    return __generator(this, function (_f) {
        switch (_f.label) {
            case 0:
                if (!(user === null || user === void 0 ? void 0 : user.data()))
                    return [2 /*return*/];
                if (!SHOULD_RUN_PENDO) return [3 /*break*/, 5];
                _f.label = 1;
            case 1:
                _f.trys.push([1, 4, , 5]);
                userOccupation = OccupationType.by_value(user.data().occupation);
                userRole = RoleType.by_value((_b = user.data()) === null || _b === void 0 ? void 0 : _b.role);
                userState = State.by_value((_c = user.data()) === null || _c === void 0 ? void 0 : _c.state);
                activeOrgSnap = void 0;
                if (!user.data().activeOrganization) return [3 /*break*/, 3];
                return [4 /*yield*/, call([
                        user.data().activeOrganization,
                        user.data().activeOrganization.get
                    ])];
            case 2:
                activeOrgSnap = _f.sent();
                _f.label = 3;
            case 3:
                pendoOrganizationData = (activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data()) ? {
                    id: activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.id,
                    organizationName: (activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data().name) || '',
                    parentOrg: ((_e = (_d = activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data()) === null || _d === void 0 ? void 0 : _d.parent) === null || _e === void 0 ? void 0 : _e.id) || '',
                    disabled: !!(activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data().disabled),
                    dwolla: !!(activeOrgSnap === null || activeOrgSnap === void 0 ? void 0 : activeOrgSnap.data().dwolla)
                }
                    : {};
                window.pendo.initialize({
                    visitor: {
                        id: user.id,
                        email: user.data().email,
                        occupation: (userOccupation === null || userOccupation === void 0 ? void 0 : userOccupation.label) || '',
                        role: (userRole === null || userRole === void 0 ? void 0 : userRole.label) || '',
                        state: (userState === null || userState === void 0 ? void 0 : userState.label) || ''
                    },
                    account: pendoOrganizationData
                });
                return [3 /*break*/, 5];
            case 4:
                err_5 = _f.sent();
                console.log("Failed to initialize pendo: " + err_5);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
export default function root() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fork(getOrgContext)];
            case 1:
                _a.sent();
                return [4 /*yield*/, fork(listenOnAuth)];
            case 2:
                _a.sent();
                return [4 /*yield*/, all([
                        takeEvery(AuthTypes.REGISTER, register),
                        takeEvery(AuthTypes.LOGIN, function (action) { return loginFlow(action); }),
                        takeEvery(AuthTypes.LOGIN_TOKEN, function (action) { return loginTokenFlow(action); }),
                        takeEvery(AuthTypes.LOGOUT, logoutFlow),
                        takeLatest([AuthTypes.SET_USER, AuthTypes.SET_ACTIVE_ORGANIZATION], updatePermissions),
                        takeEvery(AuthTypes.SET_ACTIVE_ORGANIZATION, function (action) {
                            return updateActiveOrganization(action);
                        }),
                        takeEvery(AuthTypes.SET_USER, function (action) { return getAnnouncement(action); }),
                        takeEvery(AuthTypes.SET_USER, getOrgContext),
                        takeLatest([AuthTypes.SET_USER, AuthTypes.SET_ACTIVE_ORGANIZATION], function (action) { return initializeProductTracking(action); }),
                        takeEvery(AuthTypes.MARK_ANNOUNCEMENT_READ, markAnnouncementRead)
                    ])];
            case 3:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
