import React from 'react';
var EToast = function (_a) {
    var id = _a.id, message = _a.message, type = _a.type, close = _a.close, children = _a.children;
    var styles = {
        success: {
            text: 'text-white ',
            background: 'bg-gray-800',
            close: 'text-white focus:text-gray-500'
        },
        error: {
            text: 'text-red-700',
            background: 'bg-red-200 border border-red-500',
            close: 'text-red-700 focus:text-red-500'
        }
    }[type];
    return (React.createElement("div", { className: "z-100 fixed inset-0 flex px-4 py-6 pointer-events-none sm:p-6 items-end justify-start" },
        React.createElement("div", { className: styles.background + " max-w-lg rounded shadow-xs pointer-events-auto" },
            React.createElement("div", { className: "rounded shadow-xs overflow-hidden" },
                React.createElement("div", { className: "p-4" },
                    React.createElement("div", { className: "flex items-center" },
                        React.createElement("div", { className: "flex-1 flex justify-between items-center" },
                            type === 'success' && (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", className: "mx-2" },
                                React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M8 16C10.1217 16 12.1566 15.1571 13.6569 13.6569C15.1571 12.1566 16 10.1217 16 8C16 5.87827 15.1571 3.84344 13.6569 2.34315C12.1566 0.842855 10.1217 0 8 0C5.87827 0 3.84344 0.842855 2.34315 2.34315C0.842855 3.84344 0 5.87827 0 8C0 10.1217 0.842855 12.1566 2.34315 13.6569C3.84344 15.1571 5.87827 16 8 16ZM11.707 6.707C11.8892 6.5184 11.99 6.2658 11.9877 6.0036C11.9854 5.7414 11.8802 5.49059 11.6948 5.30518C11.5094 5.11977 11.2586 5.0146 10.9964 5.01233C10.7342 5.01005 10.4816 5.11084 10.293 5.293L7 8.586L5.707 7.293C5.5184 7.11084 5.2658 7.01005 5.0036 7.01233C4.7414 7.0146 4.49059 7.11977 4.30518 7.30518C4.11977 7.49059 4.0146 7.7414 4.01233 8.0036C4.01005 8.2658 4.11084 8.5184 4.293 8.707L6.293 10.707C6.48053 10.8945 6.73484 10.9998 7 10.9998C7.26516 10.9998 7.51947 10.8945 7.707 10.707L11.707 6.707Z", fill: "#62C091" }))),
                            React.createElement("p", { className: styles.text + " flex-1 text-sm leading-5 font-medium font-medium text-sm pl-3" }, message || children)),
                        React.createElement("div", { className: "ml-4 flex-shrink-0 flex" },
                            React.createElement("button", { id: "close-toast", type: "button", className: styles.close + " inline-flex focus:outline-none transition ease-in-out duration-150", onClick: function () { return close(); } },
                                React.createElement("svg", { className: "h-5 w-5", viewBox: "0 0 20 20", fill: "currentColor" },
                                    React.createElement("path", { fillRule: "evenodd", d: "M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z", clipRule: "evenodd" }))))))))));
};
export default EToast;
