var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Collections } from '../constants';
import OccupationType from '../enums/OccupationType';
import { invoicedOutsideColumnForNoticeType } from '../helpers';
// Import firebase
var engine;
try {
    var firebaseAdmin = 'firebase-admin';
    // eslint-disable-next-line import/no-dynamic-require
    engine = require(firebaseAdmin);
}
catch (err) {
    try {
        // eslint-disable-next-line import/no-dynamic-require
        engine = require('firebase').default.app();
    }
    catch (err) {
        // Used in frontend tests
        // eslint-disable-next-line import/no-dynamic-require
        engine = require('firebase').default;
    }
}
export var getOrCreateCustomer = function (user, organization, info) {
    if (info === void 0) { info = null; }
    return __awaiter(void 0, void 0, void 0, function () {
        var existingCustomerQuery, customer, organizationName, userOrg, updateObject, customerRef;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        return __generator(this, function (_k) {
            switch (_k.label) {
                case 0: return [4 /*yield*/, engine
                        .firestore()
                        .collection('customers')
                        .where('user', '==', user.ref)
                        .where('organization', '==', organization.ref)
                        .get()];
                case 1:
                    existingCustomerQuery = _k.sent();
                    return [4 /*yield*/, ((_b = (_a = user.data()) === null || _a === void 0 ? void 0 : _a.organization) === null || _b === void 0 ? void 0 : _b.get())];
                case 2:
                    userOrg = (_k.sent());
                    if ((_c = user.data()) === null || _c === void 0 ? void 0 : _c.organizationName) {
                        organizationName = (_d = user.data()) === null || _d === void 0 ? void 0 : _d.organizationName;
                    }
                    else if (((_e = user.data()) === null || _e === void 0 ? void 0 : _e.occupation) !== OccupationType.individual.value &&
                        userOrg) {
                        organizationName = (_f = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _f === void 0 ? void 0 : _f.name;
                    }
                    if (!existingCustomerQuery.docs.length) return [3 /*break*/, 5];
                    customer = existingCustomerQuery.docs[0];
                    updateObject = __assign(__assign({}, (((_g = user === null || user === void 0 ? void 0 : user.data()) === null || _g === void 0 ? void 0 : _g.name) !== customer.data().userName && {
                        userName: (_h = user.data()) === null || _h === void 0 ? void 0 : _h.name
                    })), (organizationName &&
                        organizationName !== customer.data().organizationName && {
                        organizationName: organizationName
                    }));
                    if (!Object.keys(updateObject).length) return [3 /*break*/, 4];
                    return [4 /*yield*/, customer.ref.update(updateObject)];
                case 3:
                    _k.sent();
                    _k.label = 4;
                case 4: return [3 /*break*/, 8];
                case 5: return [4 /*yield*/, engine
                        .firestore()
                        .collection('customers')
                        .add(__assign({ organization: organization.ref, user: user.ref, internalID: '', userName: (_j = user.data()) === null || _j === void 0 ? void 0 : _j.name }, (organizationName && { organizationName: organizationName })))];
                case 6:
                    customerRef = _k.sent();
                    return [4 /*yield*/, customerRef.get()];
                case 7:
                    customer = (_k.sent());
                    _k.label = 8;
                case 8:
                    if (!info) return [3 /*break*/, 10];
                    return [4 /*yield*/, customer.ref.update(__assign({}, info))];
                case 9:
                    _k.sent();
                    _k.label = 10;
                case 10: return [2 /*return*/, customer];
            }
        });
    });
};
export var getOrCreateCustomerOrganization = function (client, organization) { return __awaiter(void 0, void 0, void 0, function () {
    var existingCustomerOrgQuery, customerOrganization, customerOrgRef;
    var _a;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4 /*yield*/, engine
                    .firestore()
                    .collection(Collections.customerOrganizations)
                    .where('client', '==', client.ref)
                    .where('organization', '==', organization.ref)
                    .get()];
            case 1:
                existingCustomerOrgQuery = _b.sent();
                if (!existingCustomerOrgQuery.docs.length) return [3 /*break*/, 2];
                customerOrganization = existingCustomerOrgQuery
                    .docs[0];
                return [3 /*break*/, 5];
            case 2: return [4 /*yield*/, engine
                    .firestore()
                    .collection(Collections.customerOrganizations)
                    .add({
                    organization: organization.ref,
                    client: client.ref,
                    internalID: ((_a = client.data()) === null || _a === void 0 ? void 0 : _a.internalID) || ''
                })];
            case 3:
                customerOrgRef = _b.sent();
                return [4 /*yield*/, customerOrgRef.get()];
            case 4:
                customerOrganization = (_b.sent());
                _b.label = 5;
            case 5: return [2 /*return*/, customerOrganization];
        }
    });
}); };
export var getOrCreateCustomerOrganizationForNotice = function (notice) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, filedBy, newspaper, clientOrgSnapshot, newspaperSnapshot;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = notice.data(), filedBy = _a.filedBy, newspaper = _a.newspaper;
                if (!filedBy)
                    return [2 /*return*/, null];
                return [4 /*yield*/, filedBy.get()];
            case 1:
                clientOrgSnapshot = _b.sent();
                return [4 /*yield*/, newspaper.get()];
            case 2:
                newspaperSnapshot = _b.sent();
                return [2 /*return*/, getOrCreateCustomerOrganization(clientOrgSnapshot, newspaperSnapshot)];
        }
    });
}); };
export var getShouldInvoiceCustomerOutsideColumn = function (customer) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaper, user, userOrg;
    var _a, _b, _c, _d, _e, _f;
    return __generator(this, function (_g) {
        switch (_g.label) {
            case 0:
                if (!customer)
                    return [2 /*return*/, false];
                return [4 /*yield*/, ((_a = customer.data().organization) === null || _a === void 0 ? void 0 : _a.get())];
            case 1:
                newspaper = _g.sent();
                if (!((_b = newspaper === null || newspaper === void 0 ? void 0 : newspaper.data()) === null || _b === void 0 ? void 0 : _b.allowInvoiceOutsideColumn))
                    return [2 /*return*/, false];
                if (customer.data().invoicedOutsideColumn)
                    return [2 /*return*/, true];
                if (!((_c = customer.data()) === null || _c === void 0 ? void 0 : _c.user)) return [3 /*break*/, 4];
                return [4 /*yield*/, customer
                        .data()
                        .user.get()];
            case 2:
                user = _g.sent();
                return [4 /*yield*/, ((_e = (_d = user.data()) === null || _d === void 0 ? void 0 : _d.organization) === null || _e === void 0 ? void 0 : _e.get())];
            case 3:
                userOrg = _g.sent();
                if ((_f = userOrg === null || userOrg === void 0 ? void 0 : userOrg.data()) === null || _f === void 0 ? void 0 : _f.invoiceOutsideColumn) {
                    return [2 /*return*/, true];
                }
                _g.label = 4;
            case 4: return [2 /*return*/, false];
        }
    });
}); };
export var isNoticeInvoicedOutsideColumn = function (notice) { return __awaiter(void 0, void 0, void 0, function () {
    var newspaper, customer, _a, _b, customerOrg, _c, err_1;
    var _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _e.trys.push([0, 10, , 11]);
                return [4 /*yield*/, notice.data().newspaper.get()];
            case 1:
                newspaper = _e.sent();
                if (!((_d = newspaper.data()) === null || _d === void 0 ? void 0 : _d.allowInvoiceOutsideColumn))
                    return [2 /*return*/, false];
                return [4 /*yield*/, invoicedOutsideColumnForNoticeType(notice)];
            case 2:
                if (_e.sent())
                    return [2 /*return*/, true];
                _a = getOrCreateCustomer;
                return [4 /*yield*/, notice.data().filer.get()];
            case 3:
                _b = [_e.sent()];
                return [4 /*yield*/, notice.data().newspaper.get()];
            case 4: return [4 /*yield*/, _a.apply(void 0, _b.concat([_e.sent()]))];
            case 5:
                customer = _e.sent();
                return [4 /*yield*/, getOrCreateCustomerOrganizationForNotice(notice)];
            case 6:
                customerOrg = _e.sent();
                return [4 /*yield*/, getShouldInvoiceCustomerOutsideColumn(customer)];
            case 7:
                _c = (_e.sent());
                if (_c) return [3 /*break*/, 9];
                return [4 /*yield*/, getShouldInvoiceCustomerOutsideColumn(customerOrg)];
            case 8:
                _c = (_e.sent());
                _e.label = 9;
            case 9: return [2 /*return*/, (_c)];
            case 10:
                err_1 = _e.sent();
                console.log("Error getting customer for notice: " + notice.id);
                return [2 /*return*/, false];
            case 11: return [2 /*return*/];
        }
    });
}); };
