// column-sam
export default {
    apiKey: 'AIzaSyDNkvLEROSwUGXs7gmzn_RzRHAq1QCXjh8',
    authDomain: 'column-sam.firebaseapp.com',
    databaseURL: 'https://column-sam-default-rtdb.firebaseio.com',
    projectId: 'column-sam',
    storageBucket: 'column-sam.appspot.com',
    messagingSenderId: '106514438533',
    appId: '1:106514438533:web:6b40f82e2647f1e0b98cec'
};
// column-leo
// export default {
//   apiKey: 'AIzaSyDs08axQzGU0tpxYTXERK9O7mHFKnTFbC8',
//   authDomain: 'column-leo.firebaseapp.com',
//   databaseURL: 'https://column-leo.firebaseio.com',
//   projectId: 'column-leo',
//   storageBucket: 'column-leo.appspot.com',
//   messagingSenderId: '744081052542',
//   appId: '1:744081052542:web:2e60697a4882c6afedd92f'
// };
