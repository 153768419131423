var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EnoticeError, ErrorType } from './index';
var DateParseError = /** @class */ (function (_super) {
    __extends(DateParseError, _super);
    function DateParseError(dateType, tag) {
        var _this = _super.call(this, "Unable to parse " + dateType + " format from tag: " + tag) || this;
        _this.errorType = ErrorType.NoticePreview.type + "01";
        _this.errorCode = 500;
        _this.name = "" + ErrorType.NoticePreview.name;
        return _this;
    }
    return DateParseError;
}(EnoticeError));
export { DateParseError };
var UnknownDateFormat = /** @class */ (function (_super) {
    __extends(UnknownDateFormat, _super);
    function UnknownDateFormat(tag) {
        var _this = _super.call(this, "Unknow date fromat from: " + tag) || this;
        _this.errorType = ErrorType.NoticePreview.type + "02";
        _this.errorCode = 500;
        _this.name = "" + ErrorType.NoticePreview.name;
        return _this;
    }
    return UnknownDateFormat;
}(EnoticeError));
export { UnknownDateFormat };
var MissingParameters = /** @class */ (function (_super) {
    __extends(MissingParameters, _super);
    function MissingParameters() {
        var _this = _super.call(this, 'Missing or bad parameters') || this;
        _this.errorType = ErrorType.NoticePreview.type + "03";
        _this.errorCode = 400;
        _this.name = "" + ErrorType.NoticePreview.name;
        return _this;
    }
    return MissingParameters;
}(EnoticeError));
export { MissingParameters };
var ResponseError = /** @class */ (function (_super) {
    __extends(ResponseError, _super);
    function ResponseError(errorCode) {
        var _this = _super.call(this, "Column Response Error: " + errorCode) || this;
        _this.errorType = ErrorType.NoticePreview.type + "04";
        _this.errorCode = 400;
        _this.name = "" + ErrorType.NoticePreview.name;
        return _this;
    }
    return ResponseError;
}(EnoticeError));
export { ResponseError };
