var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createReducer, createActions } from 'reduxsauce';
import { OccupationType } from '../lib/enums';
/* ------------- Types and Action Creators ------------- */
var _b = createActions({
    login: ['email', 'password'],
    logout: [],
    setUser: ['user'],
    startAuth: [],
    endAuth: [],
    resetPassword: ['email'],
    setPermissions: ['permissions'],
    setUserAuth: ['userAuth'],
    loginToken: ['token'],
    register: [],
    setOrganization: ['organization'],
    setActiveOrganization: ['activeOrganization'],
    setAvailableOrganizations: ['availableOrganizations'],
    showAllOrgsNotices: ['showAllOrgsNotices'],
    setOrgContext: ['orgContext'],
    logoutSuccess: [],
    setAuthError: ['error'],
    setAnnouncement: ['announcement'],
    markAnnouncementRead: []
}), Types = _b.Types, Creators = _b.Creators;
export var AuthTypes = Types;
export default Creators;
var INITIAL_STATE = {
    user: null,
    userAuth: null,
    isPublisher: null,
    organization: null,
    activeOrganization: null,
    availableOrganizations: [],
    error: null,
    orgContext: null,
    permissions: {
        billing: false,
        admin: false
    },
    loading: true,
    announcement: null
};
export var authSelector = function (state) {
    return state.auth;
};
/* ------------- Reducer ------------- */
export var reducer = createReducer(INITIAL_STATE, (_a = {},
    _a[Types.SET_USER] = function (state, _a) {
        var user = _a.user;
        return (__assign(__assign({}, state), { isPublisher: user.data().occupation === OccupationType.publishing.value, alwaysAllowAffidavitDownload: user.data().alwaysAllowAffidavitDownload || false, user: user }));
    },
    _a[Types.SET_USER_AUTH] = function (state, _a) {
        var userAuth = _a.userAuth;
        return (__assign(__assign({}, state), { userAuth: userAuth }));
    },
    _a[Types.START_AUTH] = function (state) { return (__assign(__assign({}, state), { loading: true })); },
    _a[Types.END_AUTH] = function (state) { return (__assign(__assign({}, state), { loading: false })); },
    _a[Types.SET_PERMISSIONS] = function (state, _a) {
        var permissions = _a.permissions;
        return (__assign(__assign({}, state), { permissions: permissions }));
    },
    _a[Types.SET_ORGANIZATION] = function (state, _a) {
        var organization = _a.organization;
        return (__assign(__assign({}, state), { organization: organization }));
    },
    _a[Types.SET_AVAILABLE_ORGANIZATIONS] = function (state, _a) {
        var availableOrganizations = _a.availableOrganizations;
        return (__assign(__assign({}, state), { availableOrganizations: availableOrganizations }));
    },
    _a[Types.SET_ACTIVE_ORGANIZATION] = function (state, _a) {
        var activeOrganization = _a.activeOrganization;
        return (__assign(__assign({}, state), { activeOrganization: activeOrganization }));
    },
    _a[Types.SHOW_ALL_ORGS_NOTICES] = function (state, _a) {
        var showAllOrgsNotices = _a.showAllOrgsNotices;
        return (__assign(__assign({}, state), { showAllOrgsNotices: showAllOrgsNotices }));
    },
    _a[Types.SET_AUTH_ERROR] = function (state, _a) {
        var error = _a.error;
        return (__assign(__assign({}, state), { error: error }));
    },
    _a[Types.LOGOUT_SUCCESS] = function () { return INITIAL_STATE; },
    _a[Types.SET_ORG_CONTEXT] = function (state, _a) {
        var orgContext = _a.orgContext;
        return (__assign(__assign({}, state), { orgContext: orgContext }));
    },
    _a[Types.SET_ANNOUNCEMENT] = function (state, _a) {
        var announcement = _a.announcement;
        return (__assign(__assign({}, state), { announcement: announcement }));
    },
    _a));
