export default {
    info: {
        value: 1,
        label: 'Info',
        key: 'info'
    },
    warning: {
        value: 2,
        label: 'Warning',
        key: 'warning'
    }
};
