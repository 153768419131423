var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createReducer, createActions } from 'reduxsauce';
/* ------------- Types and Action Creators ------------- */
var _b = createActions({
    setActiveJoyRide: ['activeJoyRide', 'callback'],
    setJoyRides: ['joyRides'],
    markJoyRideRead: [],
    setNewJoyRidePossible: []
}), Types = _b.Types, Creators = _b.Creators;
export var JoyRideTypes = Types;
export default Creators;
var INITIAL_STATE = {
    activeJoyRide: null,
    callback: function () { },
    steps: [],
    joyRides: []
};
/* ------------- Reducer ------------- */
export var reducer = createReducer(INITIAL_STATE, (_a = {},
    _a[Types.SET_ACTIVE_JOY_RIDE] = function (state, _a) {
        var activeJoyRide = _a.activeJoyRide, callback = _a.callback, steps = _a.steps;
        return (__assign(__assign({}, state), { activeJoyRide: activeJoyRide,
            callback: callback,
            steps: steps }));
    },
    _a[Types.SET_JOY_RIDES] = function (state, _a) {
        var joyRides = _a.joyRides;
        return (__assign(__assign({}, state), { joyRides: joyRides }));
    },
    _a[Types.MARK_JOY_RIDE_READ] = function (state) { return (__assign({}, state)); },
    _a[Types.SET_NEW_JOY_RIDE_POSSIBLE] = function (state) { return (__assign({}, state)); },
    _a));
export var joyrideSelector = function (state) { return state.joyrides; };
