import React, { useEffect } from 'react';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import ErrorActions from '../redux/errors';
import { ENV, PROD, DEMO } from '../constants';
var mapDispatchToProps = function (dispatch) { return ({
    errorActions: bindActionCreators(ErrorActions, dispatch)
}); };
var styles = function (theme) {
    return createStyles({
        loadingContainer: {
            width: '100%',
            height: '80vh'
        },
        message: {
            marginTop: theme.spacing(3)
        }
    });
};
var LoadingState = function (_a) {
    var classes = _a.classes, message = _a.message, _b = _a.timeout, timeout = _b === void 0 ? 30 : _b, errorActions = _a.errorActions;
    useEffect(function () {
        var timer = setTimeout(function () {
            var ERROR_TEXT = 'Operation timed out';
            errorActions.setError(ERROR_TEXT);
            console.error(ERROR_TEXT);
            if (ENV === DEMO || ENV === PROD) {
                Sentry.captureException(ERROR_TEXT);
            }
        }, timeout * 1000);
        return function () { return clearTimeout(timer); };
    }, []);
    return (React.createElement(Grid, { container: true, justify: "center", alignItems: "center", direction: "column", xs: 12, className: classes.loadingContainer, item: true },
        React.createElement("div", { className: "flex justify-center align-center mt-8", style: { width: '100%' } },
            React.createElement("div", { className: "loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6" })),
        message && (React.createElement(Typography, { className: classes.message }, message))));
};
export default connect(null, mapDispatchToProps)(withStyles(styles)(LoadingState));
