var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var EnoticeError = /** @class */ (function (_super) {
    __extends(EnoticeError, _super);
    function EnoticeError(message) {
        var _this = _super.call(this, message) || this;
        _this.message = 'System failure!';
        _this.name = 'Column System Error';
        _this.errorType = '00';
        _this.errorCode = 500;
        _this.message = message;
        return _this;
    }
    EnoticeError.prototype.setErrorMessage = function () {
        this.message = "\nError Code: " + this.getErrorCode() + "\nError Type: " + this.getErrorType() + "\nMessage: " + this.message;
    };
    EnoticeError.prototype.getErrorType = function () {
        return this.errorType;
    };
    EnoticeError.prototype.getErrorCode = function () {
        return this.errorCode;
    };
    return EnoticeError;
}(Error));
export { EnoticeError };
export var ErrorType = {
    Injectors: {
        name: 'Injector Errors',
        type: '01'
    },
    Router: {
        name: 'Route Error',
        type: '02'
    },
    Firestore: {
        name: 'Firestore Errors',
        type: '03'
    },
    PDF: {
        name: 'PDF Errors',
        type: '04'
    },
    EndToEnd: {
        name: 'End to End',
        type: '05'
    },
    Https: {
        name: 'Https',
        type: '06'
    },
    RatesTable: {
        name: 'Rates Table',
        type: '07'
    },
    Register: {
        name: 'Register',
        type: '08'
    },
    Stripe: {
        name: 'Stripe',
        type: '09'
    },
    NoticePreview: {
        name: 'Notice Preview Errors',
        type: '10'
    },
    Generic: {
        name: 'Generic Errors',
        type: '11'
    },
    MissingParams: {
        name: 'Missing Params Error',
        type: '12'
    },
    Auth: {
        name: 'Authentication Error',
        type: '13'
    },
    Notice: {
        name: 'Notice Error',
        type: '14'
    },
    PlacementError: {
        name: 'Placement Error',
        type: '15'
    }
};
