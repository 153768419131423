var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect, useState } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { Provider } from 'react-redux';
import { STRIPE_VARS } from './constants';
import Router from './Router';
import EnoticeTheme from './Theme';
import Header from './components/Header';
import JoyRide from './components/joyrides/JoyRide';
import './App.css';
var App = function (_a) {
    var store = _a.store;
    var _b = __read(useState(null), 2), stripe = _b[0], setStripe = _b[1];
    var setStripeAccount = function () {
        if (window.Stripe) {
            setStripe(window.Stripe(STRIPE_VARS.key));
        }
        else {
            var script = document.querySelector('#stripe-js');
            if (script) {
                script.addEventListener('load', function () {
                    setStripe(window.Stripe(STRIPE_VARS.key));
                });
            }
            else {
                console.log('Stripe not loaded correctly');
            }
        }
    };
    useEffect(function () {
        setStripeAccount();
    }, []);
    return (React.createElement("div", { className: "App" },
        React.createElement(StripeProvider, { stripe: stripe },
            React.createElement(Provider, { store: store },
                React.createElement(EnoticeTheme, null,
                    React.createElement(Router, null)),
                React.createElement(Header, null),
                React.createElement(JoyRide, null)))));
};
export default App;
