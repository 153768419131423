var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a;
import { createReducer, createActions } from 'reduxsauce';
import { NoticeType } from 'lib/enums';
/* ------------- Types and Action Creators ------------- */
var _b = createActions({
    hydrateNoticeData: ['noticeId', 'redirect'],
    populateNoticeData: ['noticeData'],
    setFiler: ['filerRef'],
    setFiledBy: ['filedBy'],
    setCreatedBy: ['createdBy'],
    setFilingState: ['filingState'],
    setFilingCounty: ['filingCounty'],
    setAvailableStates: ['availableStates'],
    setNewspaper: ['newspaperRef'],
    setPublicationDates: ['publicationDates'],
    setNoticeText: ['noticeText'],
    setNoticeType: ['noticeType'],
    setPreviousNoticeType: ['previousNoticeType'],
    setFile: ['file'],
    setColumns: ['columns'],
    setColumnsEditingEnabled: ['columnsEditingEnabled'],
    setDraft: ['draftRef'],
    setOriginal: ['originalRef'],
    setAttachedFiles: ['attachedFiles'],
    setMail: ['mail'],
    setInvoiceMailings: ['invoiceMailings'],
    setRate: ['rateRef'],
    setTemplate: ['templateRef'],
    setNewspaperList: ['newspaperList'],
    setDisplayUrl: ['displayUrl'],
    setDisplayParams: ['displayParams'],
    setProcessedDisplay: ['processedDisplay'],
    setSquashable: ['squashable'],
    setUnusedConfirmedHtml: ['unusedConfirmedHtml'],
    confirmSchedule: ['scheduleParams'],
    setConfirmedCrop: ['crop'],
    setPdfStoragePath: ['pdfStoragePath'],
    setUnusedDisplay: ['unusedDisplay'],
    setReferenceId: ['referenceId'],
    setCustomAffidavit: ['customAffidavit'],
    setDynamicFooter: ['dynamicFooter'],
    setDynamicHeaders: ['dynamicHeaders'],
    setProofStoragePath: ['proofStoragePath'],
    setDefaultRateOverride: ['defaultRateOverride'],
    setDraftSnap: ['draftSnap'],
    setProof: [],
    saveDraft: [],
    publishNotice: [],
    resetState: [],
    setEditing: ['editing'],
    setPlacementError: ['placementError'],
    setConfirmedText: ['confirmedText'],
    setFormattingError: ['formattingError'],
    setPostWithoutFormatting: ['postWithoutFormatting'],
    fileWithoutProof: [
        'formattingError',
        'postWithoutFormatting',
        'requiresFormatting'
    ],
    clearFileWithoutProof: [],
    publishNoticeNoProof: [],
    continueWithLargeFile: [],
    setCreatingInvoice: ['creatingInvoice'],
    setConfirming: ['confirming'],
    setCurrentStep: ['currentStep'],
    setCustomer: ['customer'],
    setRequireEmailAffidavit: ['requireEmailAffidavit'],
    setMailAffidavitsOutsideColumn: ['mailAffidavitsOutsideColumn']
}), Types = _b.Types, Creators = _b.Creators;
export var PlacementTypes = Types;
export default Creators;
/* ------------- Initial State ------------- */
export var INITIAL_STATE = {
    filer: null,
    filedBy: null,
    createdBy: null,
    availableStates: null,
    filingState: null,
    filingCounty: null,
    publicationDates: null,
    adTemplate: null,
    original: null,
    draft: null,
    rate: null,
    newspaper: null,
    newspaperList: [],
    noticeType: NoticeType.custom.value,
    previousNoticeType: null,
    columns: 1,
    columnsEditingEnabled: true,
    confirmedHtml: null,
    unusedConfirmedHtml: null,
    confirmedCrop: null,
    displayParams: null,
    displayUrl: null,
    file: null,
    mail: null,
    invoiceMailings: null,
    proofStoragePath: null,
    attachedFiles: null,
    processedDisplay: false,
    squashable: false,
    dynamicHeaders: null,
    dynamicFooter: null,
    footerFormatString: null,
    pdfStoragePath: '',
    jpgStoragePath: '',
    jpgURL: null,
    referenceId: '',
    customAffidavit: null,
    defaultRateOverride: false,
    userId: '',
    editing: false,
    draftSnap: null,
    unusedDisplay: null,
    placementError: null,
    confirmedText: null,
    postWithoutFormatting: false,
    requiresFormatting: false,
    continueWithLargeFile: false,
    creatingInvoice: false,
    confirming: false,
    currentStep: { id: '' },
    customer: null,
    requireEmailAffidavit: null,
    mailAffidavitsOutsideColumn: null
};
export var placementSelector = function (state) { return state.placement; };
/* ------------- Reducer ------------- */
export var reducer = createReducer(INITIAL_STATE, (_a = {},
    _a[Types.SET_FILING_STATE] = function (state, _a) {
        var filingState = _a.filingState;
        return (__assign(__assign({}, state), { filingState: filingState }));
    },
    _a[Types.SET_FILING_COUNTY] = function (state, _a) {
        var filingCounty = _a.filingCounty;
        return (__assign(__assign({}, state), { filingCounty: filingCounty }));
    },
    _a[Types.SET_DRAFT_SNAP] = function (state, _a) {
        var draftSnap = _a.draftSnap;
        return (__assign(__assign({}, state), { draftSnap: draftSnap }));
    },
    _a[Types.RESET_STATE] = function (state) {
        return __assign({}, INITIAL_STATE);
    },
    _a[Types.POPULATE_NOTICE_DATA] = function (state, _a) {
        var noticeData = _a.noticeData;
        var text = noticeData.text, confirmedHtml = noticeData.confirmedHtml, noticeState = __rest(noticeData, ["text", "confirmedHtml"]);
        return __assign(__assign(__assign({}, state), noticeState), { confirmedHtml: confirmedHtml || text || null });
    },
    _a[Types.SET_MAIL] = function (state, _a) {
        var mail = _a.mail;
        return (__assign(__assign({}, state), { mail: mail }));
    },
    _a[Types.SET_INVOICE_MAILINGS] = function (state, _a) {
        var invoiceMailings = _a.invoiceMailings;
        return (__assign(__assign({}, state), { invoiceMailings: invoiceMailings }));
    },
    _a[Types.SET_CUSTOM_AFFIDAVIT] = function (state, _a) {
        var customAffidavit = _a.customAffidavit;
        return (__assign(__assign({}, state), { customAffidavit: customAffidavit }));
    },
    _a[Types.SET_NEWSPAPER] = function (state, _a) {
        var newspaperRef = _a.newspaperRef;
        return (__assign(__assign({}, state), { newspaper: newspaperRef }));
    },
    _a[Types.SET_FILER] = function (state, _a) {
        var filerRef = _a.filerRef;
        return (__assign(__assign({}, state), { filer: filerRef, userId: filerRef.id }));
    },
    _a[Types.SET_FILED_BY] = function (state, _a) {
        var filedBy = _a.filedBy;
        return (__assign(__assign({}, state), { filedBy: filedBy }));
    },
    _a[Types.SET_CREATED_BY] = function (state, _a) {
        var createdBy = _a.createdBy;
        return (__assign(__assign({}, state), { createdBy: createdBy }));
    },
    _a[Types.SET_TEMPLATE] = function (state, _a) {
        var templateRef = _a.templateRef;
        return (__assign(__assign({}, state), { adTemplate: templateRef }));
    },
    _a[Types.SET_RATE] = function (state, _a) {
        var rateRef = _a.rateRef;
        return (__assign(__assign({}, state), { rate: rateRef }));
    },
    _a[Types.SET_ORIGINAL] = function (state, _a) {
        var originalRef = _a.originalRef;
        return (__assign(__assign({}, state), { original: originalRef }));
    },
    _a[Types.SET_DRAFT] = function (state, _a) {
        var draftRef = _a.draftRef;
        return (__assign(__assign({}, state), { draft: draftRef }));
    },
    _a[Types.SET_AVAILABLE_STATES] = function (state, _a) {
        var availableStates = _a.availableStates;
        return (__assign(__assign({}, state), { availableStates: availableStates }));
    },
    _a[Types.SET_NEWSPAPER_LIST] = function (state, _a) {
        var newspaperList = _a.newspaperList;
        return (__assign(__assign({}, state), { newspaperList: newspaperList }));
    },
    _a[Types.SET_NOTICE_TYPE] = function (state, _a) {
        var noticeType = _a.noticeType;
        return (__assign(__assign({}, state), { noticeType: noticeType }));
    },
    _a[Types.SET_PREVIOUS_NOTICE_TYPE] = function (state, _a) {
        var previousNoticeType = _a.previousNoticeType;
        return (__assign(__assign({}, state), { previousNoticeType: previousNoticeType }));
    },
    _a[Types.SET_DISPLAY_URL] = function (state, _a) {
        var displayUrl = _a.displayUrl;
        return (__assign(__assign({}, state), { displayUrl: displayUrl }));
    },
    _a[Types.SET_PROCESSED_DISPLAY] = function (state, _a) {
        var processedDisplay = _a.processedDisplay;
        return (__assign(__assign({}, state), { processedDisplay: processedDisplay }));
    },
    _a[Types.SET_COLUMNS] = function (state, _a) {
        var columns = _a.columns;
        return (__assign(__assign({}, state), { columns: columns }));
    },
    _a[Types.SET_COLUMNS_EDITING_ENABLED] = function (state, _a) {
        var columnsEditingEnabled = _a.columnsEditingEnabled;
        return (__assign(__assign({}, state), { columnsEditingEnabled: columnsEditingEnabled }));
    },
    _a[Types.SET_ATTACHED_FILES] = function (state, _a) {
        var attachedFiles = _a.attachedFiles;
        return (__assign(__assign({}, state), { attachedFiles: attachedFiles }));
    },
    _a[Types.SET_NOTICE_TEXT] = function (state, _a) {
        var noticeText = _a.noticeText;
        return (__assign(__assign({}, state), { confirmedHtml: noticeText }));
    },
    _a[Types.SET_UNUSED_CONFIRMED_HTML] = function (state, _a) {
        var unusedConfirmedHtml = _a.unusedConfirmedHtml;
        return (__assign(__assign({}, state), { unusedConfirmedHtml: unusedConfirmedHtml }));
    },
    _a[Types.SET_SQUASHABLE] = function (state, _a) {
        var squashable = _a.squashable;
        return (__assign(__assign({}, state), { squashable: squashable }));
    },
    _a[Types.SET_DISPLAY_PARAMS] = function (state, _a) {
        var displayParams = _a.displayParams;
        return (__assign(__assign({}, state), { displayParams: displayParams }));
    },
    _a[Types.SET_CONFIRMED_CROP] = function (state, _a) {
        var crop = _a.crop;
        return (__assign(__assign({}, state), { confirmedCrop: crop }));
    },
    _a[Types.SET_PROOF_STORAGE_PATH] = function (state, _a) {
        var proofStoragePath = _a.proofStoragePath;
        return (__assign(__assign({}, state), { proofStoragePath: proofStoragePath }));
    },
    _a[Types.SET_PDF_STORAGE_PATH] = function (state, _a) {
        var pdfStoragePath = _a.pdfStoragePath;
        return (__assign(__assign({}, state), { pdfStoragePath: pdfStoragePath }));
    },
    _a[Types.SET_FILE] = function (state, _a) {
        var file = _a.file;
        return (__assign(__assign({}, state), { file: file }));
    },
    _a[Types.SET_REFERENCE_ID] = function (state, _a) {
        var referenceId = _a.referenceId;
        return (__assign(__assign({}, state), { referenceId: referenceId }));
    },
    _a[Types.CONFIRM_SCHEDULE] = function (state, _a) {
        var scheduleParams = _a.scheduleParams;
        return (__assign(__assign({}, state), { publicationDates: scheduleParams.publicationDates, dynamicFooter: scheduleParams.dynamicFooter, footerFormatString: scheduleParams.footerFormatString, dynamicHeaders: scheduleParams.dynamicHeaders }));
    },
    _a[Types.SET_DYNAMIC_HEADERS] = function (state, _a) {
        var dynamicHeaders = _a.dynamicHeaders;
        return (__assign(__assign({}, state), { dynamicHeaders: dynamicHeaders }));
    },
    _a[Types.SET_DYNAMIC_FOOTER] = function (state, _a) {
        var dynamicFooter = _a.dynamicFooter;
        return (__assign(__assign({}, state), { dynamicFooter: dynamicFooter }));
    },
    _a[Types.SET_DEFAULT_RATE_OVERRIDE] = function (state, _a) {
        var defaultRateOverride = _a.defaultRateOverride;
        return (__assign(__assign({}, state), { defaultRateOverride: defaultRateOverride }));
    },
    _a[Types.SET_EDITING] = function (state, _a) {
        var editing = _a.editing;
        return (__assign(__assign({}, state), { editing: editing }));
    },
    _a[Types.SET_UNUSED_DISPLAY] = function (state, _a) {
        var unusedDisplay = _a.unusedDisplay;
        return (__assign(__assign({}, state), { unusedDisplay: unusedDisplay }));
    },
    _a[Types.SET_PLACEMENT_ERROR] = function (state, _a) {
        var placementError = _a.placementError;
        return (__assign(__assign({}, state), { placementError: placementError }));
    },
    _a[Types.SET_CONFIRMED_TEXT] = function (state, _a) {
        var confirmedText = _a.confirmedText;
        return (__assign(__assign({}, state), { confirmedText: confirmedText }));
    },
    _a[Types.SET_FORMATTING_ERROR] = function (state, _a) {
        var formattingError = _a.formattingError;
        return (__assign(__assign({}, state), { formattingError: formattingError }));
    },
    _a[Types.SET_POST_WITHOUT_FORMATTING] = function (state, _a) {
        var postWithoutFormatting = _a.postWithoutFormatting;
        return (__assign(__assign({}, state), { postWithoutFormatting: postWithoutFormatting }));
    },
    _a[Types.SET_PUBLICATION_DATES] = function (state, _a) {
        var publicationDates = _a.publicationDates;
        return (__assign(__assign({}, state), { publicationDates: publicationDates }));
    },
    _a[Types.FILE_WITHOUT_PROOF] = function (state, _a) {
        var formattingError = _a.formattingError, postWithoutFormatting = _a.postWithoutFormatting, requiresFormatting = _a.requiresFormatting;
        return (__assign(__assign({}, state), { formattingError: formattingError,
            postWithoutFormatting: postWithoutFormatting,
            requiresFormatting: requiresFormatting, continueWithLargeFile: false }));
    },
    _a[Types.CLEAR_FILE_WITHOUT_PROOF] = function (state) { return (__assign(__assign({}, state), { proofStoragePath: null, jpgStoragePath: null, jpgURL: null, formattingError: null, postWithoutFormatting: false, requiresFormatting: false, continueWithLargeFile: false })); },
    _a[Types.CONTINUE_WITH_LARGE_FILE] = function (state) { return (__assign(__assign({}, state), { continueWithLargeFile: true, formattingError: null, postWithoutFormatting: false, requiresFormatting: false })); },
    _a[Types.SET_CREATING_INVOICE] = function (state, _a) {
        var creatingInvoice = _a.creatingInvoice;
        return (__assign(__assign({}, state), { creatingInvoice: creatingInvoice }));
    },
    _a[Types.SET_CONFIRMING] = function (state, _a) {
        var confirming = _a.confirming;
        return (__assign(__assign({}, state), { confirming: confirming }));
    },
    _a[Types.SET_CURRENT_STEP] = function (state, _a) {
        var currentStep = _a.currentStep;
        return (__assign(__assign({}, state), { currentStep: currentStep }));
    },
    _a[Types.SET_CUSTOMER] = function (state, _a) {
        var customer = _a.customer;
        return (__assign(__assign({}, state), { customer: customer }));
    },
    _a[Types.SET_REQUIRE_EMAIL_AFFIDAVIT] = function (state, _a) {
        var requireEmailAffidavit = _a.requireEmailAffidavit;
        return (__assign(__assign({}, state), { requireEmailAffidavit: requireEmailAffidavit }));
    },
    _a[Types.SET_MAIL_AFFIDAVITS_OUTSIDE_COLUMN] = function (state, _a) {
        var mailAffidavitsOutsideColumn = _a.mailAffidavitsOutsideColumn;
        return (__assign(__assign({}, state), { mailAffidavitsOutsideColumn: mailAffidavitsOutsideColumn }));
    },
    _a));
