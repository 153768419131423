export default {
    clerk: {
        value: 1,
        label: 'City/County Clerk',
        key: 'clerk'
    },
    treasurer: {
        value: 2,
        label: 'City/County Treasurer',
        key: 'treasurer'
    },
    sheriff: {
        value: 3,
        label: 'Sheriff',
        key: 'sheriff'
    },
    county_board: {
        value: 4,
        label: 'Board of County Commissioners',
        key: 'county_board'
    },
    education_board: {
        value: 5,
        label: 'Board of Education',
        key: 'education_board'
    },
    election_officer: {
        value: 6,
        label: 'County Election Officer',
        key: 'election_officer'
    },
    water_district: {
        value: 7,
        label: 'Water District',
        key: 'water_district'
    },
    fire_department: {
        value: 8,
        label: 'Fire Department',
        key: 'fire_department'
    },
    housing_authority: {
        value: 9,
        label: 'Housing Authority',
        key: 'housing_authority'
    },
    other: {
        value: 10,
        label: 'Other',
        key: 'other'
    }
};
