var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
import moment from 'moment';
import { RateType, NoticeType, Day } from '../enums';
import { firestoreTimestampOrDateToDate } from '../helpers';
import { dateObjectToDayEnum } from '../utils/deadlines';
import { Collections } from '../constants';
export var getColumnInches = function (height, columns) {
    return height * columns;
};
// Based on the following rate sheet: https://michiganpress.org/wp-content/uploads/2021/03/Long-Folio-Chart.pdf
export var getFolios = function (height, columns) {
    var columnInches = getColumnInches(height, columns);
    var folios = Math.ceil(columnInches / 1.5);
    return Math.max(folios, 1);
};
export var getColumnCentimeters = function (height, columns) {
    return height * columns * 2.54;
};
// (3.1, .25) => 3.25, (3.1, 0) => 3.1, (3.0, 0.25) => 3.0
export var roundUp = function (x, r) { return (r ? Math.ceil(x / r) * r : x); };
export var oklahoma = {
    getBodyWords: function (displayParameters) {
        return Object.entries(displayParameters.justifications).reduce(function (acc, _a) {
            var _b = __read(_a, 2), k = _b[0], v = _b[1];
            if (k === 'LEFT_ALIGN' || k === 'RIGHT_ALIGN' || k === 'CENTER_ALIGN')
                return acc;
            return acc + v.words;
        }, 0);
    },
    getTabularLines: function (displayParameters) {
        return displayParameters.justifications.LEFT_ALIGN.lines +
            displayParameters.justifications.RIGHT_ALIGN.lines +
            displayParameters.justifications.CENTER_ALIGN.lines;
    }
};
export var calculateConvenienceFee = function (subtotal, percentage, disableMinimumConvenienceFee) {
    // 0 pct means it is a government rate
    if (percentage === 0)
        return 0;
    var convenience_fee = Math.round((subtotal * percentage) / 100);
    if (disableMinimumConvenienceFee)
        return convenience_fee;
    return convenience_fee;
    // return convenience_fee < 500
    //   ? 500
    //   : convenience_fee > 15000
    //   ? 15000
    //   : convenience_fee;
};
export var getApplicableRate = function (numRuns, rateRecord, runNumber) {
    var applicableRate;
    var applicableRun = rateRecord.runBased ? runNumber : numRuns;
    if (applicableRun === 1 || rateRecord.rateType === RateType.per_run.value) {
        applicableRate = rateRecord.rate_0;
    }
    else if (applicableRun === 2) {
        applicableRate = rateRecord.rate_1;
    }
    else if (applicableRun === 3) {
        applicableRate = rateRecord.rate_2;
    }
    else if (applicableRun >= 4 && rateRecord.additionalRates) {
        var maxRateNumber = Math.max.apply(Math, __spread(Object.keys(rateRecord.additionalRates).map(function (rate) {
            return parseInt(rate.replace('rate_', ''), 10);
        })));
        var rate = Math.min(maxRateNumber, applicableRun - 1);
        applicableRate = rateRecord.additionalRates["rate_" + rate];
    }
    else {
        applicableRate = rateRecord.rate_0;
    }
    return applicableRate;
};
export var relevantDisplayParameterFromRate = function (rateRecord, displayParameters, columns) {
    if (rateRecord.rateType === RateType.flat.value)
        return null;
    if (rateRecord.rateType === RateType.per_run.value)
        return null;
    if (rateRecord.rateType === RateType.word_count.value)
        return displayParameters.words;
    if (rateRecord.rateType === RateType.inch.value)
        return roundUp(displayParameters.height * displayParameters.width, rateRecord.roundOff).toFixed(2);
    if (rateRecord.rateType === RateType.column_inch.value)
        return getColumnInches(roundUp(displayParameters.height, rateRecord.roundOff), columns).toFixed(2);
    if (rateRecord.rateType === RateType.line.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.folio.value)
        return getFolios(displayParameters.height, displayParameters.columns);
    if (rateRecord.rateType === RateType.nebraska.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.oklahoma.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.battle_born.value)
        return displayParameters.lines;
    if (rateRecord.rateType === RateType.berthoud_government.value)
        return displayParameters.lines + " lines, " + getColumnInches(roundUp(displayParameters.height, rateRecord.roundOff), columns).toFixed(2) + " total column inches";
    if (rateRecord.rateType === RateType.enterprise.value)
        return getColumnInches(displayParameters.height, columns).toFixed(2) + " total column inches";
    if (rateRecord.rateType === RateType.single_column_centimetre.value) {
        return getColumnCentimeters(displayParameters.height, columns).toFixed(2) + " total scm";
    }
    throw new Error("Unknown rate type " + rateRecord.rateType);
};
export var floatToP2Float = function (bigFloat) {
    var floatStr = bigFloat.toFixed(2);
    return parseFloat(floatStr);
};
export var uiToDBCurrency = function (bigFloat) {
    var parsedFloat = typeof bigFloat === 'string' ? parseFloat(bigFloat) : bigFloat;
    var floatStr = parsedFloat.toFixed(2);
    var float = parseFloat(floatStr);
    var dbNum = Math.round(float * 100);
    return dbNum;
};
export var dbToUICurrency = function (num) {
    var floatStr = (num / 100).toFixed(2);
    return parseFloat(floatStr);
};
export var dbToUICurrencyString = function (num) {
    var floatStr = (num / 100).toFixed(2);
    return floatStr;
};
export var floatToDBPercent = function (pct) {
    var isStr = typeof pct === 'string';
    var parsedPct = isStr ? parseFloat(pct) : pct;
    var floatStr = parsedPct.toFixed(2);
    var dbNum = parseFloat(floatStr);
    return dbNum;
};
var getPercentString = function (pct) {
    return pct.toFixed(2);
};
export var customerPaidAmtFromInvoice = function (invoice, ENOTICE_FEE_PCT) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, invoice.inAppInvoicedAmt * (1 + ENOTICE_FEE_PCT / 100)];
    });
}); };
export var calculateFee = function (paper, numRuns, runNumber) {
    return paper.data().fee && runNumber + 1 === numRuns ? paper.data().fee : 0;
};
export var calculateBoldPrices = function (noticeRecord, rateRecord, displayParameters) {
    var boldPrice = 0;
    if (noticeRecord.noticeType !== NoticeType.display_ad.value) {
        if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.bold_words) && (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.boldWords)) {
            return (boldPrice +=
                (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.boldWords) * rateRecord.bold_words);
        }
        if ((rateRecord === null || rateRecord === void 0 ? void 0 : rateRecord.line_with_bold_words) && (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.nonTableBoldedLines)) {
            return (boldPrice +=
                (displayParameters === null || displayParameters === void 0 ? void 0 : displayParameters.nonTableBoldedLines) *
                    rateRecord.line_with_bold_words);
        }
    }
    return boldPrice;
};
export var calculateDBPrice = function (noticeRecord, rateRecord, displayParameters, numRuns, columns, dayRate, runNumber) {
    if (noticeRecord.fixedPrice) {
        if (runNumber > 0)
            return 0;
        return noticeRecord.fixedPrice;
    }
    var result;
    var applicableRate = dayRate || getApplicableRate(numRuns, rateRecord, runNumber + 1);
    if (rateRecord.rateType === RateType.flat.value) {
        if (dayRate)
            return dayRate;
        if (runNumber > 0)
            return 0;
        if (numRuns === 1)
            return rateRecord.rate_0;
        if (numRuns === 2)
            return rateRecord.rate_1;
        if (numRuns >= 4 && rateRecord.additionalRates) {
            var maxRateNumber = Math.max.apply(Math, __spread(Object.keys(rateRecord.additionalRates).map(function (rate) {
                return parseInt(rate.replace('rate_', ''), 10);
            })));
            var rate = Math.min(maxRateNumber, numRuns - 1);
            return rateRecord.additionalRates["rate_" + rate];
        }
        return rateRecord.rate_2;
    }
    if (rateRecord.rateType === RateType.per_run.value) {
        return dayRate || rateRecord.rate_0;
    }
    result = runNumber === 0 ? applicableRate : 0;
    var offset = rateRecord.offset || 0;
    if (rateRecord.rateType === RateType.word_count.value)
        result = Math.max(displayParameters.words - offset, 0) * applicableRate;
    if (rateRecord.rateType === RateType.folio.value) {
        result = floatToP2Float(getFolios(displayParameters.height, displayParameters.columns) *
            applicableRate);
    }
    if (rateRecord.rateType === RateType.inch.value)
        result = floatToP2Float(roundUp(Math.max(displayParameters.height * displayParameters.width - offset, 0), rateRecord.roundOff) * applicableRate);
    if (rateRecord.rateType === RateType.column_inch.value) {
        var columnInches = Math.max(getColumnInches(roundUp(displayParameters.height, rateRecord.roundOff), columns) - offset, 0);
        result = floatToP2Float(columnInches * applicableRate);
    }
    if (rateRecord.rateType === RateType.line.value)
        result = floatToP2Float(Math.max(displayParameters.lines - offset, 0) * columns * applicableRate);
    if (rateRecord.rateType === RateType.nebraska.value) {
        if (runNumber === 0) {
            result = floatToP2Float(displayParameters.lines * columns * rateRecord.rate_0);
        }
        else if (runNumber === 1) {
            result = floatToP2Float(displayParameters.lines * columns * rateRecord.rate_1);
        }
        else {
            result = floatToP2Float(displayParameters.lines * columns * rateRecord.rate_2);
        }
    }
    if (rateRecord.rateType === RateType.oklahoma.value) {
        var wordRate = runNumber === 0 ? 15 : 14;
        var tabularRate = runNumber === 0 ? 70 : 65;
        var wordTotal = floatToP2Float(oklahoma.getBodyWords(displayParameters) * wordRate);
        var tabularTotal = floatToP2Float(oklahoma.getTabularLines(displayParameters) * tabularRate);
        result = wordTotal + tabularTotal;
    }
    if (rateRecord.rateType === RateType.battle_born.value) {
        var lineRate50 = 200;
        var lineRate50Gt = 170;
        var lines50 = 50;
        var lines50Gt = Math.max(displayParameters.lines - 50, 0);
        result =
            runNumber + 1 === numRuns
                ? lines50 * lineRate50 + lines50Gt * lineRate50Gt
                : 0;
    }
    if (rateRecord.rateType === RateType.berthoud_government.value) {
        var lineRate = 44;
        var ciRate = 766;
        result =
            runNumber === 0
                ? lineRate * displayParameters.lines
                : ciRate *
                    getColumnInches(displayParameters.height, displayParameters.columns);
    }
    if (rateRecord.rateType === RateType.enterprise.value) {
        var flatciRate = 9500;
        var ciRate = 950;
        var cInches = getColumnInches(displayParameters.height, displayParameters.columns);
        result =
            cInches <= 10
                ? flatciRate
                : flatciRate + roundUp(cInches - 10, 0.25) * ciRate;
    }
    if (rateRecord.rateType === RateType.single_column_centimetre.value) {
        result = floatToP2Float(getColumnCentimeters(roundUp(displayParameters.height, rateRecord.roundOff), columns) * applicableRate);
    }
    if (!(result === 0 || result))
        console.error("Unknown pricing scheme: " + rateRecord.rateType + " with result " + result);
    return result;
};
export var getRelevantRateString = function (rateRecord, displayParameters, columns) {
    var ratePlural = RateType.by_value(rateRecord.rateType).plural;
    var relevantParam = relevantDisplayParameterFromRate(rateRecord, displayParameters, columns);
    if (rateRecord.rateType === RateType.oklahoma.value)
        return "Tabular Lines: " + oklahoma.getTabularLines(displayParameters) + ", Body Words: " + oklahoma.getBodyWords(displayParameters);
    if (rateRecord.rateType === RateType.single_column_centimetre.value)
        return "Single column centimetres: " + relevantParam;
    if (rateRecord.rateType === RateType.per_run.value)
        return '';
    return rateRecord.rateType === RateType.column_inch.value
        ? "Height: " + roundUp(displayParameters.height, rateRecord.roundOff).toFixed(2) + " / Columns: " + columns
        : ratePlural + ": " + (relevantParam ? "" + relevantParam : 'n/a');
};
export var getCorrectNoticeRate = function (oldRate, newType, newspaper) { return __awaiter(void 0, void 0, Promise, function () {
    var defaultDisplay, defaultLiner, oldRateData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, newspaper
                    .data()
                    .defaultDisplayRate.get()];
            case 1:
                defaultDisplay = (_a.sent());
                return [4 /*yield*/, newspaper
                        .data()
                        .defaultLinerRate.get()];
            case 2:
                defaultLiner = (_a.sent());
                oldRateData = oldRate && oldRate.data();
                if (newType === NoticeType.display_ad.value &&
                    oldRateData &&
                    oldRateData.code === defaultLiner.data().code) {
                    return [2 /*return*/, defaultDisplay.ref];
                }
                if (newType !== NoticeType.display_ad.value &&
                    oldRateData &&
                    oldRateData.code === defaultDisplay.data().code) {
                    return [2 /*return*/, defaultLiner.ref];
                }
                return [2 /*return*/, oldRate.ref];
        }
    });
}); };
export var getNoticeRate = function (notice, newspaper, placementRate) { return __awaiter(void 0, void 0, Promise, function () {
    var rateSnap;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!placementRate) return [3 /*break*/, 2];
                return [4 /*yield*/, placementRate.get()];
            case 1:
                rateSnap = _a.sent();
                return [3 /*break*/, 8];
            case 2:
                if (!notice.rate) return [3 /*break*/, 4];
                return [4 /*yield*/, notice.rate.get()];
            case 3:
                rateSnap = _a.sent();
                return [3 /*break*/, 8];
            case 4:
                if (!(notice.noticeType === NoticeType.display_ad.value)) return [3 /*break*/, 6];
                return [4 /*yield*/, newspaper.defaultDisplayRate.get()];
            case 5:
                rateSnap = _a.sent();
                return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, newspaper.defaultLinerRate.get()];
            case 7:
                rateSnap = _a.sent();
                _a.label = 8;
            case 8: return [2 /*return*/, rateSnap];
        }
    });
}); };
var mockDisplayParams = {
    words: 0,
    lines: 0,
    area: 0,
    height: 0,
    width: 0,
    headerLines: 0,
    headerWords: 0,
    nonTableBoldedLines: 0,
    boldWords: 0,
    justifications: {
        RIGHT_ALIGN: {
            lines: 0,
            words: 0
        },
        LEFT_ALIGN: {
            lines: 0,
            words: 0
        },
        CENTER_ALIGN: {
            lines: 0,
            words: 0
        },
        LEFT_JUSTIFIED: {
            lines: 0,
            words: 0
        }
    }
};
export var isCustomLineItem = function (lineItem) {
    // no description means not custom
    if (!lineItem.description)
        return false;
    // return yes on line items of the format mm/dd/yyyy:
    if (lineItem.description.match(/^\d{2}\/\d{2}\/\d{4}/))
        return false;
    return true;
};
export var createDBPricingObjectFromData = function (notice, displayParameters, rateSnap) {
    if (displayParameters === void 0) { displayParameters = mockDisplayParams; }
    return __awaiter(void 0, void 0, Promise, function () {
        var newspaperSnap, newspaper, customNoticeType, rate, _a, columns, lineItems, lastIndex, lastPubDatePlusOneMinute, totalNumberOfAffidavits, affidavitLineItems, blockAdditionalRateFee, feeAmount, taxPct, totalAcrossRuns, subtotal, lastNonCustomLineItemIndex, _b, _c, _d, i, item, roundedSubtotal, lineItems_1, lineItems_1_1, item, centTotal, customLineItemTotal, changedFinalLineItem, i, taxAmt, convenience_fee, total;
        var e_1, _e, e_2, _f;
        var _g, _h, _j, _k, _l;
        return __generator(this, function (_m) {
            switch (_m.label) {
                case 0:
                    if (!notice.newspaper)
                        throw new Error("Cannot compute pricing for notice " + notice.id + " without newspaper");
                    return [4 /*yield*/, ((_g = notice.newspaper) === null || _g === void 0 ? void 0 : _g.get())];
                case 1:
                    newspaperSnap = (_m.sent());
                    newspaper = newspaperSnap === null || newspaperSnap === void 0 ? void 0 : newspaperSnap.data();
                    customNoticeType = ((_h = newspaper === null || newspaper === void 0 ? void 0 : newspaper.allowedNotices) === null || _h === void 0 ? void 0 : _h.find(function (type) { return type.value === notice.noticeType; })) || null;
                    if (!rateSnap) return [3 /*break*/, 2];
                    _a = rateSnap.data();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, getNoticeRate(notice, newspaper)];
                case 3:
                    _a = (_m.sent()).data();
                    _m.label = 4;
                case 4:
                    rate = _a;
                    columns = displayParameters.columns || notice.columns || 1;
                    if (!notice.publicationDates)
                        throw new Error("Cannot compute pricing for notice " + notice.id + " without publication dates");
                    lineItems = notice.publicationDates.map(function (date, i) {
                        var dayEnum = dateObjectToDayEnum(date.toDate());
                        var dayRate = 0;
                        if (rate.dayRates && rate.dayRates.find(function (dRate) { return dRate.day === dayEnum; })) {
                            dayRate = rate.dayRates.find(function (dRate) { return dRate.day === dayEnum; }).rate;
                        }
                        return __assign({ date: date.toDate(), amount: calculateDBPrice(notice, rate, displayParameters, notice.publicationDates.length, columns, dayRate, i) +
                                calculateBoldPrices(notice, rate, displayParameters) +
                                (calculateFee(newspaperSnap, notice.publicationDates.length, i) || 0) }, (dayRate && {
                            description: moment(date.toDate()).format('MM/DD/YYYY') + ": Custom notice (" + Day.by_value(dayEnum).label + " Rate)"
                        }));
                    });
                    lastIndex = notice.publicationDates.length - 1;
                    lastPubDatePlusOneMinute = moment(notice.publicationDates[lastIndex].toDate()).add(1, 'm');
                    // allow for additional line items at the newspaper level
                    if (newspaper.additionalFees) {
                        lineItems = lineItems.concat(newspaper.additionalFees.map(function (fee) { return ({
                            date: lastPubDatePlusOneMinute.toDate(),
                            amount: fee.amount,
                            description: fee.description
                        }); }));
                    }
                    // allow for additional fees for custom affidavits
                    // this enables additional fees for Ogden papers that
                    // are requesting notice-type-specific pricing
                    if (newspaper.customAffidavitFee) {
                        lineItems = [
                            {
                                date: lastPubDatePlusOneMinute.toDate(),
                                amount: newspaper.customAffidavitFee,
                                description: 'Custom Affidavit Fee'
                            }
                        ].concat(lineItems);
                    }
                    if (rate.perAffidavitFee && ((_j = rate.additionalFee) === null || _j === void 0 ? void 0 : _j.amount) && ((_k = notice.mail) === null || _k === void 0 ? void 0 : _k.length)) {
                        totalNumberOfAffidavits = notice.mail.reduce(function (a, m) { return a + (m.copies || 0); }, 0);
                        affidavitLineItems = [
                            {
                                date: lastPubDatePlusOneMinute.toDate(),
                                amount: rate.additionalFee.amount * totalNumberOfAffidavits,
                                description: rate.additionalFee.description
                            }
                        ];
                        lineItems = __spread(lineItems, affidavitLineItems);
                    }
                    blockAdditionalRateFee = customNoticeType &&
                        !customNoticeType.rate &&
                        !NoticeType.by_value(notice.noticeType);
                    if (!blockAdditionalRateFee && ((_l = rate.additionalFee) === null || _l === void 0 ? void 0 : _l.amount) &&
                        !rate.perAffidavitFee) {
                        feeAmount = rate.additionalFee.amount;
                        if (rate.additionalFee.perRun)
                            feeAmount *= notice.publicationDates.length;
                        lineItems = lineItems.concat({
                            date: lastPubDatePlusOneMinute.toDate(),
                            amount: feeAmount,
                            description: rate.additionalFee.description
                        });
                    }
                    taxPct = newspaper.taxPct || 0;
                    totalAcrossRuns = lineItems.reduce(function (acc, lineItem) { return acc + lineItem.amount; }, 0);
                    subtotal = Math.max(totalAcrossRuns, rate.minimum);
                    lastNonCustomLineItemIndex = 0;
                    try {
                        for (_b = __values(lineItems.entries()), _c = _b.next(); !_c.done; _c = _b.next()) {
                            _d = __read(_c.value, 2), i = _d[0], item = _d[1];
                            if (!isCustomLineItem(item))
                                lastNonCustomLineItemIndex = i;
                        }
                    }
                    catch (e_1_1) { e_1 = { error: e_1_1 }; }
                    finally {
                        try {
                            if (_c && !_c.done && (_e = _b.return)) _e.call(_b);
                        }
                        finally { if (e_1) throw e_1.error; }
                    }
                    roundedSubtotal = 0;
                    try {
                        for (lineItems_1 = __values(lineItems), lineItems_1_1 = lineItems_1.next(); !lineItems_1_1.done; lineItems_1_1 = lineItems_1.next()) {
                            item = lineItems_1_1.value;
                            centTotal = Math.floor(item.amount);
                            item.amount = centTotal;
                            roundedSubtotal += centTotal;
                        }
                    }
                    catch (e_2_1) { e_2 = { error: e_2_1 }; }
                    finally {
                        try {
                            if (lineItems_1_1 && !lineItems_1_1.done && (_f = lineItems_1.return)) _f.call(lineItems_1);
                        }
                        finally { if (e_2) throw e_2.error; }
                    }
                    // put the impact of rounding onto the last non-custom line item
                    lineItems[lastNonCustomLineItemIndex].amount += subtotal - roundedSubtotal;
                    customLineItemTotal = 0;
                    changedFinalLineItem = false;
                    if (rate.finalLineItemPricing) {
                        for (i = lineItems.length - 1; i >= 0; i -= 1) {
                            if (isCustomLineItem(lineItems[i])) {
                                customLineItemTotal += lineItems[i].amount;
                            }
                            else if (changedFinalLineItem) {
                                lineItems[i].amount = 0;
                            }
                            else {
                                lineItems[i].amount = totalAcrossRuns - customLineItemTotal;
                                changedFinalLineItem = true;
                            }
                        }
                    }
                    taxAmt = lineItems.reduce(function (acc, lineItem) { return acc + Math.round((lineItem.amount * taxPct) / 100); }, 0);
                    convenience_fee = calculateConvenienceFee(subtotal, rate.enotice_fee_pct);
                    total = subtotal + taxAmt + convenience_fee;
                    return [2 /*return*/, {
                            lineItems: lineItems,
                            subtotal: subtotal,
                            taxPct: floatToDBPercent(taxPct),
                            taxAmt: taxAmt,
                            convenienceFeePct: floatToDBPercent(rate.enotice_fee_pct),
                            convenienceFee: convenience_fee,
                            total: total
                        }];
            }
        });
    });
};
export var createDBPricingObject = function (noticeSnap, displayParameters, rateSnap) {
    if (displayParameters === void 0) { displayParameters = mockDisplayParams; }
    return __awaiter(void 0, void 0, Promise, function () {
        var notice, mailQuery, mail;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!noticeSnap.data()) {
                        throw new Error('Notice not set');
                    }
                    notice = noticeSnap.data();
                    return [4 /*yield*/, noticeSnap.ref.collection(Collections.mail).get()];
                case 1:
                    mailQuery = _a.sent();
                    mail = mailQuery.docs.map(function (doc) { return doc.data(); });
                    return [4 /*yield*/, createDBPricingObjectFromData(__assign(__assign({}, notice), { mail: mail }), displayParameters, rateSnap)];
                case 2: return [2 /*return*/, _a.sent()];
            }
        });
    });
};
export var createDBPricingFromNotice = function (noticeSnap) { return __awaiter(void 0, void 0, void 0, function () {
    var rate, displayParams;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, noticeSnap.data().rate.get()];
            case 1:
                rate = (_a.sent());
                displayParams = noticeSnap.data().displayParams;
                return [2 /*return*/, createDBPricingObject(noticeSnap, displayParams, rate)];
        }
    });
}); };
export var distributeDbPrice = function (dbPricingObj, distributeEnoticeFee, finalLineItemPricing, rateType) {
    var itemsToDistributeOver = dbPricingObj.lineItems.filter(function (l) { return !isCustomLineItem(l); });
    var distributedFee = dbPricingObj.convenienceFee / itemsToDistributeOver.length;
    var subtotal = dbPricingObj.subtotal + dbPricingObj.convenienceFee;
    var taxAmt = dbPricingObj.lineItems
        .map(function (item) { return Math.round(item.amount * (dbPricingObj.taxPct / 100)); })
        .reduce(function (pre, cur) { return pre + cur; }, 0);
    taxAmt += dbPricingObj.convenienceFee * (dbPricingObj.taxPct / 100);
    var total = subtotal + taxAmt;
    var finalLineItemAmount;
    var lineItems;
    if ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) &&
        finalLineItemPricing &&
        rateType === RateType.flat.value) {
        var customLineItemTotal_1 = 0;
        dbPricingObj.lineItems.forEach(function (item) {
            if (isCustomLineItem(item)) {
                customLineItemTotal_1 += item.amount;
            }
        });
        lineItems = dbPricingObj.lineItems.map(function (item, i) {
            return {
                description: item.description,
                date: item.date,
                amount: i === 0
                    ? subtotal - customLineItemTotal_1
                    : isCustomLineItem(item)
                        ? item.amount
                        : 0
            };
        });
    }
    else if ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) &&
        !finalLineItemPricing &&
        rateType === RateType.flat.value) {
        finalLineItemAmount =
            dbPricingObj.convenienceFee + itemsToDistributeOver[0].amount;
        lineItems = dbPricingObj.lineItems.map(function (item, i) {
            return {
                description: item.description,
                date: item.date,
                amount: i === itemsToDistributeOver.length - 1
                    ? finalLineItemAmount
                    : isCustomLineItem(item)
                        ? item.amount
                        : 0
            };
        });
    }
    else if ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) &&
        !finalLineItemPricing &&
        rateType !== RateType.flat.value) {
        finalLineItemAmount =
            dbPricingObj.convenienceFee + itemsToDistributeOver[0].amount;
        lineItems = dbPricingObj.lineItems.map(function (item, i) {
            return {
                description: item.description,
                date: item.date,
                amount: i === itemsToDistributeOver.length - 1
                    ? finalLineItemAmount
                    : item.amount
            };
        });
    }
    else if (((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.evenly) &&
        (rateType === RateType.flat.value || finalLineItemPricing)) ||
        ((distributeEnoticeFee === null || distributeEnoticeFee === void 0 ? void 0 : distributeEnoticeFee.finalLineItem) && finalLineItemPricing)) {
        lineItems = dbPricingObj.lineItems.map(function (item, i) {
            return {
                description: item.description,
                date: item.date,
                amount: item.amount !== 0 && !isCustomLineItem(item)
                    ? item.amount + dbPricingObj.convenienceFee
                    : item.amount
            };
        });
    }
    else {
        var totalToDistributeOver = itemsToDistributeOver.reduce(function (a, b) { return a + b.amount; }, 0);
        // if there is only one value to distribute over, put the whole fee on it
        if (itemsToDistributeOver.length === 1) {
            finalLineItemAmount = totalToDistributeOver + dbPricingObj.convenienceFee;
        }
        // otherwise we need to be careful with rounding
        else {
            var initialDistributed = itemsToDistributeOver
                .slice(0, -1)
                .reduce(function (a, b) { return a + b.amount + Math.round(distributedFee); }, 0);
            finalLineItemAmount =
                totalToDistributeOver +
                    dbPricingObj.convenienceFee -
                    initialDistributed;
        }
        lineItems = dbPricingObj.lineItems.map(function (item, i) {
            var amount;
            // don't include the fee on custom items
            if (isCustomLineItem(item))
                amount = item.amount;
            // if we are handling rounding on the final item
            // or we are handling rounding on the last not custom line item
            else if (i === itemsToDistributeOver.length - 1 ||
                (i === itemsToDistributeOver.length - 2 &&
                    isCustomLineItem(dbPricingObj.lineItems[i + 1]))) {
                amount = finalLineItemAmount;
            }
            // otherwise include the default spread
            else
                amount = item.amount + Math.round(distributedFee);
            return {
                description: item.description,
                date: item.date,
                amount: amount
            };
        });
    }
    return __assign(__assign({}, dbPricingObj), { lineItems: lineItems, convenienceFee: null, convenienceFeePct: null, subtotal: subtotal,
        taxAmt: taxAmt,
        total: total, distributed: true, distributedFee: dbPricingObj.convenienceFee });
};
export var invoiceDataToDBPricingObject = function (inAppLineItems, convenienceFeePct, inAppTaxPct, distributeEnoticeFee, disableMinimumConvenienceFee, finalLineItemPricing, rateType) {
    var subtotal = inAppLineItems
        .map(function (item) { return item.amount; })
        .reduce(function (pre, cur) { return pre + cur; }, 0);
    var convenienceFee = calculateConvenienceFee(subtotal, convenienceFeePct, disableMinimumConvenienceFee);
    var taxAmt = inAppLineItems
        .map(function (item) { return Math.round(item.amount * (inAppTaxPct / 100)); })
        .reduce(function (pre, cur) { return pre + cur; }, 0);
    var total = subtotal + taxAmt + convenienceFee;
    var obj = {
        lineItems: inAppLineItems
            .map(function (item) {
            return __assign(__assign({}, item), { date: firestoreTimestampOrDateToDate(item.date) });
        })
            .sort(function (a, b) { return a.date.getTime() - b.date.getTime(); }),
        taxPct: inAppTaxPct,
        taxAmt: taxAmt,
        convenienceFeePct: convenienceFeePct,
        convenienceFee: convenienceFee,
        subtotal: subtotal,
        total: total
    };
    if (distributeEnoticeFee &&
        Object.values(distributeEnoticeFee).some(function (v) { return v; })) {
        obj = distributeDbPrice(obj, distributeEnoticeFee, finalLineItemPricing, rateType);
    }
    return obj;
};
export var getUIPricingObject = function (dbPricingObj) {
    var lineItems = dbPricingObj.lineItems.map(function (item) {
        return { date: item.date, amount: dbToUICurrency(item.amount) };
    });
    var subtotal = dbToUICurrency(dbPricingObj.subtotal);
    var taxPct = dbPricingObj.taxPct;
    var convenienceFeePct = dbPricingObj.convenienceFeePct;
    var convenienceFee = dbPricingObj.convenienceFee;
    var total = dbToUICurrency(dbPricingObj.total);
    return {
        lineItems: lineItems,
        subtotal: subtotal,
        taxPct: taxPct,
        convenienceFeePct: convenienceFeePct,
        convenienceFee: convenienceFee,
        total: total
    };
};
export var getUIPricingObjectStrings = function (dbPricingObj, options, finalLineItemPricing, rateType) {
    var dbPricing = options && options.distributeEnoticeFee
        ? distributeDbPrice(dbPricingObj, options.distributeEnoticeFee, finalLineItemPricing, rateType)
        : dbPricingObj;
    var lineItems = dbPricing.lineItems.map(function (item) {
        return {
            date: item.date,
            amount: dbToUICurrencyString(item.amount),
            description: item.description || null
        };
    });
    var convenienceFeePct = dbPricing.convenienceFeePct
        ? getPercentString(dbPricing.convenienceFeePct)
        : undefined;
    var convenienceFee = dbPricing.convenienceFee
        ? dbToUICurrencyString(dbPricing.convenienceFee)
        : undefined;
    var subtotal = dbToUICurrencyString(dbPricing.subtotal);
    var taxPct = getPercentString(dbPricing.taxPct);
    var total = dbToUICurrencyString(dbPricing.total);
    return {
        lineItems: lineItems,
        subtotal: subtotal,
        taxPct: taxPct,
        convenienceFeePct: convenienceFeePct,
        convenienceFee: convenienceFee,
        total: total
    };
};
export var ENOTICE_NAME = 'Column, PBC';
export default {
    getColumnInches: getColumnInches,
    getColumnCentimeters: getColumnCentimeters,
    calculateDBPrice: calculateDBPrice,
    getRelevantRateString: getRelevantRateString,
    getApplicableRate: getApplicableRate,
    relevantDisplayParameterFromRate: relevantDisplayParameterFromRate,
    getCorrectNoticeRate: getCorrectNoticeRate,
    getNoticeRate: getNoticeRate,
    createDBPricingObject: createDBPricingObject,
    createDBPricingObjectFromData: createDBPricingObjectFromData,
    getUIPricingObject: getUIPricingObject,
    getUIPricingObjectStrings: getUIPricingObjectStrings,
    floatToP2Float: floatToP2Float,
    uiToDBCurrency: uiToDBCurrency,
    dbToUICurrency: dbToUICurrency,
    dbToUICurrencyString: dbToUICurrencyString,
    floatToDBPercent: floatToDBPercent,
    ENOTICE_NAME: ENOTICE_NAME,
    customerPaidAmtFromInvoice: customerPaidAmtFromInvoice,
    calculateConvenienceFee: calculateConvenienceFee,
    calculateBoldPrices: calculateBoldPrices,
    getFolios: getFolios
};
